/* eslint-disable react-hooks/exhaustive-deps */
import {
  AttachMoney,
  Close,
  Edit,
  Group,
  Launch,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AddressForm from '../../components/Forms/AddressForm';
import BasicDataForm from '../../components/Forms/BasicDataForm';
import ProfessionalDataForm from '../../components/Forms/ProfessionalDataForm';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import ProfessionalProfile from '../../components/ProfessionalProfile';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { states } from '../../utils/filterOptions';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';
import { getProfessionName } from '../../utils/getProfessionName';
import getSortedDates from '../../utils/getSortedDates';
import getSortedPlans from '../../utils/getSortedPlans';
import getSortedStates from '../../utils/getSortedStates';
import validateCPF from '../../utils/validateCPF';
import { serializeInfoRequired } from '../../utils/validateInfoRequired';

export const ProfessionalsList = () => {
  const location = useLocation();
  const type = location?.pathname.includes('clinic')
    ? 'clinic'
    : 'professional';
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [professional, setProfessional] = useState(null);
  const [professionalProfile, setProfessionalProfile] = useState(null);
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [missingData, setMissingData] = useState(null);
  const navigate = useNavigate();

  const schema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'Telefone inválido',
      test(value) {
        const maskDefaultValue = '_';
        const str = value;
        const result = str?.search(maskDefaultValue);
        return result > -1 ? false : true;
      },
    }),
    documentNumber: Yup.string()
      .max(15, 'CPF deve conter no máximo 15 caracteres')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '');
        },
      }),
    birthday: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Data de nascimento inválida',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    ageGroups: Yup.array(),
    presentationVideo: Yup.string(),
    professionalDocumentNumber: Yup.string(),
    academicBackground: Yup.array().min(1, 'Apresente ao menos uma formação'),
    specialities: Yup.array(),
    reasons: Yup.array(),
    approaches: Yup.array(),
    bio: Yup.string(),
    sessionPrice: Yup.number('Valor deve ser numérico').min(
      1,
      'Valor não pode ser inferior a 1'
    ),
    minutes: Yup.number(),
    hours: Yup.number(),
  });

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthday: '',
      documentNumber: '',
      addressId: null,
      cep: '',
      street: '',
      streetNumber: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      professionalDocumentNumber: '',
      profession: '',
      specialities: [],
      approaches: [],
      reasons: [],
      bio: '',
      presentationVideo: '',
      ageGroups: [],
      yearsOfExperience: 0,
      sessionPrice: 0,
    },
    validationSchema: schema,
  });

  useEffect(() => {
    const controller = new AbortController();
    const getProfessionals = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `${type}s/${location?.state?.clinicId}/professionals`,
          {
            signal: controller.signal,
          }
        );

        const parsedData = data.data.map((p) => ({
          id: parseInt(p.id),
          ...p.attributes,
          fullName: `${p.attributes.firstName} ${p.attributes.lastName}`,
          clinicName: p.attributes.clinic?.name,
        }));

        setProfessionals(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar profissionais! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar profissionais');
      } finally {
        setLoading(false);
      }
    };

    getProfessionals();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    form.setValues({
      ...professional,
      cep: professional?.address?.cep,
      street: professional?.address?.street,
      neighborhood: professional?.address?.neighborhood,
      streetNumber: professional?.address?.street_number,
      complement: professional?.address?.complement,
      country: professional?.address?.country,
      state: professional?.address?.state,
      city: professional?.address?.city,
    });
  }, [professional]);

  const getProfessional = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/professionals/${id}}`);

      const parsedData = {
        id: parseInt(id, 10),
        ...data?.data?.attributes,
        addressId: data?.data?.attributes?.address?.id,
        clinicName: data?.data?.attributes?.clinic?.name,
      };

      setProfessional(parsedData);
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao carregar profissional! Erro status - ${error.response?.status}`
        );
      else toast.error('Erro ao carregar profissional');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      setIsEdit(false);
      setLoading(true);
      const request = {
        data: {
          attributes: {
            firstName: form?.values?.firstName,
            lastName: form?.values?.lastName,
            documentNumber: form?.values?.documentNumber,
            birthday: form?.values?.birthday,
            phone: form?.values?.phone,
            addressAttributes: {
              id: form?.values?.addressId,
              cep: form?.values?.cep,
              street: form?.values?.street,
              street_number: form?.values?.streetNumber,
              neighborhood: form?.values?.neighborhood,
              country: form?.values?.country,
              city: form?.values?.city,
              state: form?.values?.state,
              complement: form?.values?.complement,
            },
            professionalDocumentNumber:
              form?.values?.professionalDocumentNumber,
            profession: form?.values?.profession,
            specialities: form?.values?.specialities,
            approaches: form?.values?.approaches,
            reasons: form?.values?.reasons,
            bio: form?.values?.bio,
            presentationVideo: form?.values?.presentationVideo,
            ageGroups: form?.values?.ageGroups,
            yearsOfExperience: form?.values?.yearsOfExperience,
            sessionPrice: form?.values?.sessionPrice,
            termId: 1,
          },
        },
      };
      if (!request.data.attributes.addressAttributes.id)
        delete request.data.attributes.addressAttributes.id;
      const { data } = await api.put(
        `/professionals/${professional?.id}`,
        request
      );

      const parsedData = {
        id: parseInt(data?.data?.id),
        ...data?.data?.attributes,
        fullName: `${data?.data?.attributes.firstName} ${data?.data?.attributes.lastName}`,
      };

      const professionalIndex = professionals.findIndex(
        (p) => p.id === professional.id
      );

      const newProfessionals = [...professionals];
      newProfessionals[professionalIndex] = parsedData;
      setProfessionals(newProfessionals);

      toast.success('Profissional atualizado com sucesso!');
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao editar profissional! Erro status - ${error.response?.status}`
        );
      else toast.error('Erro ao atualizar profissional');
    } finally {
      setLoading(false);
      setProfessional(null);
    }
  };

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        filter: false,
        searchable: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <SmallUserAvatar
            src={value ? value : noAvatar}
            icon={
              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: -12,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setSelectedProfessional(
                    professionals.find((p) => p.id === rowData[1])
                  );
                }}
              >
                <Visibility />
              </IconButton>
            }
          />
        ),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        searchable: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fullName',
      label: 'Nome',
      options: {
        filterType: 'textField',
        display: 'excluded',
      },
    },
    {
      name: 'firstName',
      label: 'Nome',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            key={columnMeta.label}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flexWrap: 'nowrap',
            }}
          >
            <IconButton
              color="primary"
              sx={{
                borderRadius: '50%',
              }}
              key={rowData[2]}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setProfessionalProfile(
                  professionals.find((p) => p.id === rowData[1])
                )
              }
            >
              <Launch />
            </IconButton>
            {value}
          </Box>
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastName',
      label: 'Sobrenome',
      options: {
        display: false,
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clinicName',
      label: 'Clínica',
      options: {
        display: type === 'clinic',
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'profession',
      label: 'Especialidade',
      sortable: false,
      options: {
        filterType: 'multiselect',
        customBodyRender: (value) => {
          return getProfessionName(value, 'toPt');
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'schedulesCount',
      label: 'Agendamentos',
      options: {
        display: false,
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'reviewsCount',
      label: 'Avaliações',
      options: {
        display: false,
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'schedulesPromotionCount',
      label: 'Agendamentos Promocionais',
      options: {
        display: false,
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'subscription',
      label: 'Plano',
      options: {
        filterType: 'multiselect',
        filter: true,
        sortCompare: (order) => (plan1, plan2) =>
          getSortedPlans(plan1, plan2, order),
        customBodyRender: (value) => getConvertPlanPtBr(value.pagarmePlan),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'patients',
      label: 'Pacientes',
      sortable: false,
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() =>
                navigate('/profissionals-patients', {
                  state: { id: rowData[1] },
                })
              }
            >
              <Group />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'finance',
      label: 'Valores',
      sortable: false,
      options: {
        display: false,
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() =>
                navigate('/profissionals-finance', {
                  state: { id: rowData[1], status: 'approved' },
                })
              }
            >
              <AttachMoney />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[14]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'approvedAt',
      label: 'Data de Aprovação',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[14]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      sortable: false,
      options: {
        filterOptions: states,
        filterType: 'multiselect',
        searchable: false,
        customBodyRender: (value) =>
          value.state ? value.state : 'Não informado',
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <Chip
                label="Inativo"
                style={{
                  backgroundColor: 'red',
                  color: '#FFFFFF',
                  height: 17,
                }}
              />
            );
          }
          return (
            <Chip
              label="Ativo"
              style={{
                backgroundColor: 'green',
                color: '#FFFFFF',
                height: 17,
              }}
            />
          );
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={async () => {
                  await getProfessional(rowData[1]);
                  setIsEdit(true);
                }}
              >
                <Edit />
              </Button>
              <Button onClick={() => {}}>
                <Close color="error" />
              </Button>
            </div>
          );
        },
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Profissionais"
          data={professionals}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedProfessional !== null}
        handleClose={() => setSelectedProfessional(null)}
      >
        <Grid>
          <div style={{ display: 'flex' }}>
            {selectedProfessional?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedProfessional?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedProfessional?.firstName} {selectedProfessional?.lastName}
            </Typography>
          </div>

          {selectedProfessional?.clinicName && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Clinca vinculada
              </Typography>
              <Typography>{selectedProfessional?.clinicName}</Typography>
            </>
          )}

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Formação acadêmica
          </Typography>
          {selectedProfessional?.academicBackground?.map((ab, index) => (
            <Typography key={index}>* {ab}</Typography>
          ))}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Biografia
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.bio}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Política de cancelamento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.cancelPolicy}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.documentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.phone}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Video de apresentação
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <a href={selectedProfessional?.presentationVideo}>
              {selectedProfessional?.presentationVideo}
            </a>
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento profissional
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.professionalDocumentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Data de aprovação
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.approvedAt ||
              moment(selectedProfessional?.createdAt).format('DD/MM/YYYY')}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Preço da sessão
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {Number(selectedProfessional?.sessionPrice).toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={isEdit && professional !== null}
        handleClose={() => {
          setIsEdit(false);
          setProfessional(null);
        }}
        renderButtons={<Button onClick={handleEdit}>Salvar</Button>}
      >
        <BasicDataForm form={form} />
        <AddressForm sx={{ mt: 5 }} form={form} />
        <ProfessionalDataForm
          form={form}
          professional={professional}
          sx={{ mt: 5 }}
        />
      </ModalContent>

      <ModalContent
        open={professionalProfile !== null}
        handleClose={() => setProfessionalProfile(null)}
      >
        <ProfessionalProfile professional={professionalProfile} />
      </ModalContent>

      <ModalContent
        open={missingData !== null}
        handleClose={() => setMissingData(null)}
      >
        <Grid container direction="column">
          <Typography variant="h5" sx={{ m: 1, mb: 2 }}>
            Informações incompletas do profissional
          </Typography>
          {Object.keys(missingData || {}).map((key) => (
            <Grid container direction="row" sx={{ m: 1 }} key={key}>
              <Typography>{serializeInfoRequired(key)}:</Typography>
              <Typography sx={{ ml: 1, color: 'red' }}>Incompleto</Typography>
            </Grid>
          ))}
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
