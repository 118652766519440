import { Grid, TextField, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { MultipleSelect } from '../MultipleSelect';

export default function SchoolForm({ ...props }) {
  const { type, form } = props;
  const { values, errors, setFieldValue, handleChange, handleBlur } = form;

  const educationOptions = [
    'Ensino Fundamental 1',
    'Ensino Fundamental 2',
    'Ensino médio ',
    'Pré-fundamental',
  ];

  const handleChangeSelect = ({ target }) => {
    setFieldValue(
      'educationStages',
      typeof target.value === 'string' ? target.value.split(',') : target.value
    );
  };

  return (
    <Grid
      style={{ width: '100%' }}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      container
    >
      <Grid
        style={{ width: '100%' }}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        <Grid justifyContent="center" container>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {`${type} Escola`}
          </Typography>
          <Grid style={{ width: '100%' }}>
            <TextField
              sx={{ p: 0 }}
              id="name"
              variant="standard"
              label="Nome da escola"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              fullWidth
            />
            <TextError>{errors.name}</TextError>
          </Grid>
          <Grid style={{ width: '100%' }}>
            <TextField
              sx={{ p: 0 }}
              id="email"
              variant="standard"
              label="E-mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
            <TextError>{errors.email}</TextError>
          </Grid>
          <Grid style={{ width: '100%' }}>
            <MultipleSelect
              label="Tipo de escola"
              name="schoolType"
              value={values.educationStages}
              onChange={handleChangeSelect}
              onBlur={handleBlur}
              options={educationOptions}
            />
            <TextError>{errors.educationStages}</TextError>
          </Grid>
          <Grid style={{ width: '100%' }}>
            <TextField
              fullWidth
              inputProps={{ min: 0, max: 20 }}
              label="Desconto (%) para conveniados"
              name="discountPercentage"
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{ p: 0 }}
              type="number"
              value={values.discountPercentage}
              variant="standard"
            />
            <TextError>{errors.discountPercentage}</TextError>
          </Grid>
          <Grid
            style={{ width: '100%' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            container
          >
            <Grid style={{ width: '40%' }}>
              <InputMask
                alwaysShowMask={false}
                mask="(99)99999-9999"
                maskChar=" "
                maskPlaceholder={null}
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
              >
                <TextField
                  sx={{ p: 0 }}
                  variant="standard"
                  label="Telefone"
                  fullWidth
                />
              </InputMask>
              <TextError>{errors.phone}</TextError>
            </Grid>
            <Grid style={{ width: '40%' }}>
              <InputMask
                mask="99.999.999/9999-99"
                maskPlaceholder={null}
                name="documentNumber"
                value={values.documentNumber}
                maskChar=" "
                alwaysShowMask={true}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <TextField
                  sx={{ p: 0 }}
                  variant="standard"
                  label="CNPJ"
                  fullWidth
                />
              </InputMask>
              <TextError>{errors.documentNumber}</TextError>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const TextError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
`;
