import { Container } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const ContentContainer = ({ children }) => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      paddingTop: 15,
    }}
  >
    <Container maxWidth="xl" sx={{ height: '95%' }}>
      {children}
    </Container>
  </Box>
);
