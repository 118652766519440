/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { getSortedDatesHours } from '../../utils/getSortedDates';

export const ProfessionalsFinance = () => {
  const { state } = useLocation();
  const [selectedProfessional, setSelectedProfessional] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const getProfessionals = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          `/professionals/by_status?status=${state?.status}`,
          {
            signal: controller.signal,
          }
        );
        const parsedData = data.data.filter(
          (p) => parseInt(p.id, 10) === state.id
        );
        setSelectedProfessional(parsedData[0]?.attributes);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar profissionais! Erro status- ${error.response?.status}`
          );
        else toast.error('Erro ao carregar profissionais');
      } finally {
        setLoading(false);
      }
    };

    getProfessionals();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const getTransactions = async () => {
      setLoadingTransactions(true);
      // const { data } = await axios.get(
      //   "https://mocki.io/v1/6b629e74-2caa-4678-9e86-624312610fbb"
      // );
      try {
        const { data } = await api.get(
          `/professionals/${state.id}/transactions`,
          {
            signal: controller.signal,
          }
        );
        const parsedData = data.data.attributes.payables.map((p) => ({
          ...p,
          name: `${p.client.firstName} ${p.client.lastName}`,
          email: p.client.email,
          dateTime: moment(p.dateCreated, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm'
          ),
        }));
        setBalance(data.data.attributes.balance);
        setTransactions(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        setBalance({});
        setTransactions([]);
        if (error.response?.status)
          toast.error(
            `Erro ao carregar transações! Erro - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar transações!');
      } finally {
        setLoadingTransactions(false);
      }
    };

    getTransactions();

    return () => controller.abort();
  }, []);

  const columns = [
    {
      name: 'inputOutput',
      label: ' ',
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => {
          if (rowData[5] === 'refund') {
            return <RemoveCircleRoundedIcon />;
          } else {
            return <AddCircleRoundedIcon />;
          }
        },
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'total',
      label: 'Valor',
      options: {
        customBodyRender: (_value, { rowData }) => {
          if (rowData[5] === 'refund') {
            return `R$${Number(_value * -1).toFixed(2)}`;
          } else {
            return `R$${Number(_value).toFixed(2)}`;
          }
        },
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'name',
      label: 'Nome do Cliente',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'Email do Cliente',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'pagarmeTransactionId',
      label: 'Id',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filterType: 'multiselect',
        customBodyRender: (value) => {
          switch (value) {
            case 'credit':
              return 'Crédito';
            case 'refund':
              return 'Reembolso';
            default:
              return 'Outro';
          }
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'dateTime',
      label: 'Data/Hora',
      options: {
        filterType: 'textField',
        sortCompare: (order) => (date1, date2) =>
          getSortedDatesHours(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading || loadingTransactions ? (
        <Loading />
      ) : (
        <>
          {selectedProfessional && (
            <Box
              sx={{
                boxShadow: 24,
                bgcolor: 'background.paper',
                borderRadius: 10,
                display: 'inline-flex',
                left: '0%',
                p: 2,
                top: '10%',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: '80%',
                }}
              >
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    {selectedProfessional.avatar === null ? (
                      <SmallUserAvatar src={noAvatar} />
                    ) : (
                      <SmallUserAvatar src={selectedProfessional.avatar} />
                    )}

                    <Typography variant="h4" sx={{ ml: 2 }}>
                      {selectedProfessional.firstName}{' '}
                      {selectedProfessional.lastName}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <section style={{ paddingRight: '45px' }}>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        A Vencer
                      </Typography>
                      <Typography sx={{ mt: 1 }} align={'center'}>
                        {balance.waitingFunds
                          ? `R$ ${Number(balance?.waitingFunds).toFixed(2)}`
                          : 'R$ 0.00'}
                      </Typography>
                    </section>

                    <section>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Aguardando N.F
                      </Typography>
                      <Typography sx={{ mt: 1 }} align={'center'}>
                        {'R$ 0.00'}
                      </Typography>
                    </section>
                  </div>
                </section>
              </div>
            </Box>
          )}
          <LayoutMUIDataTable
            title="Valores e Transações"
            data={transactions}
            columns={columns}
          />
        </>
      )}
    </AuthorizedLayout>
  );
};
