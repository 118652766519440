/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Loading from '../../../../components/Loading';
import ModalContent from '../../../../components/Modais/ModalContent';
import { AuthorizedLayout } from '../../../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../../../layouts/LayoutMUIDataTable';
import api from '../../../../services/api';
import { visibilities } from '../../../../utils/dataOptions';
import { getConvertMembersType } from '../../../../utils/getConvertPlanPtBr';
import { trimString } from '../../../../utils/trimString';
import { LessonComponent } from './Classes';

export default function ModulesOf() {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [classesLoading, setClassesLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [classesList, setClassesList] = useState(null);
  const [editModal, setEditModal] = useState({
    open: false,
    selectedModule: null,
  });
  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    const getModules = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/courses/${courseId}}/show_modules`);
        if (data.data) {
          const parseData = data.data
            .map((module) => ({
              ...module.attributes,
              id: parseInt(module.id),
            }))
            .sort((a, b) => a.moduleOrder - b.moduleOrder);
          setModules(parseData);
        }
      } catch (error) {
        toast.error('Erro ao carregar os módulos!');
      } finally {
        setLoading(false);
      }
    };
    getModules();
  }, []);

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue('image', reader.result);
    };
    reader.readAsDataURL(file);
  };

  const getClasses = async (module) => {
    try {
      setClassesLoading(true);
      const data = await api.get(`course_modules/${module.id}`);
      if (data.data) {
        setClassesList(data.data.data.attributes.lessons);
        setSelectedModule({
          id: module.id,
          title: module.title,
        });
      }
    } catch (error) {
      toast.error('Erro ao carregar as aulas!');
    } finally {
      setClassesLoading(false);
    }
  };

  const handleModuleOrder = async (moduleId) => {
    const moduleIndex = modules.findIndex((module) => module.id === moduleId);
    const currentModule = modules[moduleIndex];

    if (!currentModule) {
      console.error('Módulo não encontrado.');
      return;
    }

    const currentModuleOrder = currentModule.moduleOrder;

    if (moduleIndex > 0) {
      const prevModule = modules[moduleIndex - 1];
      const moduleOrderNext = prevModule.moduleOrder;
      currentModule.moduleOrder = moduleOrderNext;
      prevModule.moduleOrder = currentModuleOrder;
      try {
        setLoading(true);
        const request = (moduleOrder) => ({
          data: {
            attributes: {
              courseId: Number(courseId),
              moduleOrder,
            },
          },
        });
        await api.put(
          `/course_modules/${currentModule.id}`,
          request(currentModule.moduleOrder)
        );
        await api.put(
          `/course_modules/${prevModule.id}`,
          request(prevModule.moduleOrder)
        );
        modules[moduleIndex] = prevModule;
        modules[moduleIndex - 1] = currentModule;
        setModules(modules);
        setReRender(!reRender);
      } catch (error) {
        toast.error('Erro ao atualizar a ordem dos módulos!');
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteModule = async (id) => {
    try {
    } catch (error) {
    } finally {
    }
  };

  const updateModule = async (values) => {
    try {
      const request = {
        data: {
          attributes: {
            title: values.title,
            image: { data: values.image },
            courseId: Number(values.courseId),
            visibility: values.visibility,
            moduleOrder: values.moduleOrder,
          },
        },
      };

      if (values.image === editModal.selectedModule?.image) {
        delete request.data.attributes.image;
      }

      const { data } = await api.put(`/course_modules/${values.id}`, request);

      if (data.data) {
        toast.success('Módulo atualizado com sucesso!');
        const updateModule = {
          ...data.data.attributes,
          id: parseInt(data.data.id),
        };

        const moludeindex = modules.findIndex(
          (module) => module.id === updateModule.id
        );

        if (moludeindex >= 0) {
          modules[moludeindex] = updateModule;
        }

        modules.sort((a, b) => a.moduleOrder - b.moduleOrder);
        setModules(modules);
      }
    } catch (error) {
      toast.error('Erro ao atualizar o módulo!');
    } finally {
      setEditModal({
        open: false,
        selectedModule: null,
      });
    }
  };

  const editForm = (moduleId) => {
    const module = modules.find((module) => module.id === moduleId);
    setEditModal({
      open: true,
      selectedModule: module,
    });
    form.setFieldValue('id', module?.id);
    form.setFieldValue('title', module?.title);
    form.setFieldValue('image', module?.image);
    form.setFieldValue('courseId', courseId);
    form.setFieldValue('visibility', module?.visibility);
    form.setFieldValue('moduleOrder', module?.moduleOrder);
  };

  const schema = yup.object().shape({
    title: yup.string().required('Campo obrigatório'),
    image: yup.string().required('Campo obrigatório'),
    visibility: yup.array().required('Campo obrigatório'),
    moduleOrder: yup.number().required('Campo obrigatório'),
  });

  const form = useFormik({
    initialValues: {
      title: '',
      image: '',
      courseId: '',
      id: '',
      moduleOrder: 1,
      visibility: [],
    },

    onSubmit: (values) => updateModule(values),
    validateYupSchema: true,
    validationSchema: schema,
  });

  const conlumnsModules = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'title',
      label: 'Título',
    },
    {
      name: 'image',
      label: 'Imagem',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <img
              src={value}
              style={{ width: 100, height: 100, borderRadius: 10 }}
              alt="Imagem do módulo"
            />
          );
        },
      },
    },
    {
      name: 'visibility',
      label: 'Visibilidade',
      options: {
        customBodyRender: (value) => {
          if (value.length < 1) {
            return <Chip label="Não configurado/Todos" />;
          } else {
            return (
              <Chip
                sx={{ mr: 1 }}
                label={trimString(
                  value
                    .map((visibility) => getConvertMembersType(visibility))
                    .join(', '),
                  30
                )}
              />
            );
          }
        },
      },
    },
    {
      name: 'moduleOrder',
      label: 'Ordem do modulo',
      options: {
        filter: false,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      filter: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              disabled={rowData[4] === 1}
              onClick={() => handleModuleOrder(rowData[0])}
            >
              <ArrowUpwardIcon titleAccess="Mover modulo" />
            </Button>

            <Button
              onClick={() =>
                getClasses({
                  id: rowData[0],
                  title: rowData[1],
                })
              }
            >
              <LaunchIcon titleAccess="Abrir" />
            </Button>

            <Button onClick={() => editForm(rowData[0])}>
              <EditIcon titleAccess="Editar" />
            </Button>

            <Button onClick={() => deleteModule(rowData[0])}>
              <Close color="error" titleAccess="Deletar" />
            </Button>
          </div>
        ),
      },
    },
    {
      name: 'classes',
      label: 'Aulas',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
  ];

  const HeaderElementsClasses = () => (
    <Button
      onClick={() =>
        navigate(`/courses/${courseId}/new-module`, {
          state: { courseId },
        })
      }
    >
      Criar Módulo <AddIcon />
    </Button>
  );

  return (
    <AuthorizedLayout>
      {loading || classesLoading ? (
        <Loading />
      ) : (
        <>
          {classesList ? (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => navigate(`new-lesson/${selectedModule.id}`)}
                  style={{ alignSelf: 'flex-start' }}
                >
                  Adicionar Aula +
                </Button>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h6"
                  alignSelf="center"
                >{`${selectedModule.title} > Aulas`}</Typography>
                {classesList?.length > 0 ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(400px, 1fr))',
                      gridGap: '1rem',
                      marginTop: '1rem',
                    }}
                  >
                    {classesList.map((less) => {
                      return (
                        <LessonComponent
                          key={less.id}
                          lesson={less}
                          setClassesList={setClassesList}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <Typography variant="h6">Nenhuma aula cadastrada</Typography>
                )}
              </Box>
            </Box>
          ) : (
            <LayoutMUIDataTable
              data={modules}
              columns={conlumnsModules}
              title="Módulos"
              customToolbar={<HeaderElementsClasses />}
            />
          )}
        </>
      )}

      <ModalContent
        open={editModal.open}
        handleClose={() =>
          setEditModal({
            open: false,
            selectedCourse: null,
          })
        }
      >
        <Grid item sx={12}>
          <label
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 25,
            }}
          >
            <img
              src={form.values.image}
              alt="Imagem do curso"
              style={{ width: 400, height: 400, borderRadius: 10 }}
            />
            <input
              placeholder="Carregar imagem"
              type="file"
              accept=".jpeg, .png, .jpg"
              onChange={(e) => {
                const file = e.target.files[0];
                storeAvatarAsBase64(file);
              }}
              style={{ display: 'none' }}
            />
          </label>

          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="title"
              label="Título"
              onChange={form.handleChange}
              sx={{ mb: 2 }}
              value={form.values.title}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="number"
              inputProps={{ min: form.values.moduleOrder + 1 }}
              id="moduleOrder"
              label="Ordem do modulo"
              onChange={form.handleChange}
              sx={{ mb: 2 }}
              value={form.values.moduleOrder}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={form.values.visibility}
                multiple
                onChange={(e) =>
                  form.setFieldValue('visibility', e.target.value)
                }
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((value) => (
                        <Chip
                          key={value}
                          label={getConvertMembersType(value)}
                          sx={{ mr: 1 }}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Button
            style={{ marginTop: 25, alignSelf: 'flex-end' }}
            onClick={() => form.handleSubmit()}
          >
            Salvar Alterações
          </Button>
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
}
