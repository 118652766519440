/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { Check, Close } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Chip,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import ProfessionalProfile from '../../components/ProfessionalProfile';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { Item, ItemProfile } from '../../styles';
import { states } from '../../utils/filterOptions';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';
import { getProfessionName } from '../../utils/getProfessionName';
import getSortedDates from '../../utils/getSortedDates';
import getSortedPlans from '../../utils/getSortedPlans';
import getSortedStates from '../../utils/getSortedStates';

export const ProfessionalsPending = () => {
  const [values, setValues] = useState({});
  const [professionals, setProfessionals] = useState([]);
  const [professional, setProfessional] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedProfessionalStatus, setselectedProfessionalStatus] =
    useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const getProfessionals = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          '/professionals/by_status?status=pending',
          {
            signal: controller.signal,
          }
        );
        const parsedData = data.data.map((p) => ({
          id: parseInt(p.id),
          ...p.attributes,
          fullName: `${p.attributes.firstName} ${p.attributes.lastName}`,
          typeStatus: p.attributes.deletedAt ? 'Inativo' : 'Ativo',
        }));

        const sortedData = parsedData.sort((a, b) => {
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        });

        setProfessionals(sortedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar profissionais! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar profissionais!');
      } finally {
        setLoading(false);
      }
    };

    getProfessionals();

    return () => controller.abort();
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const updateProfessionalStatusApproved = async (professionalId) => {
    try {
      await api.put(`/professionals/${professionalId}`, {
        data: { attributes: { status: 'approved' } },
      });
      setProfessionals(
        professionals.filter((p) => Number(p.id) !== Number(professionalId))
      );
      toast.success('Profissional aprovado.');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao mudar status de profissional! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de profissional!');
    }
  };

  const updateProfessionalStatusDisapproved = async (
    professionalId,
    reason
  ) => {
    try {
      await api.put(`/professionals/${professionalId}`, {
        data: {
          type: 'professional',
          attributes: {
            status: 'disapproved',
            'disapproved-reason': reason,
          },
        },
      });
      setProfessionals(
        professionals.filter((p) => Number(p.id) !== Number(professionalId))
      );
      toast.success('Profissional reprovados.');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao mudar status de profissional! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de profissional');
    }
  };

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            key={columnMeta.label}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <SmallUserAvatar
            src={value ? value : noAvatar}
            icon={
              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: -12,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setSelectedProfessional(
                    professionals.filter((p) => p.id === rowData[1])[0]
                  );
                }}
              >
                <VisibilityIcon />
              </IconButton>
            }
          />
        ),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'firstName',
      label: 'Nome',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            key={columnMeta.label}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flexWrap: 'nowrap',
            }}
          >
            <IconButton
              color="primary"
              sx={{
                borderRadius: '50%',
              }}
              key={rowData[2]}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setProfessional(
                  professionals.filter((p) => p.id === rowData[1])[0]
                )
              }
            >
              <LaunchIcon />
            </IconButton>
            {value}
          </Box>
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastName',
      label: 'Sobrenome',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'fullName',
      label: 'Nome',
      options: {
        display: 'excluded',
        filterType: 'textField',
      },
    },
    {
      name: 'profession',
      label: 'Especialidade',
      sortable: false,
      options: {
        filterType: 'multiselect',
        customBodyRender: (value) => getProfessionName(value, 'toPt'),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'subscription',
      label: 'Plano',
      options: {
        customBodyRender: (value) => getConvertPlanPtBr(value.pagarmePlan),
        filterType: 'multiselect',
        sortCompare: (order) => (plan1, plan2) =>
          getSortedPlans(plan1, plan2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[9]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      sortable: false,
      options: {
        customBodyRender: (value) => {
          if (value.state) {
            return value.state;
          }
          return 'Não informado';
        },
        filterType: 'multiselect',
        filterOptions: states,
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <Chip
                label="Inativo"
                style={{
                  backgroundColor: 'red',
                  color: '#FFFFFF',
                  height: 17,
                }}
              />
            );
          }
          return (
            <Chip
              label="Ativo"
              style={{
                backgroundColor: 'green',
                color: '#FFFFFF',
                height: 17,
              }}
            />
          );
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() =>
                  updateProfessionalStatusApproved(rowData[1], 'approved')
                }
              >
                <Check />
              </Button>

              <Button
                onClick={() =>
                  setselectedProfessionalStatus(
                    professionals.filter((p) => p.id === rowData[1])[0]
                  )
                }
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'typeStatus',
      label: 'Status',
      options: {
        display: 'excluded',
        filterType: 'multiselect',
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Profissionais pendentes"
          data={professionals}
          columns={columns}
          key={selectedProfessional?.id}
        />
      )}

      <ModalContent
        open={selectedProfessional !== null}
        handleClose={() => setSelectedProfessional(null)}
      >
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid
            alignItems="flex-start"
            container
            justifyContent="flex-start"
            spacing={3}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                },
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                },
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              })}
            >
              <Item sx={{ mr: 1 }}>
                {selectedProfessional?.avatar === null ? (
                  <SmallUserAvatar src={noAvatar} />
                ) : (
                  <SmallUserAvatar src={selectedProfessional?.avatar} />
                )}
              </Item>
              <Item>
                <Typography variant="h4">
                  {selectedProfessional?.firstName}{' '}
                  {selectedProfessional?.lastName}
                </Typography>
              </Item>
            </Box>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Formação acadêmica</Typography>
                {selectedProfessional?.academicBackground &&
                  selectedProfessional?.academicBackground.map((ab) => (
                    <Typography>* {ab}</Typography>
                  ))}
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Biografia</Typography>
                <Typography>{selectedProfessional?.bio}</Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Política de cancelamento</Typography>
                <Typography>{selectedProfessional?.cancelPolicy}</Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Documento</Typography>
                <Typography>{selectedProfessional?.documentNumber}</Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Telefone</Typography>
                <Typography>{selectedProfessional?.phone}</Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Video de apresentação</Typography>
                <Typography>
                  <a href={selectedProfessional?.presentationVideo}>
                    {selectedProfessional?.presentationVideo}
                  </a>
                </Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Documento profissional</Typography>
                <Typography>
                  {selectedProfessional?.professionalDocumentFile ? (
                    <a
                      href={selectedProfessional?.professionalDocumentFile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Abrir documento
                    </a>
                  ) : selectedProfessional?.professionalDocumentNumber ? (
                    selectedProfessional?.professionalDocumentNumber
                  ) : (
                    ''
                  )}
                </Typography>
              </ItemProfile>
            </Grid>

            <Grid item xs={12}>
              <ItemProfile>
                <Typography variant="h6">Preço da sessão</Typography>
                <Typography>
                  {Number(selectedProfessional?.sessionPrice).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </Typography>
              </ItemProfile>
            </Grid>
          </Grid>
        </Box>
      </ModalContent>

      <ModalContent
        open={professional !== null}
        handleClose={() => setProfessional(null)}
      >
        <ProfessionalProfile professional={professional} />
      </ModalContent>

      <ModalContent
        open={selectedProfessionalStatus !== null}
        handleClose={() => setselectedProfessionalStatus(null)}
      >
        <Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
              Descreva os motivos para não aprovação do profissional.
            </Typography>
            <TextField
              value={values?.reason}
              onChange={handleChange('reason')}
              fullWidth
              label="Descrição"
              multiline
              rows={6}
            />
          </Grid>
          <Grid
            style={{ marginTop: '2rem' }}
            justifyContent="space-around"
            container
            row
          >
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                setValues({});
                setselectedProfessionalStatus(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => {
                updateProfessionalStatusDisapproved(
                  selectedProfessionalStatus.id,
                  values?.reason
                );
                setValues({});
                setselectedProfessionalStatus(null);
              }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
