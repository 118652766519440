import { Box } from '@mui/system';
import React, { useState } from 'react';

import { MainSidebar } from '../../components/MainSidebar';
import { MainTopbar } from '../../components/MainTopbar';
import { ContentContainer } from './ContentContainer';

export const AuthorizedLayout = ({ children }) => {
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);

  return (
    <Box>
      <MainTopbar
        mainSidebarVisible={mainSidebarVisible}
        setMainSidebarVisible={setMainSidebarVisible}
      />
      <MainSidebar
        visible={mainSidebarVisible}
        setVisible={setMainSidebarVisible}
      />
      <ContentContainer>{children}</ContentContainer>
    </Box>
  );
};
