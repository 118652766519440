import { createTheme } from '@mui/material';
import { ptBR } from '@mui/x-date-pickers/locales';
import { ptBR as dataGridPtBR } from '@mui/x-data-grid';
import { ptBR as corePtBR } from '@mui/material/locale';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#426AFF',
    },
  },
  ptBR,
  dataGridPtBR,
  corePtBR,
});

export const styleGlobal = {
  'html, body, #root': {
    '&::-webkit-scrollbar': { display: 'none' },
  },
  '&::-webkit-scrollbar': {
    backgroundColor: '#eaecf4',
    height: '0.5rem',
    width: '0.5rem',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#ced0db',
    borderRadius: '0.5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#999ebc',
    borderRadius: '0.5rem',
  },
};
