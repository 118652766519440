/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { BLOCK_TYPE, INLINE_STYLE } from 'draftail';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loading';
import noAvatar from '../../../../images/noAvatar.png';
import { AuthorizedLayout } from '../../../../layouts/AuthorizedLayout';
import api from '../../../../services/api';
import { StyledDraftailEditor, StyledPage } from '../styles.js';
import { getConvertMembersType } from '../../../../utils/getConvertPlanPtBr';
import { visibilities } from '../../../../utils/dataOptions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = {
  padding: '2rem',
  width: '80%',
  margin: '10px auto',
};

export const NewArticle = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    title: '',
    description: '',
    body: '',
    image: '',
    postType: 'article',
    categoryIds: [],
    visibility: [],
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [radio, setRadio] = useState({ name: '' });
  const { state } = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [file64, setFile64] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleCreateArticle = (e) => {
    if (
      values.title !== '' &&
      values.description !== '' &&
      selectedCategories.length !== 0
    ) {
      if (
        editorState.getCurrentContent().getPlainText() !== '' ||
        selectedFile !== null
      ) {
        if (values.image !== '') {
          createArticle();
        } else {
          toast.error('O artigo deve conter uma imagem!');
        }
      } else {
        toast.error('É obrigatório preencher todos os campos!');
      }
    } else {
      toast.error('É obrigatório preencher todos os campos!');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const _handleReaderLoaded = (_filename, extension, readerEvt) => {
    let bynaryString = readerEvt.target.result;
    let auxValues = values;
    auxValues.image = `data:${extension};base64,${btoa(bynaryString)}`;
    setValues({ ...auxValues });
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0]?.name;
    const extension = e.target.files[0]?.type;

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this, filename, extension);
      reader.readAsBinaryString(file);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await api.get(`/categories`);

      if (data.data) {
        const parseData = data.data.map((p) => ({
          ...p.attributes,
          id: parseInt(p.id),
        }));
        setCategories(parseData);
      } else {
        throw new Error('Erro ao carregar categorias!');
      }
    } catch (error) {
      toast.error('Erro ao carregar categorias!');
    }
  };

  const getArticle = async (articleId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/posts/${articleId}`);
      setValues({
        title: data.data.attributes.title,
        description: data.data.attributes.description || '',
        image: data.data.attributes.image || null,
        body: data.data.attributes.body || '',
        postType: 'article',
        categoryIds: data.data.attributes.categoryIds || [],
        visibility: data.data.attributes.visibility || [],
      });
      if (
        String(data.data.attributes.body).startsWith(
          'data:application/pdf;base64,'
        )
      ) {
        setRadio({ name: 'file' });
        setEditorContent('');
        setFile64(data.data.attributes.body);
        setSelectedFile(true);
        setIsFilePicked(true);
      } else if (data.data.attributes.body !== '') {
        setRadio({ name: 'article' });
        setEditorContent(data.data.attributes.body);
        setFile64(null);
        setIsFilePicked(false);
        setSelectedFile(null);
      }
      setSelectedCategories(data.data.attributes.categoryIds);
      setLoading(false);
    } catch (error) {
      toast.error(`Erro ao carregar artigo`);
      setLoading(false);
    }
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const updateArticle = async (articleId) => {
    let response = {};

    if (isFilePicked === false) {
      if (!isValidHttpUrl(values.image)) {
        response = {
          data: {
            attributes: {
              title: values.title,
              description: values.description,
              body: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
              ),
              image: values.image ? { data: values.image } : null,
              categoryIds: selectedCategories,
              postType: values.postType,
              visibility: values.visibility,
            },
          },
        };
      } else {
        response = {
          data: {
            attributes: {
              title: values.title,
              description: values.description,
              body: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
              ),
              postType: values.postType,
              categoryIds: selectedCategories,
              visibility: values.visibility,
            },
          },
        };
      }

      try {
        const { data } = await api.put(`/posts/${articleId}`, response);

        if (data.data) {
          navigate('/contents', { state: { to: 'artigos' } });
        } else {
          throw new Error('Erro ao atualizar artigo!');
        }
      } catch (error) {
        toast.error('Erro ao atualizar artigo!');
      }
    } else if (isFilePicked === true) {
      if (!isValidHttpUrl(values.image)) {
        response = {
          data: {
            attributes: {
              title: values.title,
              description: values.description,
              body: file64,
              image: values.image ? { data: values.image } : null,
              categoryIds: selectedCategories,
              visibility: values.visibility,
            },
          },
        };
      } else {
        response = {
          data: {
            attributes: {
              title: values.title,
              description: values.description,
              body: file64,
              categoryIds: selectedCategories,
              visibility: values.visibility,
            },
          },
        };
      }

      try {
        const { data } = await api.put(`/posts/${articleId}`, response);

        if (data.data) {
          navigate('/contents', { state: { to: 'artigos' } });
        } else {
          throw new Error('Erro ao atualizar artigo!');
        }
      } catch (error) {
        toast.error('Erro ao atualizar artigo!');
      }
    }
  };

  const handleUpdateArticle = (e) => {
    if (values.title !== '' && selectedCategories.length !== 0) {
      if (
        editorState.getCurrentContent().getPlainText() !== '' ||
        selectedFile !== null
      ) {
        if (values.image !== '') {
          updateArticle(state.id);
        } else {
          toast.error('O artigo deve conter uma imagem!');
        }
      } else {
        toast.error('É obrigatório preencher todos os campos!');
      }
    } else {
      toast.error('É obrigatório preencher todos os campos!');
    }
  };

  useEffect(() => {
    if (editorContent) {
      try {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(editorContent))
          )
        );
      } catch (error) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(editorContent)
          )
        );
      }
    }
  }, [editorContent]);

  useEffect(() => {
    getCategories();
    if (state) {
      getArticle(state.id);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isFilePicked === true) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = (e) => {
        setFile64(e.target.result);
      };
    }
  }, [selectedFile]);

  const createArticle = async () => {
    try {
      const request = {
        data: {
          attributes: {
            title: values.title,
            description: values.description,
            body: isFilePicked
              ? file64
              : JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            image: {
              data: values.image,
            },
            postType: 'article',
            visibility: values.visibility,
            categoryIds: selectedCategories,
          },
        },
      };
      const { data } = await api.post('/posts', request);
      if (data.data) {
        navigate('/contents', { state: { to: 'artigos' } });
      } else {
        throw new Error('Não foi possível criar artigo!');
      }
    } catch (error) {
      toast.error('Erro ao criar artigo');
    }
  };

  const deleteArticle = async (articleId) => {
    try {
      const { data } = await api.delete(`/posts/${articleId}`);

      if (data.data) {
        navigate('/contents', { state: { to: 'artigos' } });
      } else {
        throw new Error('Erro ao apagar artigo!');
      }
    } catch (error) {
      toast.error('Erro ao apagar artigo!');
    }
  };

  const handleDeleteArticle = (e) => {
    if (state) {
      deleteArticle(state.id);
    }
  };

  const categoriesSelectionChangeHandler = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleFile = ({ target }) => {
    setSelectedFile(target.files[0]);
    setIsFilePicked(true);
  };

  const handleChange = ({ target }) => {
    setRadio({ name: target.value });
    if (target.value === 'article') {
      setSelectedFile(null);
      setIsFilePicked(false);
      setFile64(null);
    } else if (target.value === 'file') {
      setEditorState(EditorState.createEmpty());
    }
  };

  const nextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} style={style}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">Artigo</Typography>
          </Grid>
          <Grid item xs={12} direction="column" align="center">
            <label>
              <img
                src={values.image || noAvatar}
                alt=""
                height="250"
                width="250"
              />
              <input
                hidden
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg, .pdf"
                multiple
                onChange={handleChangeImage}
              />
            </label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              variant="standard"
              label="Título"
              value={values.title}
              onChange={(e) => {
                setValues({ ...values, title: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              id="title"
              variant="standard"
              label="Descrição"
              value={values.description}
              onChange={({ target }) => {
                setValues({ ...values, description: target.value });
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginTop: '25px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <RadioGroup value={radio.name} onChange={handleChange}>
                <FormControlLabel
                  value="article"
                  control={<Radio />}
                  label="Escrever artigo"
                />
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="Inserir arquivo"
                />
              </RadioGroup>
              {radio?.name === 'article' && (
                <StyledDraftailEditor
                  editorState={editorState}
                  onChange={setEditorState}
                  showUndoControl={{
                    description: 'Desfazer última mudança',
                  }}
                  showRedoControl={{
                    description: 'Refazer última mudança',
                  }}
                  blockTypes={[
                    { type: BLOCK_TYPE.HEADER_TWO },
                    { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
                    { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
                    { type: BLOCK_TYPE.BLOCKQUOTE },
                  ]}
                  inlineStyles={[
                    { type: INLINE_STYLE.BOLD },
                    { type: INLINE_STYLE.ITALIC },
                    { type: INLINE_STYLE.UNDERLINE },
                    { type: INLINE_STYLE.STRIKETHROUGH },
                    { type: INLINE_STYLE.SUBSCRIPT },
                    { type: INLINE_STYLE.SUPERSCRIPT },
                    { type: INLINE_STYLE.QUOTATION },
                  ]}
                />
              )}
              {radio?.name === 'file' && (
                <div>
                  <label htmlFor="fileInput">
                    <input
                      id="fileInput"
                      name="fileInput"
                      onChange={handleFile}
                      type="file"
                      accept=".pdf"
                      style={{ display: 'none' }}
                    />
                    <Button variant="contained" component="span" fullWidth>
                      Selecionar PDF
                    </Button>
                  </label>
                  {isFilePicked === true && (
                    <div>
                      <TextField
                        id="fileName"
                        variant="standard"
                        value={selectedFile?.name}
                        disabled={true}
                        fullWidth
                      />
                      <StyledPage>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="space-between"
                        >
                          <Button onClick={previousPage}>{'<<<'}</Button>
                          <p>
                            Página {pageNumber} de {numPages}
                          </p>
                          <Button onClick={nextPage}>{'>>>'}</Button>
                        </Grid>
                        <Document
                          file={file64}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            size="A4"
                            renderTextLayer={false}
                            pageNumber={pageNumber}
                            width={window.innerWidth} // Defina a largura da página
                            height={window.innerHeight} // Defina a altura da página
                          />
                        </Document>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="space-between"
                        >
                          <Button onClick={previousPage}>{'<<<'}</Button>
                          <p>
                            Página {pageNumber} de {numPages}
                          </p>
                          <Button onClick={nextPage}>{'>>>'}</Button>
                        </Grid>
                      </StyledPage>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Categoria</InputLabel>
              {categories.length > 0 && (
                <Select
                  value={selectedCategories}
                  onChange={categoriesSelectionChangeHandler}
                  multiple
                  label={'Categoria'}
                  renderValue={(selectedCategories) => (
                    <Box>
                      {selectedCategories.map((value) => (
                        <Chip
                          key={value}
                          label={categories.find((x) => x.id === value).title}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {categories.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={values.visibility}
                multiple
                onChange={({ target }) =>
                  setValues({ ...values, visibility: target.value })
                }
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((visibility) => (
                        <Chip
                          key={visibility}
                          label={getConvertMembersType(visibility)}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {state ? (
              <Grid container item xs={12} justifyContent="space-between">
                <Button onClick={handleDeleteArticle}>Apagar</Button>
                <Button onClick={handleUpdateArticle}>Atualizar</Button>
              </Grid>
            ) : (
              <Button onClick={handleCreateArticle}>Criar Artigo</Button>
            )}
          </Grid>
        </Grid>
      )}
    </AuthorizedLayout>
  );
};
