/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loading';
import noAvatar from '../../../../images/noAvatar.png';
import { AuthorizedLayout } from '../../../../layouts/AuthorizedLayout';
import api from '../../../../services/api';

const style = {
  padding: '20px',
  maxWidth: 900,
  margin: '10px auto',
};

export const NewCategory = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    title: '',
    image: '',
    author: '',
  });
  const [selected, setSelected] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();

  const handleChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0]?.name;
    const extension = e.target.files[0]?.type;

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this, filename, extension);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = (filename, extension, readerEvt) => {
    let bynaryString = readerEvt.target.result;
    let auxValues = values;
    auxValues.image = `data:${extension};base64,${btoa(bynaryString)}`;
    setValues({ ...auxValues });
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const handleCreateCategory = (e) => {
    if (values.title !== '' && values.author !== '' && selected.length !== 0) {
      createCategory();
    } else {
      toast.error('É obrigatório preencher todos os campos!');
    }
  };

  const handleUpdateCategory = (e) => {
    if (values.title !== '' && values.author !== '' && selected.length !== 0) {
      updateCategory(state.id);
    } else {
      toast.error('É obrigatório preencher todos os campos!');
    }
  };

  const handleDeleteCategory = (e) => {
    if (state) {
      deleteCategory(state.id);
    }
  };

  const getTags = async () => {
    try {
      const { data } = await api.get(`/tags`);

      if (data.data) {
        const parseData = data.data.map((p) => ({
          ...p.attributes,
          id: parseInt(p.id),
        }));
        setTags(parseData);
      } else {
        throw new Error('Erro ao carregar tags!');
      }
    } catch (error) {
      toast.error('Erro ao carregar tags!');
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      const { data } = await api.delete(`/categories/${categoryId}`);

      if (data.data) {
        navigate('/contents', { state: { to: 'categorias' } });
      } else {
        throw new Error('Erro ao apagar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao apagar categoria!');
    }
  };

  const updateCategory = async (categoryId) => {
    let response = {};

    if (!isValidHttpUrl(values.image)) {
      response = {
        data: {
          attributes: {
            title: values.title,
            author: values.author,
            image: values.image ? { data: values.image } : null,
            tagIds: selected,
          },
        },
      };
    } else {
      response = {
        data: {
          attributes: {
            title: values.title,
            author: values.author,
            tagIds: selected,
          },
        },
      };
    }

    try {
      const { data } = await api.put(`/categories/${categoryId}`, response);

      if (data.data) {
        navigate('/contents', { state: { to: 'categorias' } });
      } else {
        throw new Error('Erro ao atualizar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar categoria!');
    }
  };

  const getCategory = async (categoryId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/categories/${categoryId}`);

      if (data.data) {
        setValues({
          title: data.data.attributes.title,
          image: data.data.attributes.image || null,
          author: data.data.attributes.author,
        });
        setSelected(data.data.attributes.tagIds);
        setLoading(false);
      } else {
        throw new Error('Erro ao carregar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao carregar categoria!');
      setLoading(false);
    }
  };

  const createCategory = async () => {
    try {
      const { data } = await api.post(`/categories`, {
        data: {
          attributes: {
            title: values.title,
            author: values.author,
            image: values.image ? { data: values.image } : null,
            tagIds: selected,
          },
        },
      });

      if (data.data) {
        navigate('/contents', { state: { to: 'categorias' } });
      } else {
        throw new Error('Não foi possível criar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao criar categoria!');
    }
  };

  useEffect(() => {
    getTags();
    if (state) {
      getCategory(state.id);
    }
    setLoading(false);
  }, []);

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} style={style}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">Categoria</Typography>
          </Grid>
          <Grid item xs={12} direction="column" align="center">
            <label>
              <img
                src={values.image || noAvatar}
                alt=""
                height="250"
                width="250"
              />
              <input
                hidden
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg, .pdf"
                multiple
                onChange={handleChangeImage}
              />
            </label>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="title"
                variant="standard"
                label="Título"
                name="title"
                value={values.title}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="author"
                variant="standard"
                label="Autor"
                name="author"
                value={values.author}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Tags</InputLabel>
              <Select
                value={selected}
                onChange={selectionChangeHandler}
                multiple
                renderValue={(selected) => (
                  <Box>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={tags.find((x) => x.id === value).name}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {state ? (
              <Grid container item xs={12} justifyContent="space-between">
                <Button onClick={handleDeleteCategory}>Apagar</Button>
                <Button onClick={handleUpdateCategory}>Atualizar</Button>
              </Grid>
            ) : (
              <Button onClick={handleCreateCategory}>Criar Categoria</Button>
            )}
          </Grid>
        </Grid>
      )}
    </AuthorizedLayout>
  );
};
