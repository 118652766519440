/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { Check, Close } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { Button, TableCell, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import getSortedDates from '../../utils/getSortedDates';

export const InvoicesVerification = () => {
  const [values, setValues] = useState({
    reason: '',
  });
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedProfessionalStatus, setselectedProfessionalStatus] =
    useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const getProfessionals = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/invoices', {
          signal: controller.signal,
        });
        const parsedData = data.data.map((p) => ({
          id: parseInt(p.id),
          clientName: p.attributes.client.fullName,
          professionalName: p.attributes.professional.fullName,
          ...p.attributes,
        }));
        setProfessionals(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar os profissionais. Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar os profissionais.');
      } finally {
        setLoading(false);
      }
    };

    getProfessionals();

    return () => controller.abort();
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const updateProfessionalStatusApproved = async (professionalId) => {
    try {
      setLoading(true);
      await api.put(`/invoices/${professionalId}`, {
        data: { attributes: { status: 'approved' } },
      });
      toast.success('Nota fiscal aprovada.');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao mudar status de nota fiscal! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de nota fiscal!');
    } finally {
      setLoading(false);
    }
  };

  const updateProfessionalStatusDisapproved = async (professionalId) => {
    try {
      await api.put(`/invoices/${professionalId}`, {
        data: {
          type: 'professionalInvoice',
          attributes: {
            status: 'disapproved',
          },
        },
      });
      toast.success('Nota fiscal reprovada.');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao mudar status de profissional! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de profissional!');
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastName',
      label: 'Nome',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'professionalName',
      label: 'Nome do profissional',
      options: {
        filter: false,
        customBodyRender: (value, { rowData }) => <div>{`${value}`}</div>,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientName',
      label: 'Nome do cliente',
      options: {
        filter: false,
        customBodyRender: (value, { rowData }) => <div>{`${value}`}</div>,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'document',
      label: 'Documento',
      options: {
        filter: false,
        customBodyRender: (value, { rowData }) => (
          <div style={{ cursor: 'pointer' }}>
            <a href={value} target="_blank" rel="noreferrer">
              Documento
            </a>
          </div>
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[10]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label>{column.label}</label>
              <TextField
                sx={{ mb: 2, mt: 1 }}
                label="Data Inicial"
                type="date"
                variant="standard"
                value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                fullWidth
              />
              <TextField
                label="Data Final"
                type="date"
                variant="standard"
                value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                fullWidth
              />
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tStatus',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value, { rowData }) => (
          <div style={{ cursor: 'pointer' }}>{`${value}`}</div>
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() =>
                  updateProfessionalStatusApproved(rowData[0], 'approved')
                }
              >
                <Check />
              </Button>

              <Button
                onClick={() =>
                  updateProfessionalStatusDisapproved(rowData[0], 'disapproved')
                }
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Notas Fiscais pendentes"
          data={professionals}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedProfessional}
        handleClose={() => setSelectedProfessional(null)}
      >
        <div style={{ display: 'flex' }}>
          {selectedProfessional?.avatar === null ? (
            <SmallUserAvatar src={noAvatar} />
          ) : (
            <SmallUserAvatar src={selectedProfessional?.avatar} />
          )}

          <Typography variant="h4" sx={{ ml: 2 }}>
            {selectedProfessional?.firstName} {selectedProfessional?.lastName}
          </Typography>
        </div>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Formação acadêmica
        </Typography>
        {selectedProfessional?.academicBackground &&
          selectedProfessional?.academicBackground.map((ab) => (
            <Typography>* {ab}</Typography>
          ))}

        <Typography variant="h6" sx={{ mt: 2 }}>
          Biografia
        </Typography>
        <Typography sx={{ mt: 1 }}>{selectedProfessional?.bio}</Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Política de cancelamento
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {selectedProfessional?.cancelPolicy}
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Documento
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {selectedProfessional?.documentNumber}
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Telefone
        </Typography>
        <Typography sx={{ mt: 1 }}>{selectedProfessional?.phone}</Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Video de apresentação
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <a href={selectedProfessional?.presentationVideo}>
            {selectedProfessional?.presentationVideo}
          </a>
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Documento profissional
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {selectedProfessional?.professionalDocumentNumber}
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Preço da sessão
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {selectedProfessional?.sessionPrice}
        </Typography>
      </ModalContent>

      <ModalContent
        open={selectedProfessionalStatus}
        handleClose={() => setselectedProfessionalStatus(null)}
      >
        <Grid>
          <Grid item xs={12} direction="row" container>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Descreva os motivos para não aprovação do profissional.
            </Typography>
          </Grid>
          <Grid item xs={12} direction="row" justifyContent="center" container>
            <TextField
              value={values.reason}
              onChange={handleChange('reason')}
              fullWidth
              label="Descrição"
              multiline
              rows={6}
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              width: '100%',
            }}
            container
            direction="row"
            justifyContent="space-around"
          >
            <Button
              sx={{ m: 1 }}
              size="small"
              variant="text"
              onClick={() => {
                setValues({ reason: '' });
                setselectedProfessionalStatus(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => {
                updateProfessionalStatusDisapproved(
                  selectedProfessionalStatus.id,
                  values.reason
                );
                setValues({ reason: '' });
                setselectedProfessionalStatus(null);
              }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
