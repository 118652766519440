/* eslint-disable react-hooks/exhaustive-deps */
import { Close, Edit, Group, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  default as AddressForm,
  default as ClinicDataForm,
} from '../../components/Forms/AddressForm';
import BasicDataForm from '../../components/Forms/BasicDataForm';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import ClinicProfile from '../../components/ProfessionalProfile';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { states } from '../../utils/filterOptions';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';
import getSortedDates from '../../utils/getSortedDates';
import getSortedPlans from '../../utils/getSortedPlans';
import getSortedStates from '../../utils/getSortedStates';
import validateCPF from '../../utils/validateCPF';
import { serializeInfoRequired } from '../../utils/validateInfoRequired';

export const ClinicsList = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clinic, setClinic] = useState(null);
  const [clinicProfile, setClinicProfile] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedClinicStatus, setSelectedClinicStatus] = useState(null);
  const [missingData, setMissingData] = useState(null);
  const navigate = useNavigate();

  const schema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'Telefone inválido',
      test(value) {
        const maskDefaultValue = '_';
        const str = value;
        const result = str?.search(maskDefaultValue);
        return result > -1 ? false : true;
      },
    }),
    documentNumber: Yup.string()
      .max(15, 'CPF deve conter no máximo 15 caracteres')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '');
        },
      }),
    birthday: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Data de nascimento inválida',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    ageGroups: Yup.array(),
    presentationVideo: Yup.string(),
    clinicDocumentNumber: Yup.string(),
    academicBackground: Yup.array().min(1, 'Apresente ao menos uma formação'),
    specialities: Yup.array(),
    reasons: Yup.array(),
    approaches: Yup.array(),
    bio: Yup.string(),
    sessionPrice: Yup.number('Valor deve ser numérico').min(
      1,
      'Valor não pode ser inferior a 1'
    ),
    minutes: Yup.number(),
    hours: Yup.number(),
  });

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthday: '',
      documentNumber: '',
      addressId: null,
      cep: '',
      street: '',
      streetNumber: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      clinicDocumentNumber: '',
      profession: '',
      specialities: [],
      approaches: [],
      reasons: [],
      bio: '',
      presentationVideo: '',
      ageGroups: [],
      yearsOfExperience: 0,
      sessionPrice: 0,
    },
    validationSchema: schema,
  });

  useEffect(() => {
    const controller = new AbortController();
    const getClinics = async () => {
      setLoading(true);
      try {
        const { data } = await api.get('/clinics', {
          signal: controller.signal,
        });

        const parsedData = data.data.map((p) => ({
          id: parseInt(p.id),
          ...p.attributes,
          tStatus: p.attributes.subscription.tStatus === 'Trial',
        }));

        setClinics(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar profissionais! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar profissionais');
      } finally {
        setLoading(false);
      }
    };

    getClinics();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    form.setValues({
      ...clinic,
      cep: clinic?.address?.cep,
      street: clinic?.address?.street,
      neighborhood: clinic?.address?.neighborhood,
      streetNumber: clinic?.address?.street_number,
      complement: clinic?.address?.complement,
      country: clinic?.address?.country,
      state: clinic?.address?.state,
      city: clinic?.address?.city,
    });
  }, [clinic]);

  const getClinic = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/clinics/${id}}`);

      const parsedData = {
        id: parseInt(id, 10),
        ...data?.data?.attributes,
        addressId: data?.data?.attributes?.address?.id,
      };

      setClinic(parsedData);
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao carregar profissional! Erro status - ${error.response?.status}`
        );
      else toast.error('Erro ao carregar profissional');
    } finally {
      setLoading(false);
    }
  };

  const updateClinicStatus = async (clinicId, status) => {
    try {
      setLoading(true);
      await api.put(`/clinics/${clinicId}`, {
        data: { attributes: { status: status } },
      });
      setClinics(clinics.filter((p) => p.id !== clinicId));
      toast.success('Profissional retornou ao status de pendente.');
    } catch (error) {
      if (error.response?.data?.errors[0].title)
        toast.error(
          `Erro ao mudar status de profissional. Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de profissional.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      setIsEdit(false);
      setLoading(true);
      const request = {
        data: {
          attributes: {
            firstName: form?.values?.firstName,
            lastName: form?.values?.lastName,
            documentNumber: form?.values?.documentNumber,
            birthday: form?.values?.birthday,
            phone: form?.values?.phone,
            addressAttributes: {
              id: form?.values?.addressId,
              cep: form?.values?.cep,
              street: form?.values?.street,
              street_number: form?.values?.streetNumber,
              neighborhood: form?.values?.neighborhood,
              country: form?.values?.country,
              city: form?.values?.city,
              state: form?.values?.state,
              complement: form?.values?.complement,
            },
            clinicDocumentNumber: form?.values?.clinicDocumentNumber,
            profession: form?.values?.profession,
            specialities: form?.values?.specialities,
            approaches: form?.values?.approaches,
            reasons: form?.values?.reasons,
            bio: form?.values?.bio,
            presentationVideo: form?.values?.presentationVideo,
            ageGroups: form?.values?.ageGroups,
            yearsOfExperience: form?.values?.yearsOfExperience,
            sessionPrice: form?.values?.sessionPrice,
            termId: 1,
          },
        },
      };
      if (!request.data.attributes.addressAttributes.id)
        delete request.data.attributes.addressAttributes.id;
      const { data } = await api.put(`/clinics/${clinic?.id}`, request);

      const parsedData = {
        id: parseInt(data?.data?.id),
        ...data?.data?.attributes,
        fullName: `${data?.data?.attributes.firstName} ${data?.data?.attributes.lastName}`,
      };

      const clinicIndex = clinics.findIndex((p) => p.id === clinic.id);

      const newClinics = [...clinics];
      newClinics[clinicIndex] = parsedData;
      setClinics(newClinics);

      toast.success('Profissional atualizado com sucesso!');
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao editar profissional! Erro status - ${error.response?.status}`
        );
      else toast.error('Erro ao atualizar profissional');
    } finally {
      setLoading(false);
      setClinic(null);
    }
  };

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        filter: false,
        searchable: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <SmallUserAvatar
            src={value ? value : noAvatar}
            icon={
              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: -12,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setSelectedClinic(clinics.find((p) => p.id === rowData[1]));
                }}
              >
                <Visibility />
              </IconButton>
            }
          />
        ),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        searchable: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'username',
      label: 'Nome de usuário',
      sortable: false,
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'subscription',
      label: 'Plano',
      options: {
        display: false,
        filterType: 'multiselect',
        filter: true,
        sortCompare: (order) => (plan1, plan2) =>
          getSortedPlans(plan1, plan2, order),
        customBodyRender: (value) => getConvertPlanPtBr(value.pagarmePlan),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionals',
      label: 'Profissionais',
      sortable: false,
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() =>
                navigate('/clinics-professionals', {
                  state: { clinicId: rowData[1] },
                })
              }
            >
              <Group />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'patients',
      label: 'Pacientes',
      sortable: false,
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() =>
                navigate('/clinics-patients', {
                  state: { id: rowData[1], clinicName: rowData[2] },
                })
              }
            >
              <Group />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[14]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tStatus',
      label: 'Perido de Teste',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <Chip
                label="Em teste"
                style={{
                  backgroundColor: 'green',
                  color: '#FFFFFF',
                  height: 17,
                }}
              />
            );
          }
          return (
            <Chip
              label="Finalizado"
              style={{
                backgroundColor: 'red',
                color: '#FFFFFF',
                height: 17,
              }}
            />
          );
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      sortable: false,
      options: {
        filterOptions: states,
        filterType: 'multiselect',
        searchable: false,
        customBodyRender: (value) =>
          value.state ? value.state : 'Não informado',
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <Chip
                label="Inativo"
                style={{
                  backgroundColor: 'red',
                  color: '#FFFFFF',
                  height: 17,
                }}
              />
            );
          }
          return (
            <Chip
              label="Ativo"
              style={{
                backgroundColor: 'green',
                color: '#FFFFFF',
                height: 17,
              }}
            />
          );
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        display: false,
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={async () => {
                  await getClinic(rowData[1]);
                  setIsEdit(true);
                }}
              >
                <Edit />
              </Button>
              <Button
                onClick={() =>
                  setSelectedClinicStatus(
                    clinics.find((p) => p.id === rowData[1])
                  )
                }
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable title="Clinicas" data={clinics} columns={columns} />
      )}

      <ModalContent
        open={selectedClinic !== null}
        handleClose={() => setSelectedClinic(null)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {selectedClinic?.avatar === null ? (
                <SmallUserAvatar src={noAvatar} />
              ) : (
                <SmallUserAvatar src={selectedClinic?.avatar} />
              )}

              <Typography variant="h4" sx={{ ml: 2 }}>
                {selectedClinic?.name}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Banner
            </Typography>
            {selectedClinic?.banner ? (
              <img
                src={selectedClinic?.banner}
                alt="Banner"
                style={{ width: '100%', height: '150px' }}
              />
            ) : (
              <Typography sx={{ mt: 2, mb: 1 }}>Sem banner</Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              CNPJ/CPF
            </Typography>
            <Typography>{selectedClinic?.documentNumber}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Descrição Clinica
            </Typography>
            <Typography>{selectedClinic?.description}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Foto Responsável Técnico
            </Typography>
            {selectedClinic?.responsibleAvatar ? (
              <img
                src={selectedClinic?.responsibleAvatar}
                alt="Avatar"
                style={{ width: '100%', height: '150px' }}
              />
            ) : (
              <Typography sx={{ mt: 2, mb: 1 }}>Sem foto</Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Nome Responsável Técnico
            </Typography>
            <Typography>{selectedClinic?.responsibleName}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              CRP Responsável Técnico
            </Typography>
            <Typography>{selectedClinic?.responsibleDocumentNumber}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Descrição Responsável Técnico
            </Typography>
            <Typography>{selectedClinic?.responsibleAbout}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Endereço
            </Typography>
            <Typography>
              {selectedClinic?.address?.street},{' '}
              {selectedClinic?.address?.street_number},{' '}
              {selectedClinic?.address?.neighborhood}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Estado
            </Typography>
            <Typography>{selectedClinic?.address?.state}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Cidade
            </Typography>
            <Typography>{selectedClinic?.address?.city}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Telefone
            </Typography>
            <Typography>{selectedClinic?.phone}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Conta Corrente
            </Typography>
            <Typography>
              AG:{selectedClinic?.paymentAccount?.bankAg}-
              {selectedClinic?.paymentAccount?.agenciaDv} CC:
              {selectedClinic?.paymentAccount?.bankCc}-
              {selectedClinic?.paymentAccount?.contaDv}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Tipos de Pagamento
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Modelos de Agendamento
            </Typography>
          </Grid>
        </Grid>
      </ModalContent>

      <ModalContent
        open={isEdit && clinic !== null}
        handleClose={() => {
          setIsEdit(false);
          setClinic(null);
        }}
        renderButtons={<Button onClick={handleEdit}>Salvar</Button>}
      >
        <BasicDataForm form={form} />
        <AddressForm sx={{ mt: 5 }} form={form} />
        <ClinicDataForm form={form} clinic={clinic} sx={{ mt: 5 }} />
      </ModalContent>

      <ModalContent
        open={clinicProfile !== null}
        handleClose={() => setClinicProfile(null)}
      >
        <ClinicProfile clinic={clinicProfile} />
      </ModalContent>

      <ModalContent
        open={missingData !== null}
        handleClose={() => setMissingData(null)}
      >
        <Grid container direction="column">
          <Typography variant="h5" sx={{ m: 1, mb: 2 }}>
            Informações incompletas do profissional
          </Typography>
          {Object.keys(missingData || {}).map((key) => (
            <Grid container direction="row" sx={{ m: 1 }} key={key}>
              <Typography>{serializeInfoRequired(key)}:</Typography>
              <Typography sx={{ ml: 1, color: 'red' }}>Incompleto</Typography>
            </Grid>
          ))}
        </Grid>
      </ModalContent>

      <ModalAlert
        open={selectedClinicStatus !== null}
        handleClose={() => setSelectedClinicStatus(null)}
        handleSubmit={() => {
          updateClinicStatus(selectedClinicStatus?.id, 'pending');
        }}
      >
        <Typography variant="h5">
          Deseja alterar o status para pendente deste profissional?
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
