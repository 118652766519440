import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthProvider } from './providers/AuthProvider';
import Routes from './routes';
import { styleGlobal, theme } from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={styleGlobal} />
      <CssBaseline enableColorScheme />
      <ToastContainer />
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
