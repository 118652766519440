/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AddressForm from '../../components/Forms/AddressForm';
import BasicDataForm from '../../components/Forms/BasicDataForm';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import SelectDropdownWithSearch from '../../components/SelectDropdownWithSearch';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { ages, states } from '../../utils/filterOptions';
import getSortedDates from '../../utils/getSortedDates';
import getSortedStates from '../../utils/getSortedStates';

function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export const Clients = () => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolType, setSchoolType] = useState('student');
  const [loading, setLoading] = useState(true);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [modalSchool, setModalSchool] = useState(false);
  const [schools, setSchools] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const schema = Yup.object({
    avatar: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    documentNumber: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '') || null;
        },
      }),
    phone: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Telefone inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    birthday: Yup.string('Formato de data inválido')
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Data de nascimento inválida',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    needLegalGuardian: Yup.boolean().nullable(),

    // responsavel
    legalGuardianFullName: Yup.string().nullable(),
    legalGuardianEmail: Yup.string().nullable(),
    legalGuardianDocumentNumber: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '') || null;
        },
      }),
    legalGuardianPhone: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Telefone inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),

    // endereço
    cep: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CEP inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    street: Yup.string().nullable(),
    streetNumber: Yup.string().nullable(),
    neighborhood: Yup.string().nullable(),
    country: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
  });

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      documentNumber: '',
      birthday: '',
      phone: '',
      legalGuardianFullName: '',
      legalGuardianEmail: '',
      legalGuardianDocumentNumber: '',
      legalGuardianPhone: '',
      cep: '',
      addressId: null,
      street: '',
      streetNumber: '',
      neighborhood: '',
      country: '',
      city: '',
      state: '',
      complement: '',
    },
    validationSchema: schema,
    validateOnChange: true,
  });

  useEffect(() => {
    const controller = new AbortController();
    const getSchools = async () => {
      try {
        setLoadingSchools(true);
        const { data } = await api.get('/schools', {
          signal: controller.signal,
        });
        const parsedData = data.data.map((s) => ({
          id: parseInt(s.id),
          ...s.attributes,
        }));
        setSchools(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao buscar as escolas! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao buscar as escolas!');
      } finally {
        setLoadingSchools(false);
      }
    };

    getSchools();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const getClients = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/clients', {
          signal: controller.signal,
        });

        const parsedData = data.data.map((c) => ({
          id: parseInt(c.id),
          ...c.attributes,
          fullName: `${c.attributes.firstName} ${c.attributes.lastName}`,
          location: `${c.attributes.address.street}, ${
            c.attributes.address.street_number
          }${
            c.attributes.address.complement !== 'N/A'
              ? c.attributes.address.complement
              : ''
          }, ${c.attributes.address.neighborhood}, ${
            c.attributes.address.city
          }, ${c.attributes.address.state}, ${c.attributes.address.country}, ${
            c.attributes.address.cep
          }`,
          age: getAgeRange(c.attributes.birthday, 'years'),
          state: c.attributes.address.state,
          school: c.attributes.school ? 'Conveniado' : 'Não conveniado',
        }));
        setClients(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar clientes! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar clientes');
      } finally {
        setLoading(false);
      }
    };

    getClients();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    form.setValues({
      ...client,
      addressId: client?.address?.id,
      birthday: moment(client?.birthday).format('DD/MM/YYYY'),
      adressId: client?.address?.id,
      cep: client?.address?.cep,
      street: client?.address?.street,
      streetNumber: client?.address?.street_number,
      neighborhood: client?.address?.neighborhood,
      country: client?.address?.country,
      city: client?.address?.city,
      state: client?.address?.state,
      complement: client?.address?.complement,
    });
  }, [client]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setModalSchool(false);
      setSelectedClient(null);
      const request = {
        data: {
          attributes: {
            schoolId: selectedSchool?.id,
            schoolType,
          },
        },
      };

      const { data } = await api.patch(
        `/clients/${selectedClient?.id}/add_school`,
        request
      );
      const parsedData = {
        id: parseInt(data?.data?.id),
        ...data.data.attributes,
      };

      const clientIndex = clients.findIndex((c) => c.id === parsedData.id);
      const newClients = [...clients];
      newClients[clientIndex] = parsedData;
      setClients(newClients);
      toast.success('Convenio adicionado com sucesso!');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao adicionar o convenio! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao adicionar o convenio!');
    } finally {
      setLoading(false);
      setSelectedSchool(null);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/clients/${id}`);
      setClients(clients.filter((client) => client.id !== id));
      toast.success('Cliente deletado com sucesso!');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao deletar o cliente! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao deletar o cliente!');
    }
  };

  const getClient = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/clients/${id}`);
      const parsedData = {
        id: parseInt(id, 10),
        ...data?.data?.attributes,
      };

      setClient(parsedData);
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao carregar cliente! Erro status - ${error.response?.status}`
        );
      else toast.error('Erro ao carregar cliente');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      setIsEdit(false);
      setLoading(true);
      const request = {
        data: {
          attributes: {
            firstName: form?.values?.firstName,
            lastName: form?.values?.lastName,
            documentNumber: form?.values?.documentNumber,
            birthday: form?.values?.birthday,
            phone: form?.values?.phone,
            legalGuardianFullName: form?.values?.legalGuardianFullName,
            legalGuardianEmail: form?.values?.legalGuardianEmail,
            legalGuardianDocumentNumber:
              form?.values?.legalGuardianDocumentNumber,
            legalGuardianPhone: form?.values?.legalGuardianPhone,
            addressAttributes: {
              id: form?.values?.addressId,
              cep: form?.values?.cep,
              street: form?.values?.street,
              street_number: form?.values?.streetNumber,
              neighborhood: form?.values?.neighborhood,
              country: form?.values?.country,
              city: form?.values?.city,
              state: form?.values?.state,
              complement: form?.values?.complement,
            },
          },
        },
      };
      if (!form?.values?.addressId)
        delete request.data.attributes.addressAttributes.id;

      const { data } = await api.put(`/clients/${client.id}`, request);
      const parsedData = {
        id: parseInt(data.data.id),
        ...data.data.attributes,
        fullName: `${data?.data?.attributes.firstName} ${data?.data?.attributes.lastName}`,
        location: `${data?.data?.attributes.address.street}, ${
          data?.data?.attributes.address.street_number
        }${
          data?.data?.attributes.address.complement !== 'N/A'
            ? data?.data?.attributes.address.complement
            : ''
        }, ${data?.data?.attributes.address.neighborhood}, ${
          data?.data?.attributes.address.city
        }, ${data?.data?.attributes.address.state}, ${
          data?.data?.attributes.address.country
        }, ${data?.data?.attributes.address.cep}`,
        age: getAgeRange(data?.data?.attributes.birthday, 'years'),
        state: data?.data?.attributes.address.state,
        school: data?.data?.attributes.school ? 'Conveniado' : 'Não conveniado',
      };

      const clientIndex = clients.findIndex((c) => c.id === parsedData.id);
      const newClients = [...clients];
      newClients[clientIndex] = parsedData;

      setClients(newClients);
      toast.success('Cliente atualizado com sucesso!');
    } catch (error) {
      if (error.response?.status)
        toast.error(
          `Erro ao atualizar cliente! Erro status - ${error.response?.status}`
        );
      toast.error('Erro ao atualizar cliente');
    } finally {
      setLoading(false);
    }
  };

  const getAgeRange = (birthday) => {
    const age = moment().diff(birthday, 'years');
    if (age < 5) {
      return '-5';
    } else if (age < 14) {
      return '5 - 13';
    } else if (age < 19) {
      return '14 - 18';
    } else if (age < 26) {
      return '19 - 25';
    } else {
      return '25+';
    }
  };

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        filter: false,
        searchable: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <SmallUserAvatar
            src={value ? value : noAvatar}
            icon={
              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: -12,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setSelectedClient(
                    clients.filter((p) => p.id === rowData[1])[0]
                  );
                }}
              >
                <VisibilityIcon />
              </IconButton>
            }
          />
        ),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastName',
      label: 'Sobrenome',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'firstName',
      label: 'Nome',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'fullName',
      label: 'Cliente',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professional',
      label: 'Profissional',
      options: {
        filterType: 'textField',
        display: false,
        customBodyRender: (value) =>
          value?.map((p) => (
            <Box key={p.id}>
              <Typography variant="body2">{`${p.first_name} ${p.last_name}`}</Typography>
            </Box>
          )),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'school',
      label: 'Conveniado',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'schedulesCount',
      label: 'Agendamentos',
      options: {
        filterType: 'textField',
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'reviewsCount',
      label: 'Avaliações',
      options: {
        filterType: 'textField',
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'location',
      label: 'Endereço',
      options: {
        display: 'excluded',
        filterType: 'textField',
      },
    },
    {
      name: 'age',
      label: 'Idade',
      options: {
        display: 'excluded',
        filterType: 'multiselect',
        searchable: false,
        filterOptions: ages,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[13]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      options: {
        filterType: 'multiselect',
        searchable: false,
        filterOptions: states,
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        customBodyRender: (value) =>
          value.state ? value.state : 'Não informado',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Chip
            label={value ? 'Inativo' : 'Ativo'}
            style={{
              backgroundColor: value ? 'red' : 'green',
              color: '#FFFFFF',
              height: 17,
            }}
          />
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onClick={async () => {
                await getClient(rowData[1]);
                setIsEdit(true);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                setModalAlert(true);
                setClient({ id: rowData[1] });
              }}
            >
              <Close color="error" />
            </Button>
          </Box>
        ),
        setCellHeaderProps: () => ({ align: 'left' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable title="Clientes" data={clients} columns={columns} />
      )}

      <ModalContent
        open={selectedClient}
        handleClose={() => setSelectedClient(null)}
      >
        <Grid>
          <Grid sx={{ mb: 2 }} container row>
            {selectedClient?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedClient?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedClient?.firstName} {selectedClient?.lastName}
            </Typography>
          </Grid>

          <Button
            onClick={() => {
              setModalSchool(true);
            }}
          >
            Adicionar Convenio <AddIcon />
          </Button>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Data de Nascimento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {new Date(selectedClient?.birthday).toLocaleDateString('pt-br') ||
              'Não informado'}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Email
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedClient?.email}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedClient?.phone}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedClient?.documentNumber}
          </Typography>

          {/* Legal Guardian */}
          {selectedClient?.legalGuardianFullName ? (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Nome do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianFullName}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Email do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianEmail}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Telefone do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianPhone}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Documento do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianDocumentNumber}
              </Typography>
            </>
          ) : null}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Endereço
          </Typography>
          {selectedClient?.address !== 'Não informado' ? (
            <Typography sx={{ mt: 1 }}>{`${
              selectedClient?.address?.street
                ? selectedClient?.address?.street + ', '
                : ''
            }${
              selectedClient?.address?.street_number
                ? selectedClient?.address?.complement &&
                  selectedClient?.address?.complement !== 'N/A'
                  ? selectedClient?.address?.street_number + ' '
                  : selectedClient?.address?.street_number + ', '
                : ''
            }${
              selectedClient?.address?.complement &&
              selectedClient?.address?.complement !== 'N/A'
                ? selectedClient?.address?.complement + ', '
                : ''
            }${
              selectedClient?.address?.neighborhood
                ? selectedClient?.address?.neighborhood + ', '
                : ''
            }${
              selectedClient?.address?.city
                ? selectedClient?.address?.city + ', '
                : ''
            }${
              selectedClient?.address?.state
                ? selectedClient?.address?.state + ', '
                : ''
            }${
              selectedClient?.address?.country
                ? selectedClient?.address?.country + ', '
                : ''
            }${
              selectedClient?.address?.cep
                ? selectedClient?.address?.cep + '.'
                : ''
            }`}</Typography>
          ) : (
            <Typography sx={{ mt: 1 }}>{selectedClient?.address}</Typography>
          )}
          <Typography variant="h6" sx={{ mt: 2 }}>
            Conta criada em:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {new Date(selectedClient?.createdAt).toLocaleDateString('pt-br') ||
              'Não foi possível recuperar'}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={isEdit && client !== null}
        handleClose={() => {
          setIsEdit(false);
          setClient(null);
        }}
        renderButtons={<Button onClick={handleEdit}>Salvar</Button>}
      >
        <BasicDataForm form={form} modal />
        <AddressForm sx={{ mt: 5 }} form={form} modal />
      </ModalContent>

      <ModalContent
        open={modalSchool}
        handleClose={() => setModalSchool(false)}
      >
        {loadingSchools ? (
          <Loading />
        ) : (
          <Grid
            justifyContent="center"
            container
            columns={1}
            sx={{ width: '100%' }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              Escolas
            </Typography>
            <SelectDropdownWithSearch
              label="Escola"
              value={selectedSchool}
              handleChange={setSelectedSchool}
              options={schools}
            />
            <FormControl fullWidth>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Tipo do cliente
              </FormLabel>
              <RadioGroup
                sx={{ m: 2 }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={schoolType}
                onChange={({ target }) => setSchoolType(target.value)}
              >
                <FormControlLabel
                  value="student"
                  control={<Radio />}
                  label="Estudante"
                />
                <FormControlLabel
                  sx={{ ml: 5 }}
                  value="colaborator"
                  control={<Radio />}
                  label="Colaborador"
                />
              </RadioGroup>
            </FormControl>
            <Grid container row justifyContent="space-between">
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setModalSchool(false);
                  setSelectedSchool(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                disabled={!selectedSchool}
              >
                Definir Convenio
              </Button>
            </Grid>
          </Grid>
        )}
      </ModalContent>

      <ModalAlert
        handleClose={() => setModalAlert(false)}
        handleSubmit={() => handleDelete(client.id)}
        open={modalAlert}
      >
        <Typography variant="h5">
          Deseja realmente excluir este cliente?
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
