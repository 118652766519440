import { InputDate, InputMask, InputText } from './FormikComponents/inputs';
import { SelectSingle, SelectMultiple } from './FormikComponents/selects';

export default function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <InputText {...rest} />;
    case 'inputMask':
      return <InputMask {...rest} />;
    case 'date':
      return <InputDate {...rest} />;
    case 'select':
      return <SelectSingle {...rest} />;
    case 'selectMultiple':
      return <SelectMultiple {...rest} />;
    default:
      return null;
  }
}
