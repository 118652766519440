import { Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { Container, Grow, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from 'react';

export default function SelectDropdownWithSearch({ ...props }) {
  const { label, value, handleChange, options } = props;
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [dropDown, setDropDown] = useState(false);

  const handleSearch = (search) => {
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (dropDown) inputRef.current.focus();
  }, [dropDown]);

  return (
    <FormControl fullWidth>
      <TextField
        fullWidth
        id="standard-basic"
        inputRef={inputRef}
        label={!dropDown ? '' : value?.name ? label : <SearchIcon />}
        onChange={({ target }) => {
          if (value) handleChange(null);
          handleSearch(target.value);
          setSearch(target.value);
        }}
        size="small"
        value={value?.name || search}
        disabled={!dropDown}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setDropDown(!dropDown)}
        fullWidth
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <Typography variant="p" sx={{ mr: 1 }}>
          Selecione uma escola
        </Typography>
        {!dropDown ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </Button>
      <Grow
        in={dropDown}
        style={{ height: '40vh', transformOrigin: '0 0 0', overflowY: 'auto' }}
        {...(dropDown ? { timeout: 1000 } : {})}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {filteredOptions.map((option) => (
            <MenuItem
              sx={{ width: '100%' }}
              style={{
                justifyContent: 'flex-start',
                color: value === option.name ? 'white' : 'black',
                backgroundColor: value === option.name ? 'blue' : 'transparent',
                padding: '0.3rem 0.5rem',
              }}
              key={option.id}
              value={option.name}
              onClick={() => {
                handleChange(option);
                inputRef.current.focus();
                setDropDown(false);
              }}
              selected={option.name === value}
            >
              {option.name}
            </MenuItem>
          ))}
        </Container>
      </Grow>
    </FormControl>
  );
}
