export default function getConvertPlanPtBr(plan) {
  switch (plan) {
    case 'free':
      return 'Gratuito';
    case 'gold':
      return 'Ouro';
    case 'platinum':
      return 'Platina';
    case 'founder_gold':
      return 'Ouro Fundador';
    case 'founder_platinum':
      return 'Platina Fundador';
    default:
      return plan;
  }
}

export function getConvertMembersType(type) {
  switch (type) {
    case 'all':
      return 'Todos';
    case 'all-users':
      return 'Não Conveniados';
    case 'all-members':
      return 'Conveniados';
    case 'all-clients':
      return 'Clientes';
    case 'all-professionals':
      return 'Profissionais';
    case 'all-platinum':
      return 'Profissionais Platina';
    case 'all-free':
      return 'Profissionais Gratuitos';
    case 'members-professor':
      return 'Professores Conveniados';
    default:
      return type;
  }
}
