import Paper from '@mui/material/Paper';
import { styled as StyledMUI } from '@mui/material/styles';
import styled from 'styled-components';

export const TextError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  text-align: start;
`;

export const Item = StyledMUI(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  color: theme.palette.text.secondary,
  padding: theme.spacing(0),
  textAlign: 'center',
}));

export const ItemProfile = StyledMUI(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  alignItems: 'flex-start',
  boxShadow: 'none',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(0),
  textAlign: 'flex-start',
}));
