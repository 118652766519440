import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import useAuth from '../hooks/auth';
import { NewCategory } from '../pages/Contents/Categorys/AddCategory';
import { Clients } from '../pages/Clients';
import { Contents } from '../pages/Contents';
import { NewArticle } from '../pages/Contents/Articles/AddArticle';
import { NewCourse } from '../pages/Contents/Courses/AddCourse';
import ModulesOf from '../pages/Contents/Courses/Modules';
import { NewModule } from '../pages/Contents/Courses/Modules/AddModule';
import { NewLesson } from '../pages/Contents/Courses/Modules/Classes/AddClass';
import { ViewLesson } from '../pages/Contents/Courses/Modules/Classes/AddClass/viewClass';
import { NewVideo } from '../pages/Contents/Videos/AddVideo';
import { Coupons } from '../pages/Coupons';
import { Emails } from '../pages/Emails';
import { InvoicesVerification } from '../pages/InvoicesVerification';
import { Login } from '../pages/Login';
import { PatientsList } from '../pages/PatientsList';
import { ProfessionalsApproved } from '../pages/ProfessionalsApproved';
import { ProfessionalsDisapproved } from '../pages/ProfessionalsDisapproved';
import { ProfessionalsFinance } from '../pages/ProfessionalsFinance';
import { ProfessionalsPending } from '../pages/ProfessionalsPending';
import { Reports } from '../pages/Reports';
import { SchedulesCanceled } from '../pages/SchedulesCanceled';
import { ScheduleList } from '../pages/SchedulesList';
import { SchoolMembersList } from '../pages/SchoolMembersList';
import { SchoolsAffiliated } from '../pages/SchoolsAffiliated';
import { ClinicsList } from '../pages/ClinicsList';
import { ProfessionalsList } from '../pages/ProfessionalsList';

function PublicRoute({ children }) {
  let { user } = useAuth();

  if (user) {
    return <Navigate to="/professionals-pending" />;
  }

  return children;
}

function PrivateRoute({ children }) {
  let { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
}

export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/professionals-pending"
        element={
          <PrivateRoute>
            <ProfessionalsPending />
          </PrivateRoute>
        }
      />

      <Route
        path="/professionals-approved"
        element={
          <PrivateRoute>
            <ProfessionalsApproved />
          </PrivateRoute>
        }
      />

      <Route
        path="/professionals-disapproved"
        element={
          <PrivateRoute>
            <ProfessionalsDisapproved />
          </PrivateRoute>
        }
      />

      <Route
        path="/invoices-verification"
        element={
          <PrivateRoute>
            <InvoicesVerification />
          </PrivateRoute>
        }
      />

      <Route
        path="/contents"
        element={
          <PrivateRoute>
            <Contents />
          </PrivateRoute>
        }
      />

      <Route
        path="/articles-reports"
        element={
          <PrivateRoute>
            <Contents />
          </PrivateRoute>
        }
      />

      <Route
        path="/new-article"
        element={
          <PrivateRoute>
            <NewArticle />
          </PrivateRoute>
        }
      />

      <Route
        path="/courses/modules/:id"
        element={
          <PrivateRoute>
            <ModulesOf />
          </PrivateRoute>
        }
      />

      <Route
        path="/courses/modules/:id/new-lesson/:module_id"
        element={
          <PrivateRoute>
            <NewLesson />
          </PrivateRoute>
        }
      />

      <Route
        path="/courses/modules/:id/view-lesson/:lesson_id"
        element={
          <PrivateRoute>
            <ViewLesson />
          </PrivateRoute>
        }
      />

      <Route
        path="/courses/:id/new-module"
        element={
          <PrivateRoute>
            <NewModule />
          </PrivateRoute>
        }
      />

      <Route
        path="/new-video"
        element={
          <PrivateRoute>
            <NewVideo />
          </PrivateRoute>
        }
      />

      <Route
        path="/new-course"
        element={
          <PrivateRoute>
            <NewCourse />
          </PrivateRoute>
        }
      />

      <Route
        path="/category"
        element={
          <PrivateRoute>
            <NewCategory />
          </PrivateRoute>
        }
      />

      <Route
        path="/clients"
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />

      <Route
        path="/schedules"
        element={
          <PrivateRoute>
            <ScheduleList />
          </PrivateRoute>
        }
      />

      <Route
        path="/cancelled-schedules"
        element={
          <PrivateRoute>
            <SchedulesCanceled />
          </PrivateRoute>
        }
      />

      <Route
        path="/profissionals-patients"
        element={
          <PrivateRoute>
            <PatientsList />
          </PrivateRoute>
        }
      />

      <Route
        path="/profissionals-finances"
        element={
          <PrivateRoute>
            <ProfessionalsFinance />
          </PrivateRoute>
        }
      />

      <Route
        path="/coupons"
        element={
          <PrivateRoute>
            <Coupons />
          </PrivateRoute>
        }
      />

      <Route
        path="/e-mails"
        element={
          <PrivateRoute>
            <Emails />
          </PrivateRoute>
        }
      />

      <Route
        path="/affiliated_schools"
        element={
          <PrivateRoute>
            <SchoolsAffiliated />
          </PrivateRoute>
        }
      />

      <Route
        path="/school_members"
        element={
          <PrivateRoute>
            <SchoolMembersList />
          </PrivateRoute>
        }
      />

      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />

      <Route
        path="/clinics"
        element={
          <PrivateRoute>
            <ClinicsList />
          </PrivateRoute>
        }
      />

      <Route
        path="/clinics-professionals"
        element={
          <PrivateRoute>
            <ProfessionalsList />
          </PrivateRoute>
        }
      />

      <Route
        path="/clinics-patients"
        element={
          <PrivateRoute>
            <PatientsList />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<h1>404 Not found</h1>} />
    </Switch>
  );
}
