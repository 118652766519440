import moment from 'moment';

export default function getSortedDates(obj1, obj2, order) {
  if (order === 'asc')
    return moment(obj1.data, 'YYYY-MM-DD').isBefore(
      moment(obj2.data, 'YYYY-MM-DD')
    )
      ? 1
      : -1;
  return moment(obj2.data, 'YYYY-MM-DD').isBefore(
    moment(obj1.data, 'YYYY-MM-DD')
  )
    ? 1
    : -1;
}

export function getSortedDatesHours(obj1, obj2, order) {
  const date1 = moment(obj1.data, 'DD/MM/YYYY HH:mm');
  const date2 = moment(obj2.data, 'DD/MM/YYYY HH:mm');
  if (order === 'asc') return date1.isBefore(date2) ? 1 : -1;
  return date2.isBefore(date1) ? 1 : -1;
}
