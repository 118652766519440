import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextEditor } from '../../../../../../components/Input';
import Loading from '../../../../../../components/Loading';
import defaultImage from '../../../../../../images/defaultImage.jpg';
import { AuthorizedLayout } from '../../../../../../layouts/AuthorizedLayout';
import api from '../../../../../../services/api';

const style = {
  padding: '20px',
  width: 900,
  margin: '10px auto',
};

export const ViewLesson = () => {
  const { state: lesson } = useLocation();
  const navigate = useNavigate();
  const { module_id } = useParams();
  const [values, setValues] = useState({
    title: lesson.title,
    image: lesson.image,
    description: lesson.description,
    link: lesson.link,
    pdf: lesson.document,
  });
  const [loading, setLoading] = useState(true);
  const [editDescription, setEditDescription] = useState('');
  const [editPdf, setEditPdf] = useState('');

  useEffect(() => {
    setLoading(false);
  }, []);

  const storeFileAsBase64 = (file, field) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setValues({ ...values, [field]: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const checkFields = () => {
    if (values.pdf) {
      if (!values.pdf) {
        toast.error('Selecione um arquivo PDF!');
        return false;
      }
    } else {
      if (values.description === '') {
        toast.error('Preencha o campo de descrição!');
        return false;
      }
    }
    if (!values.title) {
      toast.error('Preencha o campo de título!');
      return false;
    }
    if (!values.image) {
      toast.error('Selecione uma imagem!');
      return false;
    }
    return true;
  };

  const updateLesson = async () => {
    try {
      if (!checkFields()) return;
      const request = {
        data: {
          attributes: {
            title: values.title,
            description: values.description,
            image: { data: values.image },
            course_module_id: module_id,
            video_link: values.link,
            document: values.pdf ? { data: values.pdf } : null,
          },
        },
      };
      if (values.image?.startsWith('http'))
        delete request.data.attributes.image;
      if (values.pdf?.startsWith('http'))
        delete request.data.attributes.document;
      const data = await api.put(`/lessons/${lesson.lessonId}`, request);
      if (data.data) {
        toast.success('Aula Editada com sucesso!');
        navigate(-1);
      }
    } catch (error) {
      toast.error('Erro ao Editar a aula!');
    } finally {
    }
  };

  const deleteLesson = () => {
    try {
      api.delete(`/lessons/${lesson.lessonId}`);
      toast.success('Aula excluída com sucesso!');
      navigate(-1);
    } catch (error) {
      toast.error('Erro ao excluir a aula!');
    }
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <form>
          <Grid container spacing={3} style={style}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">Aula</Typography>
            </Grid>
            <Grid item xs={12} direction="column" align="center">
              <label style={{ cursor: 'pointer' }}>
                <img
                  src={values.image || defaultImage}
                  alt=""
                  height="250"
                  width="250"
                />
                <input
                  hidden
                  type="file"
                  name="image"
                  id="image"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    storeFileAsBase64(file, 'image');
                  }}
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="title"
                variant="outlined"
                label="Título da Aula"
                value={values.title}
                onChange={(e) => {
                  setValues({ ...values, title: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              {values.pdf ? (
                <>
                  <FormControlLabel
                    label="Editar PDF"
                    control={
                      <Checkbox
                        checked={editPdf}
                        onChange={() => setEditPdf(!editPdf)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                  {editPdf ? (
                    <>
                      <input
                        id="contained-button-file"
                        type="file"
                        name="pdf"
                        accept=".pdf"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          storeFileAsBase64(file, 'pdf');
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '25px',
                          }}
                        >
                          {values.pdf.startsWith('http')
                            ? 'Selecionar PDF'
                            : 'PDF Selecionado'}
                        </Button>
                      </label>
                    </>
                  ) : (
                    <Link to={lesson.document} target="_blank">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{
                          marginTop: '25px',
                        }}
                      >
                        Visualizar PDF
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <FormControlLabel
                    label="Editar Descrição"
                    control={
                      <Checkbox
                        checked={editDescription}
                        onChange={() => setEditDescription(!editDescription)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                  {editDescription ? (
                    <TextEditor
                      value={values.description}
                      setEditor={(value) => {
                        setValues({ ...values, description: value });
                      }}
                    />
                  ) : (
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(JSON?.parse(lesson.description))
                      )}
                      readOnly={true}
                    />
                  )}
                </>
              )}

              <TextField
                id="link"
                variant="outlined"
                label="Link"
                value={values.link}
                onChange={(e) => {
                  setValues({ ...values, link: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: '25px' }}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button onClick={() => updateLesson()}>
                Atualizar Informações
              </Button>
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end">
              <Button style={{ color: 'red' }} onClick={() => deleteLesson()}>
                Excluir Aula
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </AuthorizedLayout>
  );
};
