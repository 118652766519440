import 'draft-js/dist/Draft.css';
import { DraftailEditor } from 'draftail';
import 'draftail/dist/draftail.css';
import styled from 'styled-components';

export const StyledPage = styled.div`
  height: 100%;
  width: 100%;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }
`;

export const StyledDraftailEditor = styled(DraftailEditor)`
  & .DraftEditor-editorContainer {
    opacity: 1 !important;
  }
`;
