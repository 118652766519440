/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Close } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Button,
  Grid,
  TableCell,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import getSortedDates from '../../utils/getSortedDates';

export function Reports() {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const getCoupons = async () => {
      setLoading(true);
      try {
        const { data } = await api.get('/reports', {
          signal: controller.signal,
        });
        setReports(data.data);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar relatórios! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar relatórios');
      } finally {
        setLoading(false);
      }
    };

    getCoupons();

    return () => controller.abort();
  }, []);

  const getParsedData = (data) => {
    return data.map((r) => ({
      id: parseInt(r.id),
      ...r.attributes,
      tTypeReport:
        r.typeReport === 'professional_not_attend' ? 'Não Compareceu' : 'Outro',
    }));
  };

  const getReport = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/reports/${id}`);
      setSelectedReport({ id: parseInt(id), ...data.data.attributes });
    } catch (error) {
      toast.error('Erro ao carregar relatório');
    } finally {
      setLoading(false);
    }
  };

  const getProfessional = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/professionals/${id}`);
      setSelectedProfessional({ id: parseInt(id), ...data.data.attributes });
    } catch (error) {
      toast.error('Erro ao carregar profissional');
    } finally {
      setLoading(false);
    }
  };

  const getClient = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/clients/${id}`);
      setSelectedClient({ id: parseInt(id), ...data.data.attributes });
    } catch (error) {
      toast.error('Erro ao carregar cliente');
    } finally {
      setLoading(false);
    }
  };

  const renderReports = getParsedData(reports);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => getReport(value)}
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'message',
      label: 'Menssagem',
      options: {
        filter: false,
        display: 'excluded',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tTypeReport',
      label: 'Tipo de Relatório',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'document',
      label: 'Documento',
      options: {
        filter: false,
        display: 'excluded',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Criação',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[4]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professional',
      label: 'Profissional',
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button onClick={() => getProfessional(rowData[5].id)}>
              <PersonIcon />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'client',
      label: 'Cliente',
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => (
          <div style={{ display: 'flex' }}>
            <Button onClick={() => getClient(rowData[6].id)}>
              <PersonIcon />
            </Button>
          </div>
        ),
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button onClick={() => {}}>
                <Close color="error" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Relatórios de reclamações"
          data={renderReports}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedProfessional}
        handleClose={() => setSelectedProfessional(null)}
      >
        <Grid>
          <div style={{ display: 'flex' }}>
            {selectedProfessional?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedProfessional?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedProfessional?.firstName} {selectedProfessional?.lastName}
            </Typography>
          </div>

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Formação acadêmica
          </Typography>
          {selectedProfessional?.academicBackground &&
            selectedProfessional?.academicBackground.map((ab) => (
              <Typography>* {ab}</Typography>
            ))}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Biografia
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.bio}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Política de cancelamento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.cancelPolicy}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.documentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.phone}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Video de apresentação
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <a href={selectedProfessional?.presentationVideo}>
              {selectedProfessional?.presentationVideo}
            </a>
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento profissional
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.professionalDocumentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Data de aprovação
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.approvedAt ||
              moment(selectedProfessional?.createdAt).format('DD/MM/YYYY')}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Preço da sessão
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {Number(selectedProfessional?.sessionPrice).toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={selectedClient}
        handleClose={() => setSelectedClient(null)}
      >
        <Grid>
          <Grid sx={{ mb: 2 }} container row>
            {selectedClient?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedClient?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedClient?.firstName} {selectedClient?.lastName}
            </Typography>
          </Grid>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Data de Nascimento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {new Date(selectedClient?.birthday).toLocaleDateString('pt-br') ||
              'Não informado'}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Email
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedClient?.email}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedClient?.phone}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedClient?.documentNumber}
          </Typography>

          {/* Legal Guardian */}
          {selectedClient?.legalGuardianFullName ? (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Nome do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianFullName}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Email do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianEmail}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Telefone do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianPhone}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Documento do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedClient?.legalGuardianDocumentNumber}
              </Typography>
            </>
          ) : null}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Endereço
          </Typography>
          {selectedClient?.address !== 'Não informado' ? (
            <Typography sx={{ mt: 1 }}>{`${
              selectedClient?.address?.street
                ? selectedClient?.address?.street + ', '
                : ''
            }${
              selectedClient?.address?.street_number
                ? selectedClient?.address?.complement &&
                  selectedClient?.address?.complement !== 'N/A'
                  ? selectedClient?.address?.street_number + ' '
                  : selectedClient?.address?.street_number + ', '
                : ''
            }${
              selectedClient?.address?.complement &&
              selectedClient?.address?.complement !== 'N/A'
                ? selectedClient?.address?.complement + ', '
                : ''
            }${
              selectedClient?.address?.neighborhood
                ? selectedClient?.address?.neighborhood + ', '
                : ''
            }${
              selectedClient?.address?.city
                ? selectedClient?.address?.city + ', '
                : ''
            }${
              selectedClient?.address?.state
                ? selectedClient?.address?.state + ', '
                : ''
            }${
              selectedClient?.address?.country
                ? selectedClient?.address?.country + ', '
                : ''
            }${
              selectedClient?.address?.cep
                ? selectedClient?.address?.cep + '.'
                : ''
            }`}</Typography>
          ) : (
            <Typography sx={{ mt: 1 }}>{selectedClient?.address}</Typography>
          )}
          <Typography variant="h6" sx={{ mt: 2 }}>
            Conta criada em:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {new Date(selectedClient?.createdAt).toLocaleDateString('pt-br') ||
              'Não foi possível recuperar'}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={selectedReport}
        handleClose={() => setSelectedReport(null)}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Reclamação
          </Typography>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Typography variant="h5" sx={{ mt: 2 }}>
              Menssagem
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {selectedReport?.message || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Tipo da reclamação
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {selectedReport?.typeReport === 'professional_not_attend'
                ? 'Não Compareceu'
                : 'Outro'}
            </Typography>

            {/* <Typography variant="h5" sx={{ mt: 2 }}>
            Documento
            </Typography>
            <Typography sx={{ mt: 1 }}>
            <AttachmentDocument src={selectedReport?.document} />
          </Typography> */}

            <Typography variant="h5" sx={{ mt: 2 }}>
              Criado em:
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {new Date(selectedReport?.createdAt).toLocaleDateString(
                'pt-br'
              ) || 'Não foi possível recuperar'}
            </Typography>
          </Grid>
        </Grid>
      </ModalContent>

      {/* <ModalAlert
        open={alertModal}
        handleClose={() => setAlertModal(false)}
        handleSubmit={() => handleDelete(coupon?.id)}
      >
        <Typography variant="h5">
          Você tem certeza que deseja apagar este cupom?
        </Typography>
      </ModalAlert> */}
    </AuthorizedLayout>
  );
}
