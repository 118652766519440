import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Loading from '../../../../components/Loading';
import defaultImage from '../../../../images/defaultImage.jpg';
import { AuthorizedLayout } from '../../../../layouts/AuthorizedLayout';
import api from '../../../../services/api';
import { visibilities } from '../../../../utils/dataOptions';
import { getConvertMembersType } from '../../../../utils/getConvertPlanPtBr';

const style = {
  padding: '20px',
  width: 900,
  margin: '10px auto',
};

export const NewCourse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const themes = location?.state?.categories?.map(({ title }) => title) || [
    'N/A',
  ];

  const handleCreate = async (values) => {
    try {
      const request = {
        data: {
          attributes: {
            title: values.title,
            image: {
              data: values.image,
            },
            description: values.description,
            theme: values.theme,
            visibility: values.visibility,
            courseType: values.courseType,
            credits: values.canBeSold === true ? values.credits : 0,
            canBeSold: values.canBeSold,
          },
        },
      };
      if (values.courseType === 'course') {
        delete request.data.attributes.theme;
      }
      const { data } = await api.post(`/courses`, request);
      if (data.data) {
        toast.success('Curso criado com sucesso!');
        form.resetForm();
        navigate('/contents', { state: { to: 'cursos' } });
      }
    } catch (error) {
      toast.error('Erro ao criar curso!');
    }
  };

  const schema = Yup.object().shape({
    title: Yup.string().required('Campo obrigatório'),
    image: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
    courseType: Yup.string().required('Campo obrigatório'),
    visibility: Yup.array().required('Campo obrigatório'),
    credits: Yup.number()
      .integer()
      .typeError('Deve ser um número inteiro')
      .optional(),
    canBeSold: Yup.boolean().optional(),
  });

  const form = useFormik({
    initialValues: {
      title: '',
      image: '',
      description: '',
      theme: '',
      courseType: 'course',
      visibility: [],
      canBeSold: false,
      credits: 0,
    },

    onSubmit: (values) => handleCreate(values),
    validateYupSchema: true,
    validationSchema: schema,
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue('image', reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} style={style}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">Curso</Typography>
          </Grid>
          <Grid item xs={12} direction="column" align="center">
            <label style={{ cursor: 'pointer' }}>
              <img
                src={form.values.image || defaultImage}
                alt=""
                height="250"
                width="250"
              />
              <input
                hidden
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  storeAvatarAsBase64(file);
                }}
              />
            </label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              variant="outlined"
              label="Título"
              value={form.values.title}
              onChange={(e) => form.handleChange(e)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              id="description"
              variant="outlined"
              label="Descrição"
              value={form.values.description}
              onChange={(e) => form.handleChange(e)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginTop: '25px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel id="course-type">Tipo do curso</FormLabel>
              <RadioGroup
                aria-label="course-type"
                name="course-type"
                row
                onChange={(e) =>
                  form.setFieldValue('courseType', e.target.value)
                }
                value={form.values.courseType}
              >
                <FormControlLabel
                  value="course"
                  control={<Radio />}
                  label="Normal"
                />
                <FormControlLabel
                  value="webinar"
                  control={<Radio />}
                  label="Webinar"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {form.values.courseType === 'webinar' && (
            <Grid item xs={12}>
              <InputLabel id="course-type">Tema</InputLabel>
              <FormControl fullWidth>
                <Select
                  placeholder="Selecione um tema"
                  label="Tema"
                  labelId="themes"
                  value={form.values.theme}
                  onChange={({ target }) =>
                    form.setFieldValue('theme', target.value)
                  }
                  renderValue={(selectedThemes) => {
                    return (
                      <Box>
                        <Chip key={selectedThemes} label={selectedThemes} />
                      </Box>
                    );
                  }}
                >
                  {themes.map((theme) => (
                    <MenuItem value={theme} key={theme}>
                      {theme}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={form.values.visibility}
                multiple
                onChange={({ target }) =>
                  form.setFieldValue('visibility', target.value)
                }
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((visibility) => (
                        <Chip
                          key={visibility}
                          label={getConvertMembersType(visibility)}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => form.handleSubmit()}>Criar Curso</Button>
          </Grid>
        </Grid>
      )}
    </AuthorizedLayout>
  );
};
