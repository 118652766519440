/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Item } from '../../styles';
import { getProfessionName } from '../../utils/getProfessionName';
import FormikControl from '../FomikControl';

export default function ProfessionalDataForm({ ...props }) {
  const { form, professional, ...rest } = props;
  const { setFieldValue, handleChange, handleBlur, values, errors } = form;
  const [specialities, setSpecialities] = useState([]);
  const [approaches, setApproaches] = useState([]);
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const { data } = await api.get('/professions');

        if (data.data) {
          const profession = data?.data?.attributes?.professions.map(
            (profession) => profession
          );

          const userProfession = profession.find((professions) => {
            return professions.name === professional.profession;
          });

          setSpecialities(userProfession?.specialities);
          setApproaches(userProfession?.approaches);
          setReasons(userProfession?.reasons);
        }
      } catch (error) {
        toast.error('Erro ao carregar especialidades');
      }
    };
    getSpecialities();
  }, []);

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="column"
      justifyContent="flex-start"
      style={{ width: '100%' }}
      {...rest}
    >
      <Typography
        variant="h5"
        sx={{ mb: 1, textAlign: 'center', width: '100%' }}
      >
        Informações Profissionais
      </Typography>
      <Box sx={{ flexGrow: 1, pt: 5, width: '100%' }}>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                name="professionalDocumentNumber"
                label="N de registro profissional"
                type="text"
                control="input"
                value={values?.professionalDocumentNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                name="profession"
                label="Carreira profissional"
                type="text"
                control="input"
                value={getProfessionName(professional?.profession, 'toPt')}
                fullWidth
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="selectMultiple"
                placeholder="Selecione as especialidades"
                label="Especialidades"
                name="specialities"
                onChange={setFieldValue}
                onBlur={handleBlur}
                options={specialities}
                defaultValue={values?.specialities}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="selectMultiple"
                placeholder="Selecione as abordagens"
                label="Abordagens"
                name="approaches"
                onChange={setFieldValue}
                onBlur={handleBlur}
                options={approaches}
                defaultValue={values?.approaches}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="selectMultiple"
                placeholder="Selecione os motivos"
                label="Motivos"
                name="reasons"
                onChange={setFieldValue}
                onBlur={handleBlur}
                options={reasons}
                defaultValue={values?.reasons}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.bio}
                fullWidth
                label="Mini Bio"
                multiline
                name="bio"
                onBlur={handleBlur}
                onChange={handleChange}
                rows={5}
                type="text"
                value={values?.bio}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="input"
                fullWidth
                variant="standard"
                label="Video de apresentação"
                name="presentationVideo"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'https://www.youtube.com/'}
                type="url"
                value={values.presentationVideo}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <FormikControl
                control="selectMultiple"
                defaultValue={values?.ageGroups}
                label="Faixa etária"
                name="ageGroups"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={values?.ageGroups}
                placeholder="Faixa etária"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                fullWidth
                variant="standard"
                label="Tempo de experiência"
                min={0}
                name="yearsOfExperience"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.yearsOfExperience}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                fullWidth
                variant="standard"
                label="Preço da sessão"
                min={0}
                name="sessionPrice"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.sessionPrice}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
