/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { createContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import api from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('@allminds:token');
    const userStorage = localStorage.getItem('@allminds:user');

    if (userStorage) {
      setAuthData(JSON.parse(userStorage));
    }
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    setLoading(false);
  }, []);

  const signIn = async ({ email, password }) => {
    try {
      const { data } = await api.post(`/authenticate`, {
        email: email,
        password: password,
      });
      const user = data.data.attributes;
      user.id = data.data.id;

      if (user.authToken) {
        // Adding it's token to all requests for now on.
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        localStorage.setItem('@allminds:token', user.authToken);
        localStorage.setItem(
          '@allminds:user',
          JSON.stringify({ ...user, id: data.data.id })
        );
        setAuthData({ ...user, id: data.data.id });
      } else {
        throw new Error('Token not found');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const signOut = async () => {
    //   await AsyncStorage.multiRemove(['@Allmidz: user']);

    api.defaults.headers.Authorization = null;

    setAuthData('');
    localStorage.clear();
  };

  const signOutIfTokenExpired = async () => {
    const user = await localStorage.getItem('@allminds:user');
    if (user) {
      const parsedUser = JSON.parse(user);

      if (moment(parsedUser.tokenExpirationDate).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers.Authorization = `Bearer ${parsedUser.authToken}`;
        setAuthData(parsedUser);
      }
    } else {
      signOut();
    }
  };

  useEffect(() => {
    signOutIfTokenExpired();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: authData,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
