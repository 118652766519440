import { Article, VerifiedUser } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EmailIcon from '@mui/icons-material/Email';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PeopleIcon from '@mui/icons-material/People';
import Receipt from '@mui/icons-material/Receipt';
import ReportIcon from '@mui/icons-material/Report';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SchoolIcon from '@mui/icons-material/School';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import React from 'react';
import { SidebarLink } from './styles';

export const MainListItems = () => {
  return (
    <div>
      <SidebarLink to={'/professionals-pending'}>
        <ListItem>
          <ListItemIcon>
            <VerifiedUser />
          </ListItemIcon>
          <ListItemText primary="Profissionais Pendentes" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/professionals-approved'}>
        <ListItem>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Profissionais Aprovados" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/professionals-disapproved'}>
        <ListItem>
          <ListItemIcon>
            <DoNotDisturbIcon />
          </ListItemIcon>
          <ListItemText primary="Profissionais Reprovados" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/clients'}>
        <ListItem>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/clinics'}>
        <ListItem>
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary="Clinicas" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/affiliated_schools'}>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Escolas Conveniadas" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/schedules'}>
        <ListItem>
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText primary="Agendamentos" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/cancelled-schedules'}>
        <ListItem>
          <ListItemIcon>
            <EventBusyIcon />
          </ListItemIcon>
          <ListItemText primary="Agendamentos Cancelados" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/coupons'}>
        <ListItem>
          <ListItemIcon>
            <LoyaltyIcon />
          </ListItemIcon>
          <ListItemText primary="Cupons" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/contents'}>
        <ListItem button>
          <ListItemIcon>
            <Article />
          </ListItemIcon>
          <ListItemText primary="Conteúdos" />
        </ListItem>
      </SidebarLink>
      <SidebarLink to={'/e-mails'}>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="E-Mails e SMS Institucionais" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/reports'}>
        <ListItem>
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Relatório de Reclamações" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={'/invoices-verification'}>
        <ListItem>
          <Receipt>
            <CheckCircleOutlineIcon />
          </Receipt>
          <ListItemText
            style={{ marginLeft: '2em' }}
            primary="Notas Fiscais Pendentes"
          />
        </ListItem>
      </SidebarLink>
    </div>
  );
};
