import styled from "styled-components";
import { ReactComponent as LoginImage } from "../../images/login-img.svg";


export const ImgLogin = styled(LoginImage)`
    position: absolute;
    top: -10px;
    z-index: 0;
    right: 0;
    width: 300px;
    height: 280px;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  background-size: cover;

  .absolute {
    position: absolute;
    top: -10px;
    z-index: 0;
    right: 0;
    width: 300px;
    height: 280px;
  }

  .--login-wrapper {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;

    &-button {
      margin-top: 20px;
    }

    &-title {
      margin: 1em 0;
      text-align: center;
      font-size: 14px;
      padding: 1px 1px;
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.pink};
      font-weight: 400;
      line-height: 27px;
      color: ${({ theme }) => theme.pink};
    }

    &-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      align-self: center;
      padding: 50px 0 10px 0;
      color: ${({ theme }) => theme.lightGray};
    }

    img {
      align-self: center;
      margin-bottom: 20px;
      height: 68px;
      width: 117px;
    }
  }
`;