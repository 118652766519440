import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextEditor } from '../../../../../../components/Input';
import Loading from '../../../../../../components/Loading';
import defaultImage from '../../../../../../images/defaultImage.jpg';
import { AuthorizedLayout } from '../../../../../../layouts/AuthorizedLayout';
import api from '../../../../../../services/api';

const style = {
  padding: '20px',
  width: 900,
  margin: '10px auto',
};

export const NewLesson = () => {
  const navigate = useNavigate();
  const { module_id } = useParams();
  const [values, setValues] = useState({
    title: '',
    image: '',
    description: '',
    link: '',
    pdf: '',
  });
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState('');

  const storeFileAsBase64 = (file, field) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setValues({ ...values, [field]: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const checkFields = () => {
    const { title, image, description, link, pdf } = values;
    if (!title) {
      toast.error('Preencha o campo de título!');
      return false;
    }
    if (!image) {
      toast.error('Selecione uma imagem!');
      return false;
    }
    if ([description, link, pdf].every((field) => field === '')) {
      toast.error('Preencha pelo menos um dos campos "Descrição, Link ou PDF!');
      return false;
    }
    return true;
  };

  const createLesson = async () => {
    try {
      if (checkFields()) {
        setLoading(true);
        const request = {
          data: {
            attributes: {
              title: values.title,
              description: editor,
              image: { data: values.image },
              course_module_id: module_id,
              video_link: values.link,
              document: { data: values.pdf },
            },
          },
        };
        if (values.pdf === '') delete request.data.attributes.document;
        if (values.link === '') delete request.data.attributes.video_link;

        await api.post(`/lessons`, request);
        setValues({
          title: '',
          image: '',
          description: '',
          link: '',
          pdf: '',
        });
        setEditor('');
        toast.success('Aula criada com sucesso!');
        navigate(-1);
      }
    } catch (error) {
      toast.error('Erro ao criar a aula!');
    } finally {
      setLoading(false);
    }
  };

  const sendEditor = (value) => {
    setValues({ ...values, description: JSON.parse(value)?.blocks[0]?.text });
    setEditor(value);
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} style={style}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">Aula</Typography>
          </Grid>
          <Grid item xs={12} direction="column" align="center">
            <label style={{ cursor: 'pointer' }}>
              <img
                src={values.image || defaultImage}
                alt=""
                height="250"
                width="250"
              />
              <input
                hidden
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  storeFileAsBase64(file, 'image');
                }}
              />
            </label>
          </Grid>
          <Grid container xs={12}>
            <Grid container item sx={{ mb: 3 }}>
              <TextField
                id="title"
                variant="outlined"
                label="Título da Aula"
                value={values.title}
                onChange={(e) => {
                  setValues({ ...values, title: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid container item sx={{ mb: 3 }}>
              <label htmlFor="contained-button-file">Descrição</label>
              <div
                style={{
                  border: '1px solid #ced0db',
                  borderRadius: '0.25rem',
                }}
              >
                <TextEditor setEditor={(value) => sendEditor(value)} />
              </div>
            </Grid>

            <Grid container item sx={{ mb: 3 }}>
              <input
                id="contained-button-file"
                type="file"
                name="pdf"
                accept=".pdf"
                hidden
                onChange={(e) => {
                  const file = e.target.files[0];
                  storeFileAsBase64(file, 'pdf');
                }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  {values.pdf ? 'PDF Selecionado' : 'Selecionar PDF'}
                </Button>
              </label>
            </Grid>

            <Grid container item sx={{ mb: 3 }}>
              <TextField
                id="link"
                variant="outlined"
                label="Link"
                value={values.link}
                onChange={(e) => {
                  setValues({ ...values, link: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: '25px' }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => createLesson()}>Criar Aula</Button>
          </Grid>
        </Grid>
      )}
    </AuthorizedLayout>
  );
};
