import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Button,
  Grid,
  TableCell,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import ModalContent from '../../../components/Modais/ModalContent';
import LayoutMUIDataTable from '../../../layouts/LayoutMUIDataTable';
import api from '../../../services/api';
import { getConvertMembersType } from '../../../utils/getConvertPlanPtBr';
import { trimString } from '../../../utils/trimString';

export default function ContentArticles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [articlesList, setArticlesList] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    const getArticles = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/posts`);

        if (data.data) {
          const parseData = data.data
            .filter((article) => article.attributes.postType !== 'video')
            .map((article) => ({
              ...article.attributes,
              id: parseInt(article.id),
              tDescription: trimString(article.attributes.description, 60),
              tTitle: trimString(article.attributes.title, 20),
            }));
          setArticlesList(parseData);
          setLoading(false);
        } else throw new Error('Erro ao carregar artigos!');
      } catch (error) {
        toast.error('Erro ao carregar artigos!');
        setLoading(false);
      }
    };
    getArticles();
  }, []);

  const columnsArticles = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const article = articlesList.find((s) => s.id === value);
              setSelectedArticle(article);
            }}
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tTitle',
      label: 'Título',
    },
    {
      name: 'image',
      label: 'Imagem',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <img
              src={value}
              style={{ width: 100, height: 100, borderRadius: 10 }}
              alt="Imagem do curso"
            />
          );
        },
      },
    },
    {
      name: 'tDescription',
      label: 'Descrição',
    },
    {
      name: 'categories',
      label: 'Categorias',
      options: {
        customBodyRender: (value) => (
          <Typography
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value.map((item) => item.title).join(', ')}
          </Typography>
        ),
        filter: false,
      },
    },
    {
      name: 'visibility',
      label: 'Visibilidade',
      options: {
        customBodyRender: (value) => (
          <Typography
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value.map((v) => getConvertMembersType(v)).join(', ')}
          </Typography>
        ),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() =>
                  navigate('/new-article', { state: { id: rowData[0] } })
                }
              >
                <EditIcon />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const HeaderElementsArticles = () => (
    <Button
      onClick={() => {
        navigate('/new-article');
      }}
    >
      Criar Artigo <AddIcon />
    </Button>
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <LayoutMUIDataTable
        title="Artigos"
        data={articlesList}
        columns={columnsArticles}
        customToolbar={<HeaderElementsArticles />}
      />

      <ModalContent
        open={selectedArticle}
        handleClose={() => setSelectedArticle(null)}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              Detalhes do curso
            </Typography>
          </div>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Título
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedArticle?.title || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Tipo do Curso
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedArticle?.courseType === 'course'
                ? 'Curso'
                : 'Mini Curso' || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Imagem
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Descrição
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedArticle?.description || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Categorias
            </Typography>
            {selectedArticle?.categories.map((category) => (
              <Typography variant="h7" sx={{ mt: 2, mr: 1 }}>
                {category.title || 'Não informado'}
              </Typography>
            ))}

            <Typography variant="h5" sx={{ mt: 2 }}>
              Visibilidade
            </Typography>
            {selectedArticle?.visibility.map((visibility) => (
              <Typography variant="h7" sx={{ mt: 2, mr: 1 }}>
                {getConvertMembersType(visibility) || 'Não informado'}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
}
