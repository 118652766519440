/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button, TableCell } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutMUIDataTable from '../../../layouts/LayoutMUIDataTable';

export const ContentCategorys = ({ ...props }) => {
  const { categories } = props;
  const navigate = useNavigate();

  const columnsCategories = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'title',
      label: 'Título',
    },
    {
      name: 'author',
      label: 'Autor',
    },
    {
      name: 'postCount',
      label: 'Utilização',
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() =>
                  navigate('/category', { state: { id: rowData[0] } })
                }
              >
                <EditIcon />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const HeaderElementsCategories = () => (
    <Button
      onClick={() => {
        navigate('/category');
      }}
    >
      Criar Categoria <AddIcon />
    </Button>
  );

  return (
    <LayoutMUIDataTable
      title="Categorias"
      data={categories}
      columns={columnsCategories}
      customToolbar={<HeaderElementsCategories />}
    />
  );
};
