import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loading';
import defaultImage from '../../../../images/defaultImage.jpg';
import { AuthorizedLayout } from '../../../../layouts/AuthorizedLayout';
import api from '../../../../services/api';
import { visibilities } from '../../../../utils/dataOptions';
import { getConvertMembersType } from '../../../../utils/getConvertPlanPtBr';
import { Chip } from '@mui/material';

const style = {
  padding: '20px',
  width: 900,
  margin: '10px auto',
};

export const NewVideo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    title: '',
    image: '',
    description: '',
    videoLink: '',
    postType: 'video',
    visibility: [],
  });

  const verifyFields = () => {
    if (
      values.title === '' ||
      values.description === '' ||
      values.videoLink === '' ||
      values.image === '' ||
      values.visibility.length === 0
    ) {
      toast.error('Preencha todos os campos!');
      return false;
    }
    return true;
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setValues({ ...values, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!verifyFields()) return;
      const request = {
        data: {
          attributes: {
            title: values.title,
            description: values.description,
            image: { data: values.image },
            videoLink: values.videoLink,
            postType: values.postType,
            visibility: values.visibility,
          },
        },
      };
      const { data } = await api.post('/posts', request);
      if (data.data) {
        toast.success('Vídeo cadastrado com sucesso!');
        navigate('/contents', { state: { to: 'videos' } });
      }
    } catch (error) {
      toast.error('Erro ao cadastrar vídeo!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} style={style}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">Vídeo</Typography>
          </Grid>
          <Grid item xs={12} direction="column" align="center">
            <label style={{ cursor: 'pointer' }}>
              <img
                src={values.image || defaultImage}
                alt=""
                height="250"
                width="250"
              />
              <input
                hidden
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  storeAvatarAsBase64(file);
                }}
              />
            </label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              variant="outlined"
              label="Link do vídeo"
              value={values.videoLink}
              onChange={(e) => {
                setValues({ ...values, videoLink: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              id="title"
              variant="outlined"
              label="Título"
              value={values.title}
              onChange={(e) => {
                setValues({ ...values, title: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginTop: '25px' }}
            />
            <TextField
              id="title"
              variant="outlined"
              label="Descrição"
              value={values.description}
              onChange={(e) => {
                setValues({ ...values, description: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginTop: '25px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={values.visibility}
                multiple
                onChange={({ target }) =>
                  setValues({ ...values, visibility: target.value })
                }
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((visibility) => (
                        <Chip
                          key={visibility}
                          label={getConvertMembersType(visibility)}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => handleSubmit()}>Criar Vídeo</Button>
          </Grid>
        </Grid>
      )}
    </AuthorizedLayout>
  );
};
