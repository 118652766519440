/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

export const SmallUserAvatar = ({ src, icon }) => {
  return (
    <figure
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        position: 'relative',
        width: 'fit-content',
      }}
    >
      <img
        src={src}
        style={{
          width: 50,
          height: 50,
          borderRadius: '50%',
        }}
      />
      {icon}
    </figure>
  );
};
