/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NavTopBar() {
  const navigate = useNavigate();

  return (
    <Grid
      sx={{
        bgcolor: 'Background',
      }}
      container
      row
      justifyContent="space-between"
    >
      <Button
        sx={{
          border: '1px solid #fff',
        }}
        onClick={() => navigate(-1)}
      >
        Página Anterior
      </Button>

      <Button
        sx={{
          border: '1px solid #fff',
        }}
        onClick={() => navigate(1)}
      >
        Próxima Página
      </Button>
    </Grid>
  );
}
