/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAuth from '../../hooks/auth';
import Logo from '../../images/largeLogo.png';
import { Main } from './styles';

export const Login = () => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object({
    email: Yup.string().email('Email invalido').required('Requerido'),
    password: Yup.string()
      .required('Requerido')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
  });

  const handleContinue = async (values) => {
    const { email, password } = values;

    try {
      setLoading(true);
      await signIn({ email: email, password: password });
    } catch (error) {
      toast.error('Credenciais inválidas');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleContinue(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <section className="--login-wrapper" style={{ height: '100vh' }}>
              <img src={Logo} alt="logo image" style={{ width: 200 }} />
              <label className="--login-wrapper-title">Admin</label>
              <section style={{ width: 300 }}>
                <TextField
                  error={errors.email && touched.email}
                  label="Email"
                  sx={{ width: '100%' }}
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={errors.password && touched.password}
                  label="Senha"
                  sx={{ width: '100%', marginTop: 1 }}
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <section className="--login-wrapper-button">
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    type="submit"
                  >
                    {loading ? 'Carregando...' : 'Entrar'}
                  </Button>
                </section>
              </section>
            </section>
          </form>
        )}
      </Formik>
    </Main>
  );
};
