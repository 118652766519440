import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { trimString } from '../../../../../utils/trimString';

export const LessonComponent = ({ lesson: parseLesson }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        backgroundColor: '#426AFF',
        margin: '10px 10px',
      }}
    >
      <CardMedia sx={{ height: 340 }} image={parseLesson.image} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="black">
          {parseLesson.title}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {parseLesson.description.slice(4, 14).includes('blocks')
            ? trimString(
                JSON.parse(parseLesson.description)?.blocks[0]?.text,
                30
              )
            : trimString(parseLesson.description, 30)}
        </Typography>

        <CardActions>
          <Button
            size="small"
            fullWidth
            color="info"
            style={{ backgroundColor: 'white' }}
            onClick={() =>
              navigate(`view-lesson/${parseLesson.lessonId}`, {
                state: parseLesson,
              })
            }
          >
            Abrir
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};
