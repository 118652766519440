/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TableCell, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { getSortedDatesHours } from '../../utils/getSortedDates';
import moment from 'moment';

export const PatientsList = () => {
  const location = useLocation();
  const type = location.pathname.includes('clinic') ? 'clinic' : 'professional';
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(selectedPatient, 'selectedPatient');

  const getLastSchedule = (date, time) => {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    const formattedTime = moment(time, 'HH:mm:ss').format('HH:mm');

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    const controller = new AbortController();
    const getPatients = async () => {
      setLoading(true);
      const { data } = await api.get(
        `/${type}s/${location?.state?.id}/patients`,
        {
          signal: controller.signal,
        }
      );

      let parsedData = [];

      if (type === 'clinic') {
        parsedData = data.data.map((p) => ({
          id: parseInt(p.attributes.client.id),
          ...p.attributes,
          clientFirstName: p.attributes.client.firstName,
          clientLastName: p.attributes.client.lastName,
          clientEmail: p.attributes.client.email,
          clientPhone: p.attributes.client.phone,
          clinicName: location?.state?.clinicName,
          lastSchedule: getLastSchedule(
            p.attributes.client.lastSchedule.startDate,
            p.attributes.client.lastSchedule.startTime
          ),
          avatar: p.attributes.client.avatar,
        }));
      } else {
        parsedData = data.data.map((p) => ({
          id: parseInt(p.attributes.client.id),
          ...p.attributes,
          clientFirstName: p.attributes.client.firstName,
          clientLastName: p.attributes.client.lastName,
          clientEmail: p.attributes.client.email,
          clientPhone: p.attributes.client.phone,
          lastSchedule: getLastSchedule(
            p.attributes.lastSchedule.startDate,
            p.attributes.lastSchedule.startTime
          ),
          avatar: p.attributes.client.avatar,
        }));
      }

      setPatients(parsedData);
      setLoading(false);
    };

    getPatients();

    return () => controller.abort();
  }, []);

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => {
          if (value === null) {
            return (
              <div
                onClick={() => {
                  setSelectedPatient(
                    patients.filter((p) => p.id === rowData[1])[0]
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                <SmallUserAvatar src={noAvatar} />
              </div>
            );
          } else {
            return (
              <div
                onClick={() => {
                  setSelectedPatient(
                    patients.filter((p) => p.id === rowData[1])[0]
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                <SmallUserAvatar src={value} />
              </div>
            );
          }
        },
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientFirstName',
      label: 'Nome',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientLastName',
      label: 'Sobrenome',
      options: {
        display: false,
      },
    },
    {
      name: 'clinicName',
      label: 'Clínica',
      options: {
        display: type === 'clinic',
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'schedulesCount',
      label: 'Agendamentos',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'promotionalSchedules',
      label: 'Agendamentos Promocionais',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'patientReviews',
      label: 'Avaliações',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientPhone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastSchedule',
      label: 'Último Agendamento',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sortCompare: (order) => (date1, date2) =>
          getSortedDatesHours(date1, date2, order),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Pacientes"
          data={patients}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedPatient}
        handleClose={() => setSelectedPatient(null)}
      >
        <Grid>
          <Grid container row>
            {selectedPatient?.client?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedPatient?.client?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedPatient?.client?.firstName}{' '}
              {selectedPatient?.client?.lastName}
            </Typography>
          </Grid>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Data de Nascimento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {new Date(selectedPatient?.client?.birthday).toLocaleDateString(
              'pt-br'
            ) || 'Não informado'}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Email
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedPatient?.client?.email}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedPatient?.client?.phone}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedPatient?.client?.documentNumber}
          </Typography>

          {/* Legal Guardian */}
          {selectedPatient?.client?.legalGuardianFullName ? (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Nome do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedPatient?.client?.legalGuardianFullName}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Email do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedPatient?.client?.legalGuardianEmail}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Telefone do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedPatient?.client?.legalGuardianPhone}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Documento do guardião legal
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedPatient?.client?.legalGuardianDocumentNumber}
              </Typography>
            </>
          ) : null}

          {selectedPatient?.client?.address && (
            <Fragment>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Endereço
              </Typography>
              <Typography sx={{ mt: 1 }}>{`${
                selectedPatient?.client?.address?.street
                  ? selectedPatient?.client?.address?.street + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.street_number
                  ? selectedPatient?.client?.address?.complement &&
                    selectedPatient?.client?.address?.complement !== 'N/A'
                    ? selectedPatient?.client?.address?.street_number + ' '
                    : selectedPatient?.client?.address?.street_number + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.complement &&
                selectedPatient?.client?.address?.complement !== 'N/A'
                  ? selectedPatient?.client?.address?.complement + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.neighborhood
                  ? selectedPatient?.client?.address?.neighborhood + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.city
                  ? selectedPatient?.client?.address?.city + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.state
                  ? selectedPatient?.client?.address?.state + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.country
                  ? selectedPatient?.client?.address?.country + ', '
                  : ''
              }${
                selectedPatient?.client?.address?.cep
                  ? selectedPatient?.client?.address?.cep + '.'
                  : ''
              }`}</Typography>
            </Fragment>
          )}

          {selectedPatient?.client?.createdAt && (
            <Fragment>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Conta criada em:
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {new Date(
                  selectedPatient?.client?.createdAt
                ).toLocaleDateString('pt-br') || 'Não foi possível recuperar'}
              </Typography>
            </Fragment>
          )}
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
