/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { viaCepApi } from '../../services/api';
import FormikControl from '../FomikControl';
import { Item } from '../../styles';

export default function AddressForm({ ...props }) {
  const { form, ...rest } = props;
  const { values, setValues, setFieldValue, errors, handleChange, handleBlur } =
    form;
  const [cep, setCep] = useState('');

  const onlyNumbers = (str) => str.replace(/[_]/g, '');

  useEffect(() => {
    const handleCEP = async () => {
      const { data } = await viaCepApi.get(`${values?.cep}/json/`);
      if (data) {
        setValues({
          ...values,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          cep: onlyNumbers(cep),
          country: 'Brasil',
        });
      }
    };
    if (cep.length === 9) handleCEP();
  }, [cep]);

  const validateComplement = () => {
    if ([null, ''].includes(values?.complement)) {
      setFieldValue('complement', 'N/A');
    }
  };

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="column"
      justifyContent="flex-start"
      style={{ width: '100%' }}
      {...rest}
    >
      <Typography
        sx={{ mb: 1, textAlign: 'center', width: '100%' }}
        variant="h5"
      >
        Endereço
      </Typography>
      <Box sx={{ flexGrow: 1, pt: 5, width: '100%' }}>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="inputMask"
                errors={errors.cep}
                fullWidth
                id="cep"
                label="CEP"
                mask="99999-999"
                onBlur={handleBlur}
                onChange={handleChange}
                onInput={({ target }) => setCep(target.value)}
                value={values.cep}
                variant="standard"
                width="100%"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.street}
                fullWidth
                id="street"
                label="Rua"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.streetNumber}
                fullWidth
                id="streetNumber"
                label="Número"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.streetNumber}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                alignItems="flex-end"
                control="input"
                errors={errors.complement}
                fullWidth
                id="complement"
                label="Complemento"
                onBlur={() => {
                  validateComplement();
                }}
                onChange={handleChange}
                value={values.complement}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.neighborhood}
                fullWidth
                id="neighborhood"
                label="Bairro"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.neighborhood}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                alignItems="flex-end"
                control="input"
                errors={errors.city}
                fullWidth
                id="city"
                label="Cidade"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.state}
                fullWidth
                id="state"
                label="Estado"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                alignItems="flex-end"
                control="input"
                errors={errors.country}
                fullWidth
                id="country"
                label="País"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                variant="standard"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
