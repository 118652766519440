/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import api from '../../services/api';
import ContentArticles from './Articles';
import { ContentCategorys } from './Categorys';
import ContentCourses from './Courses';
import { ContentTags } from './Tags';
import ContentVideos from './Videos';

export const Contents = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [label, setLabel] = useState('cursos');

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/categories`);

        if (data.data) {
          const parseData = data.data.map((p) => ({
            ...p.attributes,
            id: parseInt(p.id),
          }));
          setCategoriesList(parseData);
        } else {
          throw new Error('Erro ao carregar categorias!');
        }
      } catch (error) {
        toast.error('Erro ao carregar categorias!');
      } finally {
        setLoading(false);
      }
    };
    getCategories();
  }, []);

  useEffect(() => {
    if (state) {
      setLabel(state.to);
    }
  }, [state]);

  return (
    (loading && <Loading />) || (
      <AuthorizedLayout>
        <Button
          variant={label === 'cursos' ? 'contained' : ''}
          onClick={() => {
            setLabel('cursos');
          }}
        >
          Cursos
        </Button>

        <Button
          variant={label === 'artigos' ? 'contained' : ''}
          onClick={() => {
            setLabel('artigos');
          }}
        >
          Artigos
        </Button>

        <Button
          variant={label === 'videos' ? 'contained' : ''}
          onClick={() => {
            setLabel('videos');
          }}
        >
          Vídeos
        </Button>

        <Button
          variant={label === 'categorias' ? 'contained' : ''}
          onClick={() => {
            setLabel('categorias');
          }}
        >
          Categorias
        </Button>

        <Button
          variant={label === 'tags' ? 'contained' : ''}
          onClick={() => {
            setLabel('tags');
          }}
        >
          Tags
        </Button>

        {label === 'cursos' && <ContentCourses categories={categoriesList} />}

        {label === 'artigos' && <ContentArticles />}

        {label === 'videos' && <ContentVideos />}

        {label === 'categorias' && (
          <ContentCategorys categories={categoriesList} />
        )}

        {label === 'tags' && <ContentTags />}
      </AuthorizedLayout>
    )
  );
};
