import { Box, Grid, Typography } from '@mui/material';
import FormikControl from '../FomikControl';
import moment from 'moment';

export default function CouponForm({ ...props }) {
  const { form, isEdit } = props;
  const { values, errors, setFieldValue, handleChange, handleBlur } = form;

  const couponTypeOptions = [
    { id: 'admin_first_session', title: 'Primeira sessão' },
    { id: 'admin_universal', title: 'Universal' },
  ];

  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        variant="p"
        sx={{
          fontWeight: 700,
          pl: 2,
          pr: 2,
          textAlign: 'justify',
          width: '100%',
        }}
      >
        Obs: O cupom do tipo "Primeira sessão" tem um desconto máximo permitido
        de 100%. Enquanto o do tipo "Universal" tem um desconto máximo permitido
        de 20%.
      </Typography>
      <Typography variant="h5" sx={{ m: 1, mt: 2 }}>
        {!isEdit ? 'Criar novo Cupom' : 'Editar Cupom'}
      </Typography>
      <Box
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        <FormikControl
          control="select"
          fullWidth
          name="couponType"
          label="Tipo de Cupom"
          options={couponTypeOptions}
          onChange={handleChange}
          value={values?.couponType}
          variant="standard"
          width="45%"
        />
        <FormikControl
          alignItems="flex-end"
          fullWidth
          control="input"
          errors={errors.code}
          name="code"
          label="Código"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.code}
          variant="standard"
          width="45%"
        />
      </Box>

      <FormikControl
        control="input"
        fullWidth
        name="title"
        label="Descrição"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values?.title}
        variant="standard"
      />

      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <FormikControl
          control="input"
          errors={errors.amountLimit}
          name="amountLimit"
          variant="standard"
          label="Limite de uso"
          type="number"
          value={values?.amountLimit}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        {values?.discountPrice === 0 ? (
          <FormikControl
            alignItems="flex-end"
            control="input"
            errors={errors.discountPercentage}
            name="discountPercentage"
            variant="standard"
            label="Desconto (%)"
            value={values?.discountPercentage}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        ) : (
          <FormikControl
            control="input"
            errors={errors.discountPrice}
            name="discountPrice"
            variant="standard"
            label="Desconto R$"
            value={values?.discountPrice}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
      </Box>
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        <FormikControl
          control="date"
          label="Data de Início"
          minDate={moment().subtract(1, 'days')}
          onChange={(value) => {
            setFieldValue('dateLimitStart', moment(value).format('YYYY-MM-DD'));
          }}
          value={values?.dateLimitStart}
        />
        <FormikControl
          control="date"
          label="Data de Expiração"
          minDate={moment().subtract(1, 'days')}
          onChange={(value) => {
            setFieldValue('dateLimitEnd', moment(value).format('YYYY-MM-DD'));
          }}
          value={values?.dateLimitEnd}
        />
      </Box>
    </Grid>
  );
}
