import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Loading from '../../../components/Loading';
import ModalContent from '../../../components/Modais/ModalContent';
import LayoutMUIDataTable from '../../../layouts/LayoutMUIDataTable';
import api from '../../../services/api';
import { visibilities } from '../../../utils/dataOptions';
import { getConvertMembersType } from '../../../utils/getConvertPlanPtBr';
import { getConvertTheme } from '../../../utils/getConvertTheme';
import { trimString } from '../../../utils/trimString';

export default function ContentCourses({ categories }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const themes = categories.map(({ title }) => title);

  useEffect(() => {
    const getCourses = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/courses');
        if (data.data) {
          const parseData = data.data.map((course) => {
            return {
              ...course.attributes,
              id: parseInt(course.id),
              tTitle: trimString(course.attributes.title, 20),
              tDescription: trimString(course.attributes.description, 60),
            };
          });
          setCoursesList(parseData);
        }
      } catch (error) {
        toast.error('Erro ao carregar vídeos!');
      } finally {
        setLoading(false);
      }
    };
    getCourses();
  }, []);

  const schema = Yup.object().shape({
    title: Yup.string().required('Campo obrigatório'),
    image: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
    visibility: Yup.array().required('Campo obrigatório'),
    courseType: Yup.string().required('Campo obrigatório'),
    credits: Yup.number()
      .integer()
      .typeError('Informe apenas números inteiros')
      .optional(),
    canBeSold: Yup.boolean().optional(),
  });

  const form = useFormik({
    initialValues: {
      id: '',
      title: '',
      image: '',
      description: '',
      theme: '',
      visibility: [],
      courseType: 'course',
      canBeSold: false,
      credits: 0,
    },

    onSubmit: (values) => updateCourse(values),
    validateYupSchema: true,
    validationSchema: schema,
  });

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue('image', reader.result);
    };
    reader.readAsDataURL(file);
  };

  const deleteCourse = async (id) => {
    try {
      const data = await api.delete(`/courses/${id}`);
      if (data.data) {
        const filteredCourses = coursesList.filter((item) => item.id !== id);
        setCoursesList(filteredCourses);
        toast.success('Curso deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar curso!');
    } finally {
      navigate('/contents', { state: { to: 'cursos' } });
    }
  };

  const updateCourse = async (values) => {
    try {
      const request = {
        data: {
          attributes: {
            title: values.title,
            description: values.description,
            image: {
              data: values.image,
            },
            theme: values.theme,
            visibility: values.visibility,
            courseType: values.courseType,
            canBeSold: values.canBeSold,
            credits: values.credits,
          },
        },
      };
      if (request.data.attributes.image.data === selectedCourse.image) {
        delete request.data.attributes.image;
      }

      if (request.data.attributes.courseType === 'course') {
        delete request.data.attributes.theme;
      }

      const { data } = await api.put(`/courses/${values.id}`, request);
      if (data.data) {
        const courseIndex = coursesList.findIndex(
          (item) => item.id === values.id
        );
        if (courseIndex !== -1) {
          const parseData = {
            id: data.data.id,
            ...data.data.attributes,
            tTitle: trimString(data.data.attributes.title, 20),
            tDescription: trimString(data.data.attributes.description, 60),
          };
          coursesList[courseIndex] = parseData;
        }
        toast.success('Curso editado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao editar curso!');
    } finally {
      navigate('/contents', { state: { to: 'cursos' } });
      setEditModal(false);
      setSelectedCourse(null);
    }
  };

  const editForm = (id) => {
    const course = coursesList.find((item) => item.id === id);
    setEditModal(true);
    setSelectedCourse(course);
    form.setFieldValue('id', course?.id);
    form.setFieldValue('title', course?.title);
    form.setFieldValue('image', course?.image);
    form.setFieldValue('description', course?.description);
    form.setFieldValue('courseType', course?.courseType);
    form.setFieldValue('theme', course?.theme);
    form.setFieldValue('visibility', course?.visibility);
    form.setFieldValue('canBeSold', course?.canBeSold);
    form.setFieldValue('credits', course?.credits);
  };

  const columnsCourses = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const course = coursesList.find((s) => s.id === value);
              setSelectedCourse(course);
            }}
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tTitle',
      label: 'Título',
    },
    {
      name: 'image',
      label: 'Imagem',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <img
              src={value}
              style={{ width: 100, height: 100, borderRadius: 10 }}
              alt="Imagem do curso"
            />
          );
        },
      },
    },
    {
      name: 'tDescription',
      label: 'Descrição',
    },
    {
      name: 'courseType',
      label: 'Tipo',
      options: {
        customBodyRender: (value) => {
          return value === 'course' ? 'Curso' : 'Mini Curso';
        },
      },
    },
    {
      name: 'theme',
      label: 'Tema',
      options: {
        customBodyRender: (value, { rowData }) => {
          const hasTheme = rowData[4] === 'webinar';
          return hasTheme ? getConvertTheme(value) : 'N/A';
        },
      },
    },
    {
      name: 'credits',
      label: 'Créditos',
    },
    {
      name: 'visibility',
      label: 'Visibilidade',
      options: {
        customBodyRender: (value) =>
          value.map((item) => getConvertMembersType(item)).join(', '),
        filter: false,
      },
    },
    {
      name: 'Actions',
      label: 'Ações',
      sortable: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  navigate(`/courses/modules/${rowData[0]}`);
                }}
              >
                <OpenInNewIcon titleAccess="Abrir" />
              </Button>

              <Button onClick={() => editForm(rowData[0])}>
                <EditIcon titleAccess="Editar" />
              </Button>

              <Button onClick={() => deleteCourse(rowData[0])}>
                <Close color="error" titleAccess="Deletar" />
              </Button>

              <Button>
                <CloudUploadIcon titleAccess="Disponibilizar no Site" />
              </Button>

              <Button>
                <CloudOffIcon titleAccess="Retirar do Site" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const HeaderElementsCourses = () => (
    <Button
      onClick={() => {
        navigate('/new-course', { state: { categories } });
      }}
    >
      Criar Curso <AddIcon />
    </Button>
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <LayoutMUIDataTable
        data={coursesList}
        columns={columnsCourses}
        title="Cursos"
        customToolbar={<HeaderElementsCourses />}
      />

      <ModalContent
        open={editModal}
        handleClose={() => {
          setEditModal(false);
          setSelectedCourse(null);
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <label
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                cursor: 'pointer',
                marginBottom: 25,
              }}
            >
              <img
                src={form.values.image}
                alt="Imagem do curso"
                style={{ width: 400, height: 400, borderRadius: 10 }}
              />
              <input
                placeholder="Carregar imagem"
                type="file"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  storeAvatarAsBase64(file);
                }}
                style={{ display: 'none' }}
              />
            </label>
            <TextField
              id="title"
              variant="outlined"
              label="Título"
              value={form.values.title}
              onChange={form.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />

            <TextField
              id="description"
              variant="outlined"
              label="Descrição"
              value={form.values.description}
              onChange={form.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginTop: 25 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel id="course-type">Tipo do curso</FormLabel>
              <RadioGroup
                aria-label="course-type"
                name="course-type"
                row
                onChange={(e) =>
                  form.setFieldValue('courseType', e.target.value)
                }
                value={form.values.courseType}
              >
                <FormControlLabel
                  value="course"
                  control={<Radio />}
                  label="Normal"
                />
                <FormControlLabel
                  value="webinar"
                  control={<Radio />}
                  label="Webinar"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {form.values.courseType === 'webinar' && (
            <Grid item xs={12}>
              <InputLabel id="course-type">Tema</InputLabel>
              <FormControl fullWidth>
                <Select
                  placeholder="Selecione um tema"
                  label="Tema"
                  labelId="themes"
                  value={form.values.theme}
                  onChange={({ target }) =>
                    form.setFieldValue('theme', target.value)
                  }
                  renderValue={(selectedThemes) => {
                    return (
                      <Box>
                        <Chip key={selectedThemes} label={selectedThemes} />
                      </Box>
                    );
                  }}
                >
                  {themes.map((theme) => (
                    <MenuItem value={theme} key={theme}>
                      {theme}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} marginTop={2}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={form.values.visibility}
                multiple
                onChange={({ target }) => {
                  form.setFieldValue('visibility', target.value);
                }}
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((visibility) => (
                        <Chip
                          key={visibility}
                          label={getConvertMembersType(visibility)}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ marginTop: 25, alignSelf: 'flex-end' }}
              onClick={() => form.handleSubmit()}
            >
              Salvar Alterações
            </Button>
          </Grid>
        </Grid>
      </ModalContent>

      <ModalContent
        open={selectedCourse && !editModal}
        handleClose={() => setSelectedCourse(null)}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              Detalhes do curso
            </Typography>
          </div>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Título
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedCourse?.title || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Tipo do Curso
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedCourse?.courseType === 'course'
                ? 'Curso'
                : 'Mini Curso' || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Imagem
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Descrição
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedCourse?.description || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Tema
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedCourse?.theme || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Visibilidade
            </Typography>
            {selectedCourse?.visibility.map((visibility) => (
              <Typography variant="h7" sx={{ mt: 2 }}>
                {getConvertMembersType(visibility) || 'Não informado'}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
}
