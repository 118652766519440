import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Loading from '../../../components/Loading';
import ModalContent from '../../../components/Modais/ModalContent';
import LayoutMUIDataTable from '../../../layouts/LayoutMUIDataTable';
import api from '../../../services/api';
import { visibilities } from '../../../utils/dataOptions';
import { getConvertMembersType } from '../../../utils/getConvertPlanPtBr';
import { trimString } from '../../../utils/trimString';

export default function ContentVideos() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videosList, setVideosList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const getVideos = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/posts`);
        if (data.data) {
          const parseData = data.data
            .filter((video) => video.attributes.postType === 'video')
            .map((video) => ({
              ...video.attributes,
              id: parseInt(video.id),
              tTitle: trimString(video.attributes.title, 20),
              tDescription: trimString(video.attributes.description, 60),
            }));
          setVideosList(parseData);
        } else throw new Error('Erro ao carregar vídeos!');
      } catch (error) {
        toast.error('Erro ao carregar vídeos!');
      } finally {
        setLoading(false);
      }
    };
    getVideos();
  }, []);

  const schema = yup.object().shape({
    title: yup.string().required('Campo obrigatório'),
    image: yup.string().required('Campo obrigatório'),
    videoLink: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    visibility: yup.array().required('Campo obrigatório'),
  });

  const form = useFormik({
    initialValues: {
      description: '',
      id: '',
      image: '',
      postType: 'video',
      title: '',
      videoLink: '',
      visibility: [],
    },

    onSubmit: (values) => updateVideo(values),
    validateYupSchema: true,
    validationSchema: schema,
  });

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue('image', reader.result);
    };
    reader.readAsDataURL(file);
  };

  const deleteVideo = async (id) => {
    try {
      await api.delete(`/posts/${id}`);
      toast.success('Vídeo excluído com sucesso!');
      setVideosList(videosList.filter((video) => video.id !== id));
    } catch (error) {
      toast.error('Erro ao excluir vídeo!');
    }
  };

  const updateVideo = async (values) => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            title: values.title,
            description: values.description,
            image: { data: values.image },
            videoLink: values.videoLink,
            postType: values.postType,
            visibility: values.visibility,
          },
        },
      };
      if (values.image === selectedVideo?.image) {
        delete request.data.attributes.image;
      }
      const { data } = await api.put(`/posts/${values.id}`, request);
      if (data.data) {
        toast.success('Vídeo atualizado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar vídeo!');
    } finally {
      setLoading(false);
      setSelectedVideo(null);
      setIsEdit(false);
    }
  };

  const columnsVideos = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setSelectedVideo(videosList.find((video) => video.id === value))
            }
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tTitle',
      label: 'Título',
    },
    {
      name: 'image',
      label: 'Imagem',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <img
              src={value}
              style={{ width: 100, height: 100, borderRadius: 10 }}
              alt="video"
            />
          );
        },
      },
    },
    {
      name: 'tDescription',
      label: 'Descrição',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              sx={{
                maxWidth: 200,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'visibility',
      label: 'Visibilidade',
      options: {
        customBodyRender: (value) => (
          <Typography
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value.map((v) => getConvertMembersType(v)).join(', ')}
          </Typography>
        ),
      },
    },
    {
      name: 'videoLink',
      label: 'Ações',
      sortable: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onClick={() => {
                form.setFieldValue('id', rowData[0]);
                form.setFieldValue('title', rowData[1]);
                form.setFieldValue('image', rowData[2]);
                form.setFieldValue('description', rowData[3]);
                form.setFieldValue('videoLink', rowData[5]);

                setSelectedVideo(
                  videosList.find((video) => video.id === rowData[0])
                );
                setIsEdit(true);
              }}
            >
              <EditIcon />
            </Button>

            <Button onClick={() => deleteVideo(rowData[0])}>
              <Close color="error" />
            </Button>
          </div>
        ),
      },
    },
  ];

  const HeaderElementsVideos = () => (
    <Button
      onClick={() => {
        navigate('/new-video');
      }}
    >
      Criar Video <AddIcon />
    </Button>
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <LayoutMUIDataTable
        title="Videos"
        data={videosList}
        columns={columnsVideos}
        customToolbar={<HeaderElementsVideos />}
      />

      <ModalContent
        open={selectedVideo}
        handleClose={() => setSelectedVideo(null)}
      >
        <Grid item xs={12}>
          <Typography variant="h6" align="center" padding={5}>
            {selectedVideo?.title}
          </Typography>
          <ReactPlayer controls url={selectedVideo?.videoLink} />
          <Typography
            variant="body1"
            align="center"
            sx={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              marginTop: 5,
            }}
          >
            {selectedVideo?.description}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={selectedVideo && isEdit}
        handleClose={() => {
          setSelectedVideo(null);
          setIsEdit(false);
        }}
      >
        <Grid item sx={12}>
          <label
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              marginBottom: 25,
            }}
          >
            <img
              src={form.values.image}
              alt="video"
              style={{ width: 400, height: 400, borderRadius: 10 }}
            />
            <input
              placeholder="Carregar imagem"
              type="file"
              accept=".jpeg, .png, .jpg"
              onChange={(e) => {
                const file = e.target.files[0];
                storeAvatarAsBase64(file);
              }}
              style={{ display: 'none' }}
            />
          </label>
          <TextField
            id="title"
            variant="outlined"
            label="Título"
            value={form.values.title}
            onChange={form.handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <TextField
            id="description"
            variant="outlined"
            label="Descrição"
            value={form.values.description}
            onChange={form.handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            style={{ marginTop: 25 }}
          />

          <TextField
            id="link"
            variant="outlined"
            label="Link"
            value={form.values.videoLink}
            onChange={form.handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            style={{ marginTop: 25 }}
          />

          <Grid item xs={12} marginTop={2}>
            <InputLabel>Visibilidade</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Selecione uma visibilidade"
                label="Visibilidade"
                labelId="visibility"
                value={form.values.visibility}
                multiple
                onChange={({ target }) => {
                  form.setFieldValue('visibility', target.value);
                }}
                renderValue={(selectedVisibility) => {
                  return (
                    <Box>
                      {selectedVisibility.map((visibility) => (
                        <Chip
                          key={visibility}
                          label={getConvertMembersType(visibility)}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {visibilities.map((visibility) => (
                  <MenuItem value={visibility} key={visibility}>
                    {getConvertMembersType(visibility)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Button
            style={{ marginTop: 25, alignSelf: 'flex-end' }}
            onClick={form.handleSubmit}
          >
            Salvar Alterações
          </Button>
        </Grid>
      </ModalContent>
    </>
  );
}
