import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { GridMenuIcon } from '@mui/x-data-grid';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import NavTopBar from '../NavTopBar';

export const MainTopbar = ({ mainSidebarVisible, setMainSidebarVisible }) => {
  const { signOut } = useAuth();

  const { pathname } = useLocation();

  const title = () => {
    switch (pathname) {
      case '/professionals-pending':
        return 'Profissionais Pendentes';

      case '/professionals-approved':
        return 'Profissionais Aprovados';

      case '/professionals-disapproved':
        return 'Profissionais Reprovados';

      case '/invoices-verification':
        return 'Notas Fiscais';

      case '/new-article':
        return 'Artigos';

      case '/contents':
        return 'Conteúdos';

      case '/category':
        return 'Categorias';

      case '/clients':
        return 'Clientes';

      case '/clinics':
        return 'Clinicas';

      case '/schedules':
        return 'Agendamentos';

      case '/cancelled-schedules':
        return 'Agendamentos Cancelados';

      case '/profissionals-patients':
        return 'Pacientes';

      case '/profissionals-finances':
        return 'Valores';

      case '/coupons':
        return 'Cupons';

      case '/new-video':
        return 'Vídeo';

      case '/new-course':
        return 'Curso';

      case `/courses/modules/${pathname.split('/')[3]}`:
        return 'Curso > Módulos';

      case '/new-lesson':
        return 'Aula';

      case '/e-mails':
        return 'Emails e SMS Institucionais';

      case '/affiliated_schools':
        return 'Escolas Conveniadas';

      case '/school_members':
        return 'Clientes Conveniados';

      case '/reports':
        return 'Relatórios de Reclamações';

      case '/clinics-professionals':
        return 'Profissionais da Clínica';

      case '/clinics-patients':
        return 'Clientes da Clínica';

      default:
        return 'Não encontrado';
    }
  };

  return (
    <AppBar position="absolute">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMainSidebarVisible(!mainSidebarVisible)}
          edge="start"
        >
          <GridMenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title()}
        </Typography>
        <Button color="inherit" onClick={() => signOut()}>
          Sair
        </Button>
      </Toolbar>
      <NavTopBar />
    </AppBar>
  );
};
