import React from 'react';
import MUIDataTable from 'mui-datatables';

const optionsMUIDataTable = (customToolbar) => {
  return {
    selectableRows: 'none',
    tableBodyHeight: '65vh',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Página Seguinte',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Fazer download de CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Reiniciar',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Ocultar Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
        delete: 'Apagar',
        deleteAria: 'Apagar linhas selecionadas',
      },
    },
    customToolbar: () => customToolbar,
  };
};

export default function LayoutMUIDataTable({
  title,
  data,
  columns,
  customToolbar,
  ...rest
}) {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={optionsMUIDataTable(customToolbar)}
      {...rest}
    />
  );
}
