/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Chip,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import ProfessionalProfile from '../../components/ProfessionalProfile';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { states } from '../../utils/filterOptions';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';
import { getProfessionName } from '../../utils/getProfessionName';
import getSortedDates from '../../utils/getSortedDates';
import getSortedPlans from '../../utils/getSortedPlans';
import getSortedStates from '../../utils/getSortedStates';

export const ProfessionalsDisapproved = () => {
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [professionalProfile, setProfessionalProfile] = useState(null);
  const [selectedProfessionalStatus, setselectedProfessionalStatus] =
    useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const getProfessionals = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          '/professionals/by_status?status=disapproved',
          {
            signal: controller.signal,
          }
        );
        const parsedData = data.data.map((p) => ({
          id: parseInt(p.id),
          ...p.attributes,
        }));
        setProfessionals(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar profissionais! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar profissionais');
      } finally {
        setLoading(false);
      }
    };

    getProfessionals();

    return () => controller.abort();
  }, []);

  const updateProfessionalStatus = async (professionalId, status) => {
    try {
      setLoading(true);
      await api.put(`/professionals/${professionalId}`, {
        data: { attributes: { status: status } },
      });
      setProfessionals(
        professionals.filter((p) => Number(p.id) !== Number(professionalId))
      );
      toast.success('Profissional retornou ao status de pendente.');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao mudar status de profissional! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao mudar status de profissional.');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        filter: false,
        searchable: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <SmallUserAvatar
            src={value ? value : noAvatar}
            icon={
              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: -12,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setSelectedProfessional(
                    professionals.filter((p) => p.id === rowData[1])[0]
                  );
                }}
              >
                <VisibilityIcon />
              </IconButton>
            }
          />
        ),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lastName',
      label: 'Nome',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'firstName',
      label: 'Nome',
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            key={columnMeta.label}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flexWrap: 'nowrap',
            }}
          >
            <IconButton
              color="primary"
              sx={{
                borderRadius: '50%',
              }}
              key={rowData[2]}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setProfessionalProfile(
                  professionals.filter((p) => p.id === rowData[1])[0]
                )
              }
            >
              <LaunchIcon />
            </IconButton>
            {value}
          </Box>
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'profession',
      label: 'Especialidade',
      sortable: false,
      options: {
        filter: false,
        customBodyRender: (value) => getProfessionName(value, 'toPt'),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'subscription',
      label: 'Plano',
      options: {
        sortCompare: (order) => (plan1, plan2) =>
          getSortedPlans(plan1, plan2, order),
        customBodyRender: (value) => getConvertPlanPtBr(value.pagarmePlan),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[8]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      sortable: false,
      options: {
        filterType: 'multiselect',
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        customBodyRender: (value) =>
          value.state ? value.state : 'Não informado',
        filterOptions: states,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Chip
            label={value ? 'Inativo' : 'Ativo'}
            style={{
              backgroundColor: value ? 'red' : 'green',
              color: '#FFFFFF',
              height: 17,
            }}
          />
        ),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onClick={() =>
                setselectedProfessionalStatus(
                  professionals.filter((p) => p.id === rowData[1])[0]
                )
              }
            >
              <Close color="error" />
            </Button>
          </Box>
        ),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Profissionais reprovados"
          data={professionals}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedProfessional}
        handleClose={() => setSelectedProfessional(null)}
      >
        <Grid>
          <div style={{ display: 'flex' }}>
            {selectedProfessional?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedProfessional?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedProfessional?.firstName} {selectedProfessional?.lastName}
            </Typography>
          </div>

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Formação acadêmica
          </Typography>
          {selectedProfessional?.academicBackground &&
            selectedProfessional?.academicBackground.map((ab) => (
              <Typography>* {ab}</Typography>
            ))}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Biografia
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.bio}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Política de cancelamento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.cancelPolicy}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.documentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Telefone
          </Typography>
          <Typography sx={{ mt: 1 }}>{selectedProfessional?.phone}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Video de apresentação
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <a href={selectedProfessional?.presentationVideo}>
              {selectedProfessional?.presentationVideo}
            </a>
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Documento profissional
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {selectedProfessional?.professionalDocumentNumber}
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Preço da sessão
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {Number(selectedProfessional?.sessionPrice).toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
          </Typography>
        </Grid>
      </ModalContent>

      <ModalContent
        open={professionalProfile !== null}
        handleClose={() => setProfessionalProfile(null)}
      >
        <ProfessionalProfile professional={professionalProfile} />
      </ModalContent>

      <ModalAlert
        open={selectedProfessionalStatus}
        handleClose={() => setselectedProfessionalStatus(null)}
        handleSubmit={() => {
          updateProfessionalStatus(selectedProfessionalStatus.id, 'pending');
          setselectedProfessionalStatus(null);
        }}
      >
        <Typography variant="h5">
          Deseja alterar o status para pendente deste profissional?
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
