import * as React from 'react';
import {
  BigContainer,
  CheckInput,
  Container,
  Content,
  StyledInput,
  StyledTextArea,
  StyledTimeField,
  StyledTimeInput,
  Wrapper,
} from './styles';
import { convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const TextInput = ({ ...props }) => {
  return (
    <Container>
      <StyledInput {...props} />
    </Container>
  );
};

export const FixedTextInput = ({ ...props }) => {
  const { label } = props;
  return (
    <Container>
      {label && <label>{label}</label>}
      <StyledInput {...props} />
    </Container>
  );
};

export const OpenedTextInput = ({ ...props }) => {
  const { title, label } = props;
  return (
    <BigContainer>
      {title && <label>{title}</label>}
      <Wrapper>
        {label && <label>{label}</label>}
        <StyledTextArea {...props} />
      </Wrapper>
    </BigContainer>
  );
};

export const TimeInput = ({ ...props }) => {
  const { hour, minutes, minutesName, hourName } = props;
  return (
    <Container>
      <StyledTimeInput
        min={0}
        max={24}
        type="number"
        name={hourName}
        value={hour}
        {...props}
      />
      <label>h</label>
      <StyledTimeInput
        type="number"
        max={60}
        min={0}
        name={minutesName}
        value={minutes}
        {...props}
      />
      <label>min</label>
    </Container>
  );
};

export const CheckBoxInput = ({ ...props }) => {
  const { label, defaultChecked } = props;
  return (
    <Wrapper>
      <CheckInput {...props} type="checkbox" defaultChecked={defaultChecked} />
      <label>{label}</label>
    </Wrapper>
  );
};

export const SimpleTimeInput = ({ ...props }) => {
  return <StyledTimeField {...props} />;
};

export const TimeSelect = ({ ...props }) => {
  const { initial, final } = props;
  return (
    <Content>
      <div className="--time">
        <StyledTimeField initTime={initial} {...props} />
      </div>
      <label className="--signal">-</label>
      <div className="--time">
        <StyledTimeField mountFocus="true" initTime={final} {...props} />
      </div>
    </Content>
  );
};


const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

export class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(content);
    this.state = {
      contentState,
    }
  }
  
  onContentStateChange = (contentState) => {
    this.setState({
      contentState,
    });
    this.props.setEditor(JSON.stringify(contentState, null, 4));
  };

  render() {
    return (
      <div>
        <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onContentStateChange={this.onContentStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
          }}
        />
      </div>
    );
  }
}
