/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Button,
  Chip,
  TableCell,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AddressForm from '../../components/Forms/AddressForm';
import SchoolForm from '../../components/Forms/SchoolForm';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import handleErrors from '../../utils/getHandleErrors';
import { validateCNPJ } from '../../utils/validateCNPJ';

export const SchoolsAffiliated = () => {
  const navigate = useNavigate();
  const [affiliatedSchools, setAffiliatedSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schoolModal, setSchoolModal] = useState(false);
  const [step, setStep] = useState(0);
  const [isRendering, setIsRendering] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [schoolId, setSchoolId] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const getAffiliatedSchools = async () => {
      setLoading(true);
      try {
        const { data } = await api.get('/schools', {
          signal: controller.signal,
        });
        setAffiliatedSchools(data.data);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar escolas conveniadas. Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar escolas conveniadas.');
      } finally {
        setLoading(false);
      }
    };

    getAffiliatedSchools();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    setIsRendering(!isRendering);
  }, [affiliatedSchools]);

  const schema = Yup.object({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: Yup.string()
      .required('Telefone é obrigatorio')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Telefone inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    discountPercentage: Yup.number()
      .min(0, 'O desconto não pode ser menor que 0%')
      .max(20, 'O desconto máximo é de 20%')
      .optional(),
    documentNumber: Yup.string()
      .required('CNPJ é obrigatório')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CNPJ inválido',
        test(value) {
          return validateCNPJ(value || '') || null;
        },
      }),
    educationStages: Yup.array().min(1, 'Selecione ao menos uma opção'),
    cep: Yup.string().required('CEP é obrigatório'),
    street: Yup.string().required('Rua é obrigatório'),
    streetNumber: Yup.string().required('Número é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    country: Yup.string().required('País é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
  });

  const form = useFormik({
    initialValues: {
      id: null,
      name: '',
      email: '',
      phone: '',
      documentNumber: '',
      educationStages: [],
      discountPercentage: 0,
      cep: '',
      addressId: null,
      street: '',
      streetNumber: '',
      neighborhood: '',
      country: '',
      city: '',
      state: '',
      complement: '',
    },
    validationSchema: schema,
  });

  const { errors, values, resetForm } = form;

  const getParsedData = (data) =>
    data.map((e) => ({
      id: parseInt(e.id),
      ...e.attributes,
    }));

  const handleContinue = () => {
    const fieldValues = [
      'name',
      'email',
      'phone',
      'documenNumber',
      'educationStages',
      'discountPercentage',
    ];
    if (!handleErrors(errors, fieldValues)) {
      return setStep(1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fieldValues = [
      'street',
      'streetNumber',
      'neighborhood',
      'city',
      'state',
    ];
    if (!handleErrors(errors, fieldValues)) {
      setSchoolModal(false);
      setLoading(true);
      try {
        const request = {
          data: {
            type: 'school',
            attributes: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              documentNumber: values.documentNumber,
              educationStages: values.educationStages,
              discountPercentage: values.discountPercentage,
              addressAttributes: {
                cep: values.cep,
                street: values.street,
                street_number: values.streetNumber,
                neighborhood: values.neighborhood,
                city: values.city,
                state: values.state,
                country: values.country,
                complement: values.complement,
              },
            },
          },
        };
        const { data } = await api.post('/schools', request);
        setAffiliatedSchools([...affiliatedSchools, data.data]);
        toast.success('Escola cadastrada com sucesso!');
      } catch (error) {
        if (error.response.data.errors[0].title)
          toast.error(
            `Erro ao cadastrar escola! Erro - ${error.response.data.errors[0].title}`
          );
        else
          toast.error(
            'Erro ao cadastrar escola! Revise os dados e tente novamente.'
          );
      } finally {
        resetForm();
        setLoading(false);
        setStep(0);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await api.delete(`/schools/${id}`);
      setAffiliatedSchools(
        affiliatedSchools.filter((school) => Number(school.id) !== id)
      );
      toast.success('Escola deletada com sucesso!');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao deletar escola! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao deletar escola!');
    } finally {
      setLoading(false);
    }
  };

  const renderAffiliatedSchools = getParsedData(affiliatedSchools);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/school_members', {
                state: { id: value },
              });
            }}
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'CNPJ',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientsCount',
      label: 'Alunos',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'colaboratorCount',
      label: 'Colaboradores',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deletedAt',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <Chip
                label="Inativo"
                style={{
                  backgroundColor: 'red',
                  color: '#FFFFFF',
                  height: 17,
                }}
              />
            );
          }
          return (
            <Chip
              label="Ativo"
              style={{
                backgroundColor: 'green',
                color: '#FFFFFF',
                height: 17,
              }}
            />
          );
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  setSchoolId(rowData[0]);
                  setModalAlert(true);
                }}
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
        sortThirdClickReset: true,
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  const HeaderCreateSchool = () => (
    <Button
      onClick={() => {
        setSchoolModal(true);
      }}
    >
      Adicionar Escola <AddIcon />
    </Button>
  );

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Escolas Conveniadas"
          data={renderAffiliatedSchools}
          columns={columns}
          customToolbar={<HeaderCreateSchool />}
        />
      )}

      <ModalContent
        open={schoolModal}
        handleClose={() => setSchoolModal(false)}
      >
        {step === 0 ? (
          <SchoolForm type="Cadastrar" form={form} />
        ) : (
          <AddressForm form={form} />
        )}
        <Grid
          container
          justifyContent={step === 0 ? 'flex-end' : 'space-between'}
          row
          style={{ marginTop: 20 }}
        >
          {step === 0 ? (
            <Button onClick={handleContinue}>Continuar</Button>
          ) : (
            <>
              <Button onClick={() => setStep(0)}>Voltar</Button>
              <Button onClick={handleSubmit}>Salvar</Button>
            </>
          )}
        </Grid>
      </ModalContent>

      <ModalAlert
        handleSubmit={() => handleDelete(schoolId)}
        handleClose={() => setModalAlert(false)}
        open={modalAlert}
      >
        <Typography variant="h5">
          Deseja realmente excluir esta escola?
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
