export const visibilities = [
  'all',
  'all-users',
  'all-members',
  'all-clients',
  'all-professionals',
  'all-platinum',
  'all-free',
  'members-professor',
];
