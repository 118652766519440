import styled from 'styled-components';
import TimeField from 'react-simple-timefield';
export const Container = styled.div`
  width: 400px;
  height: 50px;
  margin: 5px 0;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  label {
    font-size: 14px;
    line-height: 21px;
    padding-right: 10px;
    color: ${({ theme }) => theme.darkGray};
  }
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
`;

export const BigContainer = styled.div`
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

export const StyledInput = styled.input`
  height: 90%;
  flex: 1;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
`;

export const StyledTextArea = styled.textarea`
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  width: 100%;
  border: none;
  font-size: 14px;
  line-height: 21px;
  background-color: transparent;
`;

export const StyledTimeInput = styled.input`
  height: 90%;
  width: 10%;
  padding-left: 2%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
`;

export const CheckInput = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const Content = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.ExtralightGray};
  .--time {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
  .--signal {
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.lightGray};
  }
`;

export const StyledTimeField = styled(TimeField)`
  border: 0;
  display: flex;
  width: 50%;
  background-color: ${({ theme }) => theme.primaryAlt};
`;
