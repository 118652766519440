import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../../../components/Loading';
import defaultImage from '../../../../../images/defaultImage.jpg';
import { AuthorizedLayout } from '../../../../../layouts/AuthorizedLayout';
import api from '../../../../../services/api';
import { getConvertMembersType } from '../../../../../utils/getConvertPlanPtBr';
import { visibilities } from '../../../../../utils/dataOptions';

const style = {
  padding: '20px',
  width: 900,
  margin: '10px auto',
};

export const NewModule = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState({
    title: '',
    image: '',
    visiblity: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setValues({ ...values, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const checkFields = () => {
    if (!values.title) {
      toast.error('Preencha o campo de título!');
      return false;
    }
    if (!values.image) {
      toast.error('Selecione uma imagem!');
      return false;
    }
    return true;
  };

  const createModule = async () => {
    try {
      setLoading(true);
      if (!checkFields()) return;
      const request = {
        data: {
          attributes: {
            title: values.title,
            image: { data: values.image },
            visibility: values.visiblity,
            course_id: id,
          },
        },
      };
      const data = await api.post(`/course_modules`, request);
      if (data.data) {
        setValues({
          title: '',
          image: '',
          visiblity: [],
        });
        toast.success('Módulo criado com sucesso!');
        navigate(-1);
      }
    } catch (error) {
      toast.error('Erro ao criar módulo!');
    } finally {
      setLoading(true);
    }
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <form>
          <Grid container spacing={3} style={style}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">Módulo</Typography>
            </Grid>
            <Grid item xs={12} direction="column" align="center">
              <label style={{ cursor: 'pointer' }}>
                <img
                  src={values.image || defaultImage}
                  alt=""
                  height="250"
                  width="250"
                />
                <input
                  hidden
                  type="file"
                  name="image"
                  id="image"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    storeAvatarAsBase64(file);
                  }}
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="title"
                variant="outlined"
                label="Título do Módulo"
                value={values.title}
                onChange={(e) => {
                  setValues({ ...values, title: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <FormControl fullWidth style={{ marginTop: 25 }}>
                <InputLabel id="visible">Visibilidade</InputLabel>
                <Select
                  labelId="visible"
                  id="visible"
                  multiple
                  value={values.visiblity}
                  onChange={(e) => {
                    setValues({ ...values, visiblity: e.target.value });
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={getConvertMembersType(value)}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {visibilities.map((name) => (
                    <MenuItem key={name} value={name}>
                      {getConvertMembersType(name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button onClick={() => createModule()}>Criar Módulo</Button>
            </Grid>
          </Grid>
        </form>
      )}
    </AuthorizedLayout>
  );
};
