export const getProfessionName = (value, targetLang) => {
  if (targetLang === 'toPt') {
    switch (value?.toLowerCase()) {
      case 'psychologist':
        return 'Psicólogo';
      case 'psychiatrist':
        return 'Psiquiatra';
      case 'psychopedagogue':
        return 'Psicopedagogo';
      case 'neuropediatrician':
        return 'Neuropediatra';
      case 'professor':
        return 'Professor';
      case 'professional_advisor':
        return 'Orientador Profissional';
      default:
        break;
    }
  } else if (targetLang === 'toEng') {
    switch (value?.toLowerCase()) {
      case 'psicólogo':
        return 'psychologist';
      case 'psiquiatra':
        return 'psychiatrist';
      case 'psicopedagogo':
        return 'psychopedagogue';
      case 'neuropediatra':
        return 'neuropediatrician';
      case 'professor':
        return 'professor';
      case 'orientador profissional':
        return 'professional_advisor';
      default:
        break;
    }
  }
};
