import { Box, Button, Grid, Modal, IconButton } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function ModalAlert({ ...props }) {
  const { children, handleClose, handleSubmit, open } = props;
  return (
    <Modal open={open}>
      <Box
        sx={{
          boxShadow: 24,
          border: '3px solid #ffb74d',
          bgcolor: 'background.paper',
          borderRadius: 5,
          left: '50%',
          maxHeight: '80%',
          maxWidth: '40%',
          overflow: 'auto',
          p: 4,
          position: 'relative',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <IconButton style={{ marginRight: '1rem' }}>
            <WarningAmberIcon color="warning" sx={{ fontSize: '3rem' }} />
          </IconButton>
          {children}
        </Grid>

        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-around"
          style={{ marginTop: '1rem' }}
        >
          <Button
            sx={{ m: 1 }}
            onClick={handleClose}
            variant="outlined"
            color="error"
            size="large"
          >
            Não
          </Button>
          <Button
            sx={{ m: 1 }}
            onClick={() => {
              handleSubmit();
              handleClose(false);
            }}
            variant="contained"
            size="large"
          >
            Sim
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
