import { Box, Grid, Typography } from '@mui/material';
import FormikControl from '../FomikControl';
import { Item } from '../../styles';

export default function BasicDataForm({ ...props }) {
  const { form, ...rest } = props;
  const { values, errors, handleChange, handleBlur } = form;
  return (
    <Grid
      alignItems="flex-start"
      container
      direction="column"
      justifyContent="flex-start"
      style={{ width: '100%' }}
      {...rest}
    >
      <Typography
        variant="h5"
        sx={{ mb: 1, textAlign: 'center', width: '100%' }}
      >
        Informações Pessoais
      </Typography>
      <Box sx={{ flexGrow: 1, pt: 5, width: '100%' }}>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.firstName}
                fullWidth
                id="firstName"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                errors={errors.lastName}
                fullWidth
                id="lastName"
                label="Sobrenome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="input"
                disabled
                fullWidth
                id="email"
                label="E-mail"
                value={values.email}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="inputMask"
                errors={errors.phone}
                fullWidth
                id="phone"
                label="Telefone"
                mask="(99) 99999-9999"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                variant="standard"
                width="100%"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="inputMask"
                alignItems={'flex-end'}
                errors={errors.birthday}
                fullWidth
                id="birthday"
                label="Data de nascimento"
                mask="99/99/9999"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.birthday}
                variant="standard"
                width="100%"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <FormikControl
                control="inputMask"
                disabled
                errors={errors.documentNumber}
                fullWidth
                id="documentNumber"
                label="CPF"
                mask="999.999.999-99"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.documentNumber}
                variant="standard"
                width="100%"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
