/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SelectSingle({ ...props }) {
  const {
    alignItems,
    defaultValue,
    errors,
    justifyContent,
    label,
    name,
    options,
    width,
    ...rest
  } = props;

  const styles = {
    alignItems: alignItems || 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent || 'center',
    width: width,
  };

  return (
    <Box style={{ ...styles }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          name={name}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          {...rest}
        >
          {options?.map((item) => (
            <MenuItem value={item.id}>{item.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function SelectMultiple({ ...props }) {
  const {
    alignItems,
    defaultValue,
    errors,
    onChange,
    justifyContent,
    label,
    name,
    options,
    width,
    ...rest
  } = props;

  const styles = {
    alignItems: alignItems || 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent || 'center',
    width: width,
  };

  const theme = useTheme();

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const [personName, setPersonName] = useState(defaultValue || []);

  const handleChangeItem = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    onChange(name, personName);
  }, [personName]);

  return (
    <Box style={{ ...styles }}>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChangeItem}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip key={`${value}-${index}`} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          {...rest}
        >
          {options?.map((option, index) => (
            <MenuItem
              key={`${option}-${index}`}
              value={option}
              style={getStyles(name, personName, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
