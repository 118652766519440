/* eslint-disable react-hooks/exhaustive-deps */
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Button,
  Grid,
  TableCell,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';
import getPaymentName from '../../utils/getPaymentName';
import getSortedDates from '../../utils/getSortedDates';

export const ScheduleList = () => {
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [loading, setLoading] = useState(true);

  const getStatus = (date, time, review) => {
    const dateTime = new Date(
      `${date.getMonth() + 1} ${
        date.getDate() + 1
      } ${date.getFullYear()} ${time}`
    );
    if (review) {
      return 'Avaliado';
    } else if (Date.now() >= dateTime) {
      return 'Concluído';
    } else if (Date.now() < dateTime) {
      return 'Agendado';
    } else {
      return 'N/A';
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getSchedules = async () => {
      try {
        setLoading(true);
        //const { data } = await axios.get('https://mocki.io/v1/ca851065-a822-4203-9a57-9272ad6bb670'); //mock api
        const { data } = await api.get('/schedules', {
          signal: controller.signal,
        });

        const parsedData = data.data.map((s) => ({
          id: parseInt(s.id),
          ...s.attributes,
          clientFirstName: s.attributes.client.firstName,
          clientLastName: s.attributes.client.last_name,
          clientFullName: `${s.attributes.client.firstName} ${s.attributes.client.lastName}`,
          clientEmail: s.attributes.client.email,
          professionalFirstName: s.attributes.professional.firstName,
          professionalLastName: s.attributes.professional.lastName,
          professionalFullName: `${s.attributes.professional.firstName} ${s.attributes.professional.lastName}`,
          professionalEmail: s.attributes.professional.email,
          appointmentType:
            s.attributes.appointmentType === 'in_person'
              ? 'Presencial'
              : 'Online',
          status: getStatus(
            new Date(s.attributes.startDate),
            s.attributes.startTime,
            s.attributes.reviewId
          ),
          professionType: s.attributes.professional.profession,
          tPlan: getConvertPlanPtBr(s.attributes.professional.pagarmePlan),
        }));

        setSchedules(parsedData);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar agendamentos. Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar agendamentos.');
      } finally {
        setLoading(false);
      }
    };

    getSchedules();

    return () => controller.abort();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, updateDirection, sortOrder) => (
          <TableCell
            align="center"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            <TableSortLabel
              active={['asc', 'desc'].includes(sortOrder?.direction)}
              onClick={() => {
                updateDirection(0);
              }}
              style={{ cursor: 'pointer' }}
              direction={sortOrder?.direction}
            >
              {columnMeta.label}
            </TableSortLabel>
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const schedule = schedules.find((s) => s.id === value);
              setSelectedSchedule(schedule);
            }}
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {value}
          </Button>
        ),
        setCellProps: () => ({ align: 'center' }),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientFullName',
      label: 'Nome do Cliente',
      options: {
        display: 'excluded',
        filterType: 'textField',
      },
    },
    {
      name: 'clientLastName',
      label: 'Sobrenome do Cliente',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'clientFirstName',
      label: 'Nome do Cliente',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'clientEmail',
      label: 'Email do Cliente',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionalFullName',
      label: 'Nome do Profissional',
      options: {
        display: 'excluded',
        filterType: 'textField',
      },
    },
    {
      name: 'professionalLastName',
      label: 'Sobrenome do Profissional',
      options: {
        display: 'excluded',
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionalFirstName',
      label: 'Nome do Profissional',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionalEmail',
      label: 'Email do Profissional',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionType',
      label: 'Tipo de Profissional',
      options: {
        display: 'excluded',
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tPlan',
      label: 'Plano do Profissional',
      options: {
        display: 'excluded',
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'startDate',
      label: 'Data Agendada',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[11]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'startTime',
      label: 'Hora Agendada',
      options: {
        filterType: 'textField',
        customBodyRender: (value) =>
          value ? moment(value, 'HH:mm:ss').format('HH:mm') : '',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'professionType',
      label: 'Tipo de Profissional',
      options: {
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'appointmentType',
      label: 'Tipo de Agendamento',
      options: {
        filterType: 'multiselect',
        searchable: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Situação',
      options: {
        filterType: 'multiselect',
        searchable: false,
        sortThirdClickReset: true,
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Agendamentos"
          data={schedules}
          columns={columns}
        />
      )}

      <ModalContent
        open={selectedSchedule}
        handleClose={() => setSelectedSchedule(null)}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              Detalhes do agendamento
            </Typography>
          </div>
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Cliente
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.clientFullName || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Profissional
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.professionalFullName || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Valor pago
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {Number(selectedSchedule?.paymentAmount).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }) || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Forma de pagamento
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.payment.paymentMethod || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Plano do profissional
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.professional.tPlan || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Data da sessão
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {moment(selectedSchedule?.startDate).format('DD/MM/YYYY') ||
                'Não informado'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Tipo de profissional
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.professionType || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Tipo da sessão
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.appointmentType === 'in_person'
                ? 'Presencial'
                : 'Online' || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Data de pagamento
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {moment(selectedSchedule?.payment.createdAt).format(
                'DD/MM/YYYY'
              ) || 'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Status do pagamento
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {getPaymentName(selectedSchedule?.payment.paymentStatus)}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Hora agendada
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {moment(selectedSchedule?.payment.createdAt).format('HH:mm') ||
                'Não informado'}
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Status do agendamento
            </Typography>
            <Typography variant="h7" sx={{ mt: 2 }}>
              {selectedSchedule?.status || 'Não informado'}
            </Typography>
          </Grid>
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
