/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Container,
  Grid,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import AddressForm from '../../components/Forms/AddressForm';
import SchoolForm from '../../components/Forms/SchoolForm';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import { SmallUserAvatar } from '../../components/UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import { ages, states } from '../../utils/filterOptions';
import handleErrors from '../../utils/getHandleErrors';
import getLinkMembers from '../../utils/getLinkMembers';
import getSortedDates from '../../utils/getSortedDates';
import getSortedStates from '../../utils/getSortedStates';
import { validateCNPJ } from '../../utils/validateCNPJ';

export const SchoolMembersList = () => {
  const {
    state: { id },
  } = useLocation();
  const [school, setSchool] = useState({});
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [schoolModal, setSchoolModal] = useState(false);
  const [step, setStep] = useState(0);
  const [copyLink, setCopyLink] = useState({
    btnClient: false,
    btnColaborators: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const getSchool = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/schools/${id}`, {
          signal: controller.signal,
        });
        setSchool({ ...data.data.attributes, id });
        setMembers(data.data.attributes.clients);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao buscar escola! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao buscar escola');
      } finally {
        setLoading(false);
      }
    };

    getSchool();

    return () => controller.abort();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: Yup.string().required('Telefone é obrigatório'),
    documentNumber: Yup.string()
      .required('CNPJ é obrigatório')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CNPJ inválido',
        test(value) {
          return validateCNPJ(value || '') || null;
        },
      }),
    discountPercentage: Yup.number()
      .min(0, 'O desconto não pode ser menor que 0%')
      .max(20, 'O desconto máximo é de 20%')
      .optional(),
    educationStages: Yup.array().min(1, 'Selecione ao menos uma opção'),
    cep: Yup.string().required('CEP é obrigatório'),
    street: Yup.string().required('Rua é obrigatório'),
    streetNumber: Yup.string().required('Número é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    country: Yup.string().required('País é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
  });

  const form = useFormik({
    initialValues: {
      id: null,
      name: '',
      email: '',
      phone: '',
      documentNumber: '',
      discountPercentage: 0,
      educationStages: [],
      cep: '',
      address: {},
      addressId: null,
      street: '',
      streetNumber: '',
      neighborhood: '',
      country: '',
      city: '',
      state: '',
      complement: '',
    },
    validationSchema: validationSchema,
  });

  const { errors, setValues, values, setFieldValue } = form;

  const handleEdit = () => {
    setValues({
      ...school.address,
      ...school,
      addressId: school.address.id,
      streetNumber: school.address.street_number || school.address.streetNumber,
      discountPercentage: school.discountPercentage || 0,
    });
  };

  const resetFields = () => {
    setFieldValue('name', '');
    setFieldValue('email', '');
    setFieldValue('phone', '');
    setFieldValue('documentNumber', '');
    setFieldValue('educationStages', []);
    setFieldValue('cep', '');
    setFieldValue('street', '');
    setFieldValue('streetNumber', '');
    setFieldValue('neighborhood', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
    setFieldValue('complement', '');
  };

  const handleContinue = () => {
    const fieldValues = [
      'name',
      'email',
      'phone',
      'documenNumber',
      'educationStages',
    ];
    if (!handleErrors(errors, fieldValues)) {
      return setStep(1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSchoolModal(false);
    const fieldValues = [
      'street',
      'streetNumber',
      'neighborhood',
      'city',
      'state',
    ];
    if (!handleErrors(errors, fieldValues)) {
      setLoading(true);
      const address = {
        id: values.addressId,
        cep: values.cep,
        street: values.street,
        streetNumber: values.streetNumber,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        country: values.country,
        complement: values.complement,
      };
      try {
        const request = {
          data: {
            type: 'school',
            attributes: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              documentNumber: values.documentNumber,
              discountPercentage: values.discountPercentage,
              educationStages: values.educationStages,
              addressAttributes: address,
            },
          },
        };
        const { data } = await api.put(`/schools/${id}`, request);
        setSchool({ id, ...data.data.attributes, address });
        toast.success('Informações editada com sucesso!');
      } catch (error) {
        toast.error(
          'Erro ao editar escola! Revise os dados e tente novamente.'
        );
      } finally {
        resetFields();
        setLoading(false);
        setStep(0);
      }
    }
  };

  const getParsedData = (data) =>
    data.map((s) => ({
      ...s,
      id: parseInt(s.id),
      phone: s.phone ? s.phone : 'Não informado',
      documentNumber: s.documentNumber ? s.documentNumber : 'Não informado',
      age: s.age ? s.age : 'Não informado',
      schoolType: s.schoolType === 'colaborator' ? 'Colaborador' : 'Estudante',
      recurrent: s.recurrent ? 'Sim' : 'Não',
      createdAt: s.createdAt,
      address: s.address ? s.address : 'Não informado',
    }));

  const getParsedSchool = (data) => {
    return {
      ...data,
      id: data.id,
      phone: data.phone ? data.phone : 'Não informado',
      documentNumber: data.documentNumber
        ? data.documentNumber
        : 'Não informado',
      age: data.birthday ? moment().subtract(data.birthday) : 'Não informado',
      tSchoolType: data.schoolType === 'client' ? 'Aluno' : 'Professor',
      recurrent: data.recurrent ? 'Sim' : 'Não',
      createdAt: data.createdAt ? data.createdAt : 'Não informado',
      address: data.address ? '' : 'Não informado',
    };
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await api.patch(`/clients/${id}/remove_school`);
      const newMembers = members.filter((member) => Number(member.id) !== id);
      setMembers(newMembers);
      toast.success('Conveniado removido com sucesso');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao remover conveniado da escola! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error('Erro ao remover conveniado da escola');
    } finally {
      setLoading(false);
    }
  };

  const renderSchool = getParsedData(members);

  const columns = [
    {
      name: 'avatar',
      label: 'Avatar',
      width: 90,
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (value, { rowData }) => {
          if (value === null) {
            return (
              <div
                onClick={() => {
                  const schoolMember = members.find(
                    (member) => Number(member.id) === rowData[1]
                  );
                  setSelectedMembers(getParsedSchool(schoolMember));
                }}
                style={{ cursor: 'pointer' }}
              >
                <SmallUserAvatar src={noAvatar} />
              </div>
            );
          } else {
            return (
              <div
                onClick={() => {
                  const schoolMember = members.find(
                    (member) => Number(member.id) === rowData[1]
                  );
                  setSelectedMembers(getParsedSchool(schoolMember));
                }}
                style={{ cursor: 'pointer' }}
              >
                <SmallUserAvatar src={value} />
              </div>
            );
          }
        },
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fullName',
      label: 'Aluno',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'documentNumber',
      label: 'Documento',
      options: {
        display: false,
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'age',
      label: 'Idade',
      options: {
        filterType: 'multiselect',
        searchable: false,
        filterOptions: ages,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'schoolType',
      label: 'Tipo de Conveniado',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'recurrent',
      label: 'Recorrente',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Cadastro',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[13]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Estado',
      options: {
        filterType: 'multiselect',
        searchable: false,
        filterOptions: states,
        sortCompare: (order) => (state1, state2) =>
          getSortedStates(state1, state2, order),
        customBodyRender: (value) =>
          value.state ? value.state : 'Não informado',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'actions',
      label: 'Remover Convenio',
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  setModalAlert(true);
                  setMemberId(rowData[1]);
                }}
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
        setCellHeaderProps: () => ({ align: 'left' }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container
            sx={{
              borderRadius: '4px',
              boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2),
                  0px 4px 5px 0px rgba(0,0,0,0.14),
                  0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
              marginBottom: '1rem',
              paddingBottom: '1rem',
              paddingTop: '0.6rem',
              minWidth: '100%',
            }}
          >
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant="h5" sx={{ mr: 2, alignItems: 'center' }}>
                  {school?.name}
                </Typography>
                <Button
                  onClick={() => {
                    setSchoolModal(true);
                    handleEdit();
                  }}
                >
                  <EditIcon />
                </Button>
              </div>
              <Tooltip title="Copiar link de convite para inclusão de clientes conveniados.">
                <Button
                  sx={{ height: '1.7rem', width: 'fit-content' }}
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    const link = getLinkMembers(school?.name, school?.id);
                    try {
                      await navigator.clipboard.writeText(link);
                      toast.success(
                        'Link do cliente copiado para área de transferência'
                      );
                      setCopyLink({
                        ...copyLink,
                        btnClient: true,
                      });
                    } catch (error) {
                      toast.error('Erro ao copiar link');
                      setCopyLink({
                        ...copyLink,
                        btnClient: false,
                      });
                    }
                  }}
                >
                  {copyLink.btnClient ? (
                    <>
                      <span>link copiado</span>
                      <CheckIcon sx={{ ml: 1 }} />
                    </>
                  ) : (
                    <>
                      <span>gerar link para o cliente</span>
                      <AddLinkIcon sx={{ ml: 1 }} />
                    </>
                  )}
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              container
              spacing={2}
              lg={12}
            >
              <Grid item>
                <Paragraph>
                  <SpanText>CNPJ:</SpanText>
                  {school?.documentNumber}
                </Paragraph>
                <Paragraph>
                  <SpanText>E-mail:</SpanText>
                  {school?.email}
                </Paragraph>
                <Paragraph>
                  <SpanText>Telefone:</SpanText>
                  {school?.phone}
                </Paragraph>
                <Paragraph>
                  <SpanText>Escolaridade:</SpanText>
                  {school?.educationStages?.toString().split(',').join(', ')}
                </Paragraph>
              </Grid>
              <Grid item>
                <Paragraph>
                  <SpanText>Número de Clientes:</SpanText>
                  {school?.clientsCount}
                </Paragraph>
                <Paragraph>
                  <SpanText>Rua:</SpanText>
                  {school?.address?.street}
                  <SpanText style={{ marginLeft: '0.5rem' }}>N.</SpanText>
                  {school?.address?.street_number ||
                    school?.address?.streetNumber}
                </Paragraph>
                <Paragraph>
                  <SpanText>Bairro:</SpanText>
                  {school?.address?.neighborhood}
                </Paragraph>
                <Paragraph>
                  <SpanText>Complemento:</SpanText>
                  {school?.address?.complement}
                </Paragraph>
              </Grid>
              <Grid item>
                <Paragraph>
                  <SpanText>Cidade:</SpanText>
                  {school?.address?.city}
                </Paragraph>
                <Paragraph>
                  <SpanText>Estado:</SpanText>
                  {school?.address?.state}
                </Paragraph>
                <Paragraph>
                  <SpanText>País:</SpanText>
                  {school?.address?.country}
                </Paragraph>
              </Grid>
            </Grid>
          </Container>

          <LayoutMUIDataTable
            sx={{ margin: 0, padding: 0 }}
            title="Clientes Conveniados"
            data={renderSchool}
            columns={columns}
          />
        </>
      )}

      <FormikProvider value={form}>
        <ModalContent
          open={selectedMembers}
          handleClose={() => setSelectedMembers(false)}
        >
          <div style={{ display: 'flex' }}>
            {selectedMembers?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={selectedMembers?.avatar} />
            )}

            <Typography variant="h4" sx={{ ml: 2 }}>
              {selectedMembers?.fullName}
            </Typography>
          </div>
          <Grid
            sx={{
              justifyContent: 'space-between',
              p: 2,
            }}
            container
            row
          >
            <Grid>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Informações Pessoais
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Data de Nascimento
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedMembers?.birthday
                  ? moment(selectedMembers?.birthday).format('DD/MM/YYYY')
                  : 'Não informado'}
              </Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Email
              </Typography>
              <Typography sx={{ mt: 1 }}>{selectedMembers?.email}</Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Telefone
              </Typography>
              <Typography sx={{ mt: 1 }}>{selectedMembers?.phone}</Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>
                Documento
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedMembers?.documentNumber}
              </Typography>

              <Typography variant="h5" sx={{ mt: 2 }}>
                Endereço
              </Typography>
              {selectedMembers?.address !== 'Não informado' ? (
                <Typography sx={{ mt: 1 }}>{`${
                  selectedMembers?.address?.street
                    ? selectedMembers?.address?.street + ', '
                    : ''
                }${
                  selectedMembers?.address?.street_number
                    ? selectedMembers?.address?.complement &&
                      selectedMembers?.address?.complement !== 'N/A'
                      ? selectedMembers?.address?.street_number + ' '
                      : selectedMembers?.address?.street_number + ', '
                    : ''
                }${
                  selectedMembers?.address?.complement &&
                  selectedMembers?.address?.complement !== 'N/A'
                    ? selectedMembers?.address?.complement + ', '
                    : ''
                }${
                  selectedMembers?.address?.neighborhood
                    ? selectedMembers?.address?.neighborhood + ', '
                    : ''
                }${
                  selectedMembers?.address?.city
                    ? selectedMembers?.address?.city + ', '
                    : ''
                }${
                  selectedMembers?.address?.state
                    ? selectedMembers?.address?.state + ', '
                    : ''
                }${
                  selectedMembers?.address?.country
                    ? selectedMembers?.address?.country + ', '
                    : ''
                }${
                  selectedMembers?.address?.cep
                    ? selectedMembers?.address?.cep + '.'
                    : ''
                }`}</Typography>
              ) : (
                <Typography sx={{ mt: 1 }}>
                  {selectedMembers?.address}
                </Typography>
              )}

              <Typography variant="h6" sx={{ mt: 2 }}>
                Conta criada em:
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {selectedMembers?.createdAt
                  ? moment(selectedMembers?.createdAt).format('DD/MM/YYYY')
                  : 'Não foi possível recuperar'}
              </Typography>
            </Grid>
          </Grid>
        </ModalContent>

        <ModalContent
          open={schoolModal}
          handleClose={() => setSchoolModal(false)}
        >
          {step === 0 ? (
            <SchoolForm type="Editar" form={form} />
          ) : (
            <AddressForm form={form} />
          )}
          <Grid
            container
            justifyContent={step === 0 ? 'flex-end' : 'space-between'}
            row
            style={{ marginTop: 20 }}
          >
            {step === 0 ? (
              <Button onClick={handleContinue}>Continuar</Button>
            ) : (
              <>
                <Button onClick={() => setStep(0)}>Voltar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
              </>
            )}
          </Grid>
        </ModalContent>

        <ModalAlert
          handleClose={() => setModalAlert(false)}
          handleSubmit={() => handleDelete(memberId)}
          open={modalAlert}
        >
          <Typography variant="h5">
            Deseja realmente remover o convenio desse usuário?
          </Typography>
        </ModalAlert>
      </FormikProvider>
    </AuthorizedLayout>
  );
};

const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`;

const SpanText = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
`;
