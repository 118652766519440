import { Box, Grid, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ptBR } from '@mui/x-date-pickers/locales';
import moment from 'moment';
import 'moment/locale/pt-br';
import ReactInputMask from 'react-input-mask';
import { TextError } from '../../../styles';

export function InputText({ ...props }) {
  const { label, name, errors, width, alignItems, justifyContent, ...rest } =
    props;

  const styles = {
    alignItems: alignItems || 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent || 'center',
    width: width || '100%',
  };

  return (
    <Box style={{ ...styles }}>
      <TextField sx={{ p: 0 }} id={name} label={label} {...rest} />
      <TextError>{errors}</TextError>
    </Box>
  );
}

export function InputMask({ ...props }) {
  const { label, name, errors, width, alignItems, justifyContent, ...rest } =
    props;

  const styles = {
    alignItems: alignItems || 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent || 'center',
    width: width,
  };

  return (
    <Box style={{ ...styles }}>
      <ReactInputMask
        maskPlaceholder={null}
        name={name}
        alwaysShowMask={false}
        sx={{
          ...rest.sx,
          width: width,
        }}
        {...rest}
      >
        <TextField
          sx={{ p: 0 }}
          variant="standard"
          label={label}
          fullWidth={rest?.fullWidth}
        />
      </ReactInputMask>
      <TextError style={{ width: width }}>{errors}</TextError>
    </Box>
  );
}

export function InputDate({ ...props }) {
  const { label, value, ...rest } = props;

  const styles = {
    width: rest?.fullWidth && '100%',
  };

  return (
    <Grid style={{ ...styles }} columns={1}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="pt-br"
        dateLibInstance={moment.utc}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DemoContainer components={['DatePicker']}>
          <DesktopDatePicker
            label={label}
            localeText={
              ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            }
            value={moment(value)}
            views={['year', 'month', 'day']}
            {...rest}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Grid>
  );
}
