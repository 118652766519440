import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  Modal,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import LayoutMUIDataTable from '../../../layouts/LayoutMUIDataTable';
import api from '../../../services/api';

export const ContentTags = () => {
  const [tagsList, setTagsList] = useState([]);
  const [tag, setTag] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(true);
  const [tagsModal, setTagsModal] = useState(false);

  useEffect(() => {
    getTags();
  }, []);

  const createTag = async () => {
    if (!(tag.name === '')) {
      createTags();
      setTagsModal(false);
      setTag({ name: '' });
    } else {
      toast.error('Título é obrigatório!');
    }
  };

  const getTags = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/tags`);

      if (data.data) {
        const parseData = data.data.map((p) => ({
          ...p.attributes,
          id: parseInt(p.id),
        }));
        setTagsList(parseData);
        setLoading(false);
      } else {
        throw new Error('Erro ao carregar categorias!');
      }
    } catch (error) {
      toast.error('Erro ao carregar categorias!');
      setLoading(false);
    }
  };

  const deleteTags = async (Tag) => {
    setLoading(true);
    try {
      const { data } = await api.delete(`/tags/${Tag.id}`);

      if (data.data) {
        setTagsList(tagsList.filter((item) => item.id !== Tag.id));
        setLoading(false);
      } else {
        throw new Error('Erro ao apagar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao apagar categoria!');
      setLoading(false);
    }
  };

  const createTags = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(`/tags`, {
        data: {
          attributes: {
            name: tag.name,
          },
        },
      });

      if (data.data) {
        setTagsList([
          ...tagsList,
          { ...data.data.attributes, id: data.data.id },
        ]);
        setLoading(false);
      } else {
        throw new Error('Erro ao criar categoria!');
      }
    } catch (error) {
      toast.error('Erro ao criar categoria!');
      setLoading(false);
    }
  };

  const columnsTags = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  deleteTags(tagsList.filter((p) => p.id === rowData[1])[0]);
                }}
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const HeaderElementsTags = () => (
    <Button
      onClick={() => {
        setTagsModal(true);
      }}
    >
      Criar Tag <AddIcon />
    </Button>
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <LayoutMUIDataTable
        title="Tags"
        data={tagsList}
        columns={columnsTags}
        customToolbar={<HeaderElementsTags />}
      />
      <Modal
        open={tagsModal}
        onClose={() => {
          setTagsModal(false);
          setTag({ name: '' });
        }}
      >
        <Box
          sx={{
            boxShadow: 24,
            bgcolor: 'background.paper',
            borderRadius: 10,
            left: '50%',
            maxHeight: '80%',
            maxWidth: '70%',
            minWidth: '400px',
            overflow: 'auto',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        >
          <Typography variant="h6" sx={{ m: 1, mt: 2 }}>
            {' '}
            Criar tag nova{' '}
          </Typography>
          <TextField
            sx={{ m: 1 }}
            id="name"
            variant="standard"
            label="Nome"
            value={tag.name}
            onChange={(e) => {
              setTag({ name: e.target.value });
            }}
            fullWidth
          />
          <Grid
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            container
          >
            <Button
              sx={{ m: 1 }}
              onClick={() => {
                setTagsModal(false);
                setTag({ name: '' });
              }}
              size="small"
              variant="text"
            >
              Cancelar
            </Button>
            <Button
              sx={{ m: 1 }}
              onClick={() => {
                createTag();
              }}
              size="small"
              variant="contained"
            >
              Criar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
