import axios from 'axios';

export const baseURL = () => {
  switch (process.env.REACT_APP_HOST) {
    case 'development':
      return process.env.REACT_APP_DEV_URL;
    case 'development.local':
      return process.env.REACT_APP_DEV_LOCAL_URL;
    default:
      return process.env.REACT_APP_PRODUCTION_URL;
  }
};

const api = axios.create({
  baseURL: baseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const viaCepApi = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
});

export default api;
