import { Box, Grid, Typography } from '@mui/material';
import { SmallUserAvatar } from '../UserAvatar';
import noAvatar from '../../images/noAvatar.png';
import { ItemProfile, Item } from '../../styles';

export default function ProfessionalProfile({ ...props }) {
  const { professional } = props;

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <Grid
        sx={(theme) => ({
          [theme.breakpoints.up('xs')]: {
            p: 0,
            pb: 2,
            pt: 2,
          },
          [theme.breakpoints.up('md')]: {
            p: 2,
          },
        })}
        container
        spacing={3}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'column',
              p: 3,
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          })}
        >
          <Item sx={{ mr: 1 }}>
            {professional?.avatar === null ? (
              <SmallUserAvatar src={noAvatar} />
            ) : (
              <SmallUserAvatar src={professional?.avatar} />
            )}
          </Item>
          <Item>
            <Typography variant="h4">
              {professional?.firstName} {professional?.lastName}
            </Typography>
          </Item>
        </Box>

        <Typography
          sx={{
            pb: 2,
            pt: 3,
            textAlign: 'center',
            textDecoration: 'underline',
            width: '100%',
          }}
          variant="h5"
        >
          Informações Pessoais
        </Typography>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Nome Social</Typography>
            <Typography>{professional?.socialName || 'Não possui'}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Data de Nascimento</Typography>
            <Typography>
              {new Date(professional?.birthday).toLocaleDateString('pt-br') ||
                'Não informado'}
            </Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Documento</Typography>
            <Typography>{professional?.documentNumber}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Video de apresentação</Typography>
            <Typography>
              <a
                href={professional?.presentationVideo}
                target="_blank"
                rel="noreferrer"
              >
                Youtube
              </a>
            </Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Biografia</Typography>
            <Typography>{professional?.bio}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">E-mail</Typography>
            <Typography>{professional?.email}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Telefone</Typography>
            <Typography>{professional?.phone}</Typography>
          </ItemProfile>
        </Grid>

        <Typography
          sx={{
            pb: 2,
            pt: 3,
            textAlign: 'center',
            textDecoration: 'underline',
            width: '100%',
          }}
          variant="h5"
        >
          Endereço
        </Typography>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">CEP</Typography>
            <Typography>{professional?.address?.cep}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Rua</Typography>
            <Typography>
              {professional?.address?.street ||
                professional?.address?.streetNumber}
            </Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Bairro</Typography>
            <Typography>{professional?.address?.neighborhood}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Cidade</Typography>
            <Typography>{professional?.address?.city}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Estado</Typography>
            <Typography>{professional?.address?.state}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Pais</Typography>
            <Typography>{professional?.address?.country}</Typography>
          </ItemProfile>
        </Grid>

        <Typography
          sx={{
            pb: 2,
            pt: 3,
            textAlign: 'center',
            textDecoration: 'underline',
            width: '100%',
          }}
          variant="h5"
        >
          Informações Profissionais
        </Typography>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Profissão</Typography>
            <Typography>{professional?.profession}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Modalidade de trabalho</Typography>
            {professional?.availabilityRule?.appointmentTypes?.map(
              (item, index) => (
                <Typography key={`${item}-${index}`}>
                  {item === 'online' ? 'Online' : 'Presencial'}
                </Typography>
              )
            )}
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Especialidades</Typography>
            {professional?.specialities?.map((ab) => (
              <Typography key={ab}>{ab}</Typography>
            ))}
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Motivos</Typography>
            {professional?.reasons?.map((ab) => (
              <Typography key={ab}>{ab}</Typography>
            ))}
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Grupos de atendimentos</Typography>
            {professional?.ageGroups?.map((ab) => (
              <Typography key={ab}>{ab}</Typography>
            ))}
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Formação acadêmica</Typography>
            {professional?.academicBackground?.map((ab) => (
              <Typography key={ab}>{ab}</Typography>
            ))}
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Anos de experiência</Typography>
            <Typography>{professional?.yearsOfExperience}</Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Documento profissional</Typography>
            <Typography>
              {professional?.professionalDocumentFile ? (
                <a
                  href={professional.professionalDocumentFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  Abrir documento
                </a>
              ) : professional?.professionalDocumentNumber ? (
                professional?.professionalDocumentNumber
              ) : (
                ''
              )}
            </Typography>
          </ItemProfile>
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemProfile>
            <Typography variant="h6">Preço da sessão</Typography>
            <Typography>
              {Number(professional?.sessionPrice).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </ItemProfile>
        </Grid>
      </Grid>
    </Box>
  );
}
