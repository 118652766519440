export default function getLinkMembers(name, id) {
  const parseName = name.replace(/ /g, '%20');
  const environment = process.env.REACT_APP_HOST;
  switch (environment) {
    case 'development':
      return `https://mind-client-development.herokuapp.com/cliente/cadastre-se/sign-up?schoolName=${parseName}&schoolId=${id}`;
    case 'development.local':
      return `http://localhost:4200/cliente/cadastre-se/sign-up?schoolName=${parseName}&schoolId=${id}`;
    default:
      return `https://web.allminds.app/cliente/cadastre-se/sign-up?schoolName=${parseName}&schoolId=${id}`;
  }
}
