export const validateInfoRequired = [
  'firstName',
  'lastName',
  'email',
  'documentNumber',
  'birthday',
  'phone',
  'worksOnline',
  'worksInPerson',
  'sessioPrice',
  'academicBackground',
  'professionalDocumentNumber',
  'specialities',
  'term',
];

export const serializeInfoRequired = (info) => {
  switch (info) {
    case 'firstName':
      return 'Nome';
    case 'lastName':
      return 'Sobrenome';
    case 'email':
      return 'E-mail';
    case 'documentNumber':
      return 'CPF';
    case 'birthday':
      return 'Data de nascimento';
    case 'phone':
      return 'Telefone';
    case 'worksOnline':
      return 'Atende online';
    case 'worksInPerson':
      return 'Atende presencialmente';
    case 'sessioPrice':
      return 'Valor da sessão';
    case 'academicBackground':
      return 'Formação acadêmica';
    case 'professionalDocumentNumber':
      return 'CRP';
    case 'specialities':
      return 'Especialidades';
    case 'term':
      return 'Termo de uso';
    default:
      return '';
  }
};
