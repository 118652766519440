/* eslint-disable react-hooks/exhaustive-deps */
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ModalContent from '../../components/Modais/ModalContent';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import getConvertPlanPtBr from '../../utils/getConvertPlanPtBr';

export const Emails = () => {
  const [loading, setLoading] = useState(true);
  const [emailsClients, setEmailsClients] = useState([]);
  const [emailsProfessionals, setEmailProfessionals] = useState([]);
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState({
    subject: '',
    body: '',
    clientIds: [],
    professionalIds: [],
  });
  const [label, setLabel] = useState('client');
  const [optionUser, setOptionUser] = useState('Todos');
  const [optionPlan, setOptionPlan] = useState('Todos');
  const [sendType, setSendType] = useState('send_mail');
  const [paragraph, setParagraph] = useState(['Parágrafo']);
  const [body, setBody] = useState({});

  const userOptions = [
    'Todos',
    'Clientes',
    'Clientes Conveniados',
    'Profissionais',
    'Profissionais Aprovados',
    'Profissionais Pendentes',
    'Profissionais Excluidos',
  ];

  const plansOption = ['Todos', 'free', 'gold', 'platinum'];

  useEffect(() => {
    const controller = new AbortController();
    const getEmails = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/mail/list_users', {
          signal: controller.signal,
        });

        const parsedDataClients = data.table?.clients.map((e) => ({
          ...e,
          fullName: `${e.firstName} ${e.lastName}`,
          cotracted: e.cotracted ? 'Sim' : 'Não',
        }));

        const parsedDataProfessionais = data.table?.professionals.map((e) => ({
          ...e,
          fullName: `${e.firstName} ${e.lastName}`,
          tPlan: getConvertPlanPtBr(e.pagarmePlan),
          tStatus: selectStatus(e.status),
        }));

        setEmailsClients(parsedDataClients);
        setEmailProfessionals(parsedDataProfessionais);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar e-mails! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar e-mails');
      } finally {
        setLoading(false);
      }
    };

    getEmails();

    return () => controller.abort();
  }, []);

  const handleChangeUser = ({ target }) => {
    setOptionUser(target.value);
    const clientIds = emailsClients.map((e) => e.id);
    const professionalIds = emailsProfessionals.map((e) => e.id);
    switch (target.value) {
      case 'Clientes':
        return setEmail({
          ...email,
          clientIds,
          professionalIds: [],
        });
      case 'Clientes Conveniados':
        return setEmail({
          ...email,
          clientIds: emailsClients
            .filter((c) => c.school_id !== null)
            .map((c) => c.id),
          professionalIds: [],
        });
      case 'Profissionais':
        return setEmail({
          ...email,
          clientIds: [],
          professionalIds: emailsProfessionals.map((e) => e.id),
        });
      case 'Profissionais Aprovados':
        return setEmail({
          ...email,
          clientIds: [],
          professionalIds: emailsProfessionals
            .filter((p) => p.status === 'approved')
            .map((p) => p.id),
        });
      case 'Profissionais Pendentes':
        return setEmail({
          ...email,
          clientIds: [],
          professionalIds: emailsProfessionals
            .filter((p) => p.status === 'pending')
            .map((p) => p.id),
        });
      case 'Profissionais Excluidos':
        return setEmail({
          ...email,
          clientIds: [],
          professionalIds: emailsProfessionals
            .filter((p) => p.status === 'disapproved')
            .map((p) => p.id),
        });
      default:
        return setEmail({
          ...email,
          clientIds,
          professionalIds,
        });
    }
  };

  const handleChangePlan = ({ target }) => {
    setOptionPlan(target.value);
    const professionalIds = emailsProfessionals
      .filter((p) => p.pagarmePlan === target.value)
      .map((p) => p.id);
    setEmail({ ...email, professionalIds });
  };

  const sendEmail = async () => {
    setLoading(true);
    setEmailModal(false);
    try {
      const request = {
        data: {
          attributes: {
            ...email,
            body:
              sendType === 'send_message' ? email.body : Object.values(body),
          },
        },
      };
      let data;
      if (sendType === 'send_message') {
        await api.post(`/mail/${sendType}`, request);
      } else {
        const { data: response } = await api.post(`/mail/${sendType}`, request);
        data = response.data;
      }
      if (data) {
        const parsedDataClients = [
          { id: parseInt(data.id), ...data?.attributes },
          ...emailsClients,
        ];
        const parsedDataProfessionais = [
          { id: parseInt(data.id), ...data?.attributes },
          { ...emailsProfessionals },
        ];

        setEmailsClients(parsedDataClients);
        setEmailProfessionals(parsedDataProfessionais);
        toast.success(
          `${sendType === 'send_mail' ? 'E-mail' : 'SMS'} enviado com sucesso`
        );
      } else toast.success('Mensagem enviada com sucesso');
      resetMessage();
    } catch (error) {
      if (error?.response?.data?.errors[0].title)
        toast.error(
          `Erro ao enviar ${
            sendType === 'send_mail' ? 'E-mail' : 'SMS'
          } Erro - ${error.response.data.errors[0]?.title}`
        );
      else
        toast.error(
          `Erro ao enviar ${sendType === 'send_mail' ? 'E-mail' : 'SMS'}`
        );
    } finally {
      setLoading(false);
    }
  };

  const resetMessage = () => {
    setBody({});
    setEmail({
      subject: '',
      body: '',
      clientIds: [],
      professionalIds: [],
    });
    setParagraph(['Parágrafo']);
  };

  const selectStatus = (status) => {
    if (status === 'approved') return 'Aprovado';
    if (status === 'pending') return 'Pendente';
    if (status === 'disapproved') return 'Excluido';
  };

  const HeaderSendEmail = () => (
    <>
      <Button
        onClick={() => {
          setSendType('send_mail');
          setEmailModal(true);
        }}
      >
        <ForwardToInboxIcon sx={{ mr: 1 }} /> Escrever E-mail
      </Button>
      <Button
        onClick={() => {
          setSendType('send_message');
          setEmailModal(true);
        }}
      >
        <ForwardToInboxIcon sx={{ mr: 1 }} /> Escrever Menssagem
      </Button>
    </>
  );

  const handleChangeBody = (pGraph) => {
    return pGraph?.map((p, i) => (
      <>
        {sendType === 'send_mail' && (
          <label key={p + i} htmlFor="paragraph">
            {i + 1}º Parágrafo
          </label>
        )}
        <TextareaAutosize
          id="paragraph"
          aria-label="textarea"
          minRows={8}
          maxRows={15}
          style={{ marginBottom: 10, width: '100%' }}
          onChange={({ target }) => setBody({ ...body, [i]: target.value })}
        />
      </>
    ));
  };

  const columnsClient = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fullName',
      label: 'Nome',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'cotracted',
      label: 'Conveniado',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
  ];

  const columnsProfessional = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fullName',
      label: 'Nome',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tPlan',
      label: 'Plano',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tStatus',
      label: 'Status',
      options: {
        filterType: 'multiselect',
        sortThirdClickReset: true,
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <>
          <>
            <Button
              variant={label === 'client' ? 'contained' : ''}
              onClick={() => {
                setLabel('client');
              }}
            >
              Clientes
            </Button>
            <Button
              variant={label === 'professional' ? 'contained' : ''}
              onClick={() => {
                setLabel('professional');
              }}
            >
              Profissionais
            </Button>
          </>

          <LayoutMUIDataTable
            title={
              label === 'client'
                ? 'E-mails de Clientes'
                : 'E-mails de Profissionais'
            }
            data={label === 'client' ? emailsClients : emailsProfessionals}
            columns={label === 'client' ? columnsClient : columnsProfessional}
            customToolbar={<HeaderSendEmail />}
          />
        </>
      )}

      <ModalContent
        open={emailModal}
        handleClose={() => {
          resetMessage();
          setEmailModal(false);
        }}
      >
        <Typography variant="h5" sx={{ m: 1 }}>
          {`Escrever nov${
            sendType === 'send_mail' ? 'o e-mail' : 'a mensagem'
          }`}
        </Typography>

        <Grid
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          <Grid sx={{ m: 1 }}>
            <InputLabel id="demo-simple-select-label">Para</InputLabel>
            <Select
              sx={{ width: 250 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={optionUser}
              label="Usuários"
              onChange={handleChangeUser}
            >
              {userOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {['Profissionais', 'Profissionais Aprovados'].includes(
            optionUser
          ) && (
            <Grid sx={{ m: 1 }}>
              <InputLabel id="demo-simple-select-label">do plano</InputLabel>
              <Select
                sx={{ width: 250 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={optionPlan}
                label="Planos"
                onChange={handleChangePlan}
              >
                {plansOption.map((plan, index) => (
                  <MenuItem key={index} value={plan}>
                    {getConvertPlanPtBr(plan)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
        <Grid sx={{ m: 1 }} container>
          <TextField
            id="subject"
            variant="standard"
            label="Assunto"
            value={email.subject}
            onChange={({ target }) =>
              setEmail({
                ...email,
                subject: target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid sx={{ m: 1, mt: 2 }} container>
          {handleChangeBody(paragraph)}
          {sendType === 'send_mail' && (
            <Grid container justifyContent="space-between">
              <Button onClick={() => setParagraph([...paragraph, 'Parágrafo'])}>
                <Typography sx={{ cursor: 'pointer' }}>
                  Adicionar parágrafo
                </Typography>
              </Button>
              <Button
                color="error"
                disabled={paragraph?.length <= 1}
                onClick={() => {
                  const newParagraph = paragraph.splice(
                    0,
                    paragraph.length - 1
                  );
                  setParagraph(newParagraph);
                }}
              >
                <Typography sx={{ cursor: 'pointer' }}>
                  Remover parágrafo
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid sx={{ m: 1, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ width: 100 }} variant="contained" onClick={sendEmail}>
            Enviar
          </Button>
        </Grid>
      </ModalContent>
    </AuthorizedLayout>
  );
};
