export const states = {
  names: [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ],
  logic(state, filterVal) {
    const show =
      (filterVal.indexOf('AC') >= 0 && state === 'AC') ||
      (filterVal.indexOf('AL') >= 0 && state === 'AL') ||
      (filterVal.indexOf('AP') >= 0 && state === 'AP') ||
      (filterVal.indexOf('AM') >= 0 && state === 'AM') ||
      (filterVal.indexOf('BA') >= 0 && state === 'BA') ||
      (filterVal.indexOf('CE') >= 0 && state === 'CE') ||
      (filterVal.indexOf('DF') >= 0 && state === 'DF') ||
      (filterVal.indexOf('ES') >= 0 && state === 'ES') ||
      (filterVal.indexOf('GO') >= 0 && state === 'GO') ||
      (filterVal.indexOf('MA') >= 0 && state === 'MA') ||
      (filterVal.indexOf('MT') >= 0 && state === 'MT') ||
      (filterVal.indexOf('MS') >= 0 && state === 'MS') ||
      (filterVal.indexOf('MG') >= 0 && state === 'MG') ||
      (filterVal.indexOf('PA') >= 0 && state === 'PA') ||
      (filterVal.indexOf('PB') >= 0 && state === 'PB') ||
      (filterVal.indexOf('PR') >= 0 && state === 'PR') ||
      (filterVal.indexOf('PE') >= 0 && state === 'PE') ||
      (filterVal.indexOf('PI') >= 0 && state === 'PI') ||
      (filterVal.indexOf('RJ') >= 0 && state === 'RJ') ||
      (filterVal.indexOf('RN') >= 0 && state === 'RN') ||
      (filterVal.indexOf('RS') >= 0 && state === 'RS') ||
      (filterVal.indexOf('RO') >= 0 && state === 'RO') ||
      (filterVal.indexOf('RR') >= 0 && state === 'RR') ||
      (filterVal.indexOf('SC') >= 0 && state === 'SC') ||
      (filterVal.indexOf('SP') >= 0 && state === 'SP') ||
      (filterVal.indexOf('SE') >= 0 && state === 'SE') ||
      (filterVal.indexOf('TO') >= 0 && state === 'TO');
    return !show;
  },
};

export const ages = {
  names: ['Menor de 5', '5 - 13', '14 - 18', '19 - 25', 'Maior de 25'],
  logic(age, filterVal) {
    const show =
      (filterVal.indexOf('Menor de 5') >= 0 && age === '-5') ||
      (filterVal.indexOf('5 - 13') >= 0 && age === '5 - 13') ||
      (filterVal.indexOf('14 - 18') >= 0 && age === '14 - 18') ||
      (filterVal.indexOf('19-25') >= 0 && age === '19-25') ||
      (filterVal.indexOf('Maior de 25') >= 0 && age === '25+');

    return !show;
  },
};
