export default function getPaymentName(payment) {
  switch (payment) {
    case 'paid':
      return 'Pago';
    case 'pending':
      return 'Pendente';
    case 'waiting_payment':
      return 'Aguardando pagamento';
    default:
      return 'Não informado';
  }
}
