/* eslint-disable jsx-a11y/alt-text */
import { Divider, Drawer, List, Toolbar } from '@mui/material';
import React from 'react';
import logo from '../../images/smallLogo.png';
import { MainListItems } from './listItems';

export const MainSidebar = ({ visible, setVisible }) => {
  return (
    <Drawer
      variant="temporary"
      open={visible}
      onClose={() => setVisible(false)}
      sx={{
        width: 180,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: [1],
        }}
      >
        <img src={logo} style={{ width: '50%', margin: '20px 0' }} />
      </Toolbar>
      <Divider />
      <List>
        <MainListItems />
      </List>
    </Drawer>
  );
};
