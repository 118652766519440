/* eslint-disable react-hooks/exhaustive-deps */
import { Close, Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, TableCell, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import CouponForm from '../../components/Forms/CouponForm';
import Loading from '../../components/Loading';
import ModalAlert from '../../components/Modais/ModalAlert';
import ModalContent from '../../components/Modais/ModalContent';
import { AuthorizedLayout } from '../../layouts/AuthorizedLayout';
import LayoutMUIDataTable from '../../layouts/LayoutMUIDataTable';
import api from '../../services/api';
import getSortedDates from '../../utils/getSortedDates';

export const Coupons = () => {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [couponModal, setCouponModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const schema = yup.object({
    code: yup.string(),
    title: yup.string(),
    couponType: yup.string().required('Tipo do cupom é obrigatório'),
    discountPercentage: yup.number().when('couponType', {
      is: (couponType) => couponType === 'admin_universal',
      then: yup
        .number()
        .typeError('O valor deve ser um número')
        .min(1, 'O valor deve ser maior que 0')
        .max(20, 'O valor máximo permitido é 20%')
        .required('O valor de desconto é obrigatório')
        .nullable(),
      otherwise: yup
        .number()
        .typeError('O valor deve ser um número')
        .min(1, 'O valor deve ser maior que 0')
        .max(100, 'O valor máximo permitido é 100%')
        .required('O valor de desconto é obrigatório')
        .nullable(),
    }),
    discountPrice: yup
      .number()
      .typeError('O valor deve ser um número')
      .nullable(),
    amountLimit: yup
      .number()
      .typeError('O valor deve ser um número')
      .nullable(),
  });

  const form = useFormik({
    initialValues: {
      code: '',
      title: '',
      couponType: 'admin_universal',
      discountPercentage: '',
      discountPrice: 0,
      amountLimit: null,
      dateLimitStart: null,
      dateLimitEnd: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      isEdit ? updateCoupons(values) : createCoupons(values);
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    const getCoupons = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/coupons', {
          signal: controller.signal,
        });

        setCoupons(data.data);
      } catch (error) {
        if (controller.signal.aborted) return;
        if (error.response?.status)
          toast.error(
            `Erro ao carregar cupons! Erro status - ${error.response?.status}`
          );
        else toast.error('Erro ao carregar cupons');
      } finally {
        setLoading(false);
      }
    };

    getCoupons();

    return () => controller.abort();
  }, []);

  const getParsedData = (data) =>
    data.map((c) => ({
      id: parseInt(c.id),
      ...c.attributes,
      tCouponType: c.attributes.couponType
        ? c.attributes.couponType === 'admin_universal'
          ? 'Universal'
          : 'Primeira sessão'
        : 'Não Informado',
      discountType:
        Number(c.attributes?.discountPercentage) !== 0
          ? { value: c.attributes?.discountPercentage || 0, label: '%' }
          : { value: c.attributes?.discountPrice || 0, label: 'R$' },
      expirationDate:
        !c.attributes?.dateLimitEnd && !c.attributes?.dateLimitStart
          ? { value: moment().add(100, 'year'), label: 'Não expira' }
          : { value: c.attributes?.dateLimitEnd, label: 'Expira' },
      unlimited:
        (!c.attributes?.amountLimit &&
          !c.attributes?.dateLimitEnd &&
          !c.attributes?.dateLimitStart) ||
        !c.attributes?.amountLimit ||
        c.attributes?.amountLimit === 1000000000
          ? 1000000000
          : c.attributes?.amountLimit,
    }));

  const createCoupons = async (values) => {
    try {
      setCouponModal(false);
      setLoading(true);
      const request = {
        data: {
          type: 'coupon',
          attributes: {
            amountLimit: values.amountLimit,
            code: values.code,
            couponType: values.couponType,
            dateLimitStart: values.dateLimitStart,
            dateLimitEnd: values.dateLimitEnd,
            discountPercentage: values.discountPercentage,
            title: values.title,
          },
        },
      };
      const { data } = await api.post('/coupons', request);
      if (data?.data) {
        setCoupons([...coupons, data.data]);
        toast.success('Cupom criado com sucesso');
      } else throw new Error('Erro ao criar cupom');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao criar cupom! Erro - ${error.response.data.errors[0]?.title}`
        );
      else toast.error('Erro ao criar cupom');
    } finally {
      setCouponId(null);
      form?.resetForm();
      setLoading(false);
    }
  };

  const updateCoupons = async (values) => {
    setCouponModal(false);
    setIsEdit(false);
    setLoading(true);
    try {
      const request = {
        data: {
          type: 'coupon',
          attributes: {
            amountLimit: values.amountLimit,
            code: values.code,
            couponType: values.couponType,
            dateLimitStart: values.dateLimitStart,
            dateLimitEnd: values.dateLimitEnd,
            discountPercentage: values.discountPercentage,
            discountPrice: values.discountPrice,
            title: values.title,
          },
        },
      };
      const { data } = await api.put(`/coupons/${couponId}`, request);
      const index = coupons.findIndex((c) => Number(c.id) === couponId);
      if (index !== -1) coupons[index] = data?.data;
      setCoupons(coupons);
      toast.success('Cupom atualizado com sucesso');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao atualizar cupom! Erro - ${error.response.data.errors[0]?.title}`
        );
      else toast.error('Erro ao atualizar cupom');
    } finally {
      setCouponId(null);
      form?.resetForm();
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.delete(`/coupons/${id}`);
      if (data?.data) {
        const parsedData = coupons.filter((c) => Number(c.id) !== id);
        setCoupons(parsedData);
        toast.success('Cupom excluído com sucesso');
      } else throw new Error('Erro ao excluir cupom');
    } catch (error) {
      if (error.response.data.errors[0].title)
        toast.error(
          `Erro ao excluir cupom! Erro - ${error.response.data.errors[0]?.title}`
        );
      else toast.error('Erro ao excluir cupom');
    } finally {
      setCouponModal(false);
      setLoading(false);
    }
  };

  const renderCoupons = getParsedData(coupons);

  const HeaderCreateCoupon = () => (
    <Button
      onClick={() => {
        setCouponModal(true);
      }}
    >
      Criar Cupon <AddIcon />
    </Button>
  );

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'tCouponType',
      label: 'Tipo do Cupom',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'code',
      label: 'Código',
      options: {
        filterType: 'textField',
        sortThirdClickReset: true,
      },
    },
    {
      name: 'title',
      label: 'Descrição',
      options: {
        filterType: 'textField',
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'discountType',
      label: 'Desconto',
      options: {
        filterType: 'custom',
        customBodyRender: ({ value, label }) => (
          <div>
            {`${
              label === 'R$'
                ? value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : Number(value).toFixed()
            } ${label !== 'R$' ? label : ''}`}
          </div>
        ),
        filterOptions: {
          logic: (data, filters) =>
            filters.length ? !filters.includes(data.value) : false,
          display: (filterList, onChange, index, column) => (
            <div>
              <TextField
                label="Desconto"
                type="number"
                variant="standard"
                value={filterList[index][0] || 0}
                onChange={(event) => {
                  filterList[index][0] = Number(event.target.value);
                  onChange(filterList[index], index, column);
                }}
                fullWidth
              />
            </div>
          ),
        },
        sortCompare:
          (order) =>
          ({ data: discount1 }, { data: discount2 }) => {
            if (order === 'asc')
              return Number(discount1.value) > Number(discount2.value) ? 1 : -1;
            if (order === 'desc')
              return Number(discount1.value) > Number(discount2.value) ? -1 : 1;
          },
        sortThirdClickReset: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Criação',
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value.length > 0) {
              if (moment(value[0]).isValid() && moment(value[1]).isValid())
                return `De ${moment(value[0]).format(
                  'DD/MM/YYYY'
                )} até ${moment(value[1]).format('DD/MM/YYYY')}`;
              return 'Selecione uma data.';
            }
          },
        },
        filterOptions: {
          logic: (_date, filters, row) => {
            const compareDate = moment(row[4]);
            const startDate = moment(filters[0]).subtract(1, 'days');
            const endDate = moment(filters[1]).add(1, 'days');
            if (filters.length)
              return !compareDate.isBetween(startDate, endDate);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <label style={{ color: '#707070' }}>{column.label}</label>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  label="Data Inicial"
                  type="date"
                  variant="standard"
                  value={filterList[index][0] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Data Final"
                  type="date"
                  variant="standard"
                  value={filterList[index][1] || moment().format('YYYY-MM-DD')}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  fullWidth
                />
              </div>
            </div>
          ),
        },
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'dateLimitStart',
      label: 'Data Início',
      options: {
        filterType: 'textField',
        customBodyRender: (value) =>
          value ? moment(value).format('DD/MM/YYYY') : '',
        sortCompare: (order) => (date1, date2) =>
          getSortedDates(date1, date2, order),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'expirationDate',
      label: 'Data Expiração',
      options: {
        filterType: 'textField',
        customBodyRender: ({ value, label }) =>
          label === 'Expira' ? moment(value).format('DD/MM/YYYY') : label,
        sortCompare: (order) => (date1, date2) => {
          if (order === 'desc')
            return moment(date1.data.value, 'YYYY-MM-DD').isBefore(
              moment(date2.data.value, 'YYYY-MM-DD')
            )
              ? 1
              : -1;
          return moment(date2.data.value, 'YYYY-MM-DD').isBefore(
            moment(date1.data.value, 'YYYY-MM-DD')
          )
            ? 1
            : -1;
        },
      },
    },
    {
      name: 'unlimited',
      label: 'Limite de quantidade',
      options: {
        filterType: 'textField',
        customBodyRender: (value) =>
          value === 1000000000 ? 'Ilimitado' : value,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      sortable: false,
      options: {
        filter: false,
        customHeadRender: (columnMeta) => (
          <TableCell
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 100,
              backgroundColor: 'white',
            }}
            align="center"
          >
            {columnMeta.label}
          </TableCell>
        ),
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  const couponFind = coupons.find(
                    (c) => Number(c.id) === rowData[0]
                  ).attributes;
                  form?.setValues({
                    ...couponFind,
                    couponType:
                      couponFind?.couponType || form?.values?.couponType,
                    discountPercentage: Number(
                      couponFind?.discountPercentage
                    ).toFixed(0),
                    dateLimitStart: moment(couponFind?.dateLimitStart).format(
                      'YYYY-MM-DD'
                    ),
                    dateLimitEnd: moment(couponFind?.dateLimitEnd).format(
                      'YYYY-MM-DD'
                    ),
                  });
                  setIsEdit(true);
                  setCouponId(rowData[0]);
                }}
              >
                <Edit sx={{ fontSize: '1.3rem' }} />
              </Button>

              <Button
                onClick={() => {
                  setAlertModal(true);
                  setCouponId(rowData[0]);
                }}
              >
                <Close color="error" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loading />
      ) : (
        <LayoutMUIDataTable
          title="Cupons"
          data={renderCoupons}
          columns={columns}
          customToolbar={<HeaderCreateCoupon />}
        />
      )}

      <ModalContent
        open={couponModal || isEdit}
        handleClose={() => {
          setCouponModal(false);
          setIsEdit(false);
          setCouponId(null);
          form?.resetForm();
        }}
      >
        <CouponForm form={form} />
        <Grid
          sx={{ mt: 5 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          <Button
            sx={{ m: 1 }}
            onClick={() => {
              setCouponModal(false);
              setIsEdit(false);
              setCouponId(null);
              form?.resetForm();
            }}
            size="small"
            variant="text"
          >
            Cancelar
          </Button>
          <Button
            sx={{ m: 1, width: '7rem' }}
            onClick={form?.handleSubmit}
            size="small"
            variant="contained"
          >
            {isEdit ? 'Salvar' : 'Criar'}
          </Button>
        </Grid>
      </ModalContent>

      <ModalAlert
        open={alertModal}
        handleClose={() => setAlertModal(false)}
        handleSubmit={() => handleDelete(couponId)}
      >
        <Typography variant="h5">
          Você tem certeza que deseja apagar este cupom?
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
